export const pasteIcon = `
<svg xmlns="http://www.w3.org/2000/svg" width="21" height="20" viewBox="0 0 21 20"
     fill="none">
  <path fill-rule="evenodd"
        clip-rule="evenodd"
        d="M7.625 2H13.625V3H7.625V2ZM5.625 2V0H15.625V2H20.625V20H0.625V2H5.625ZM5.625 4H2.625V18H18.625V4H15.625V5H5.625V4Z"
        fill="var(--zbp-svg-primary-color, black)"
  />
</svg>
`;
