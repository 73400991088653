export const keyGlowIcon = `
<svg xmlns="http://www.w3.org/2000/svg"
     width="85" height="85"
     viewBox="0 0 85 85"
     fill="none">
  <circle cx="41.8335" cy="41.8325" r="41.6655"
          fill="var(--zbp-svg-secondary-color, white)"
          fill-opacity="0.3"
  />
  <circle cx="41.832" cy="41.833" r="38"
          fill="var(--zbp-svg-secondary-color, white)"
          fill-opacity="0.67"
  />
  <path
    d="M42.5078 55.2985C44.1425 53.6594 45.1647 51.5094 45.4021 49.2111C45.6395 46.9128 45.0775 44.6067 43.8109 42.6817L46.7978 39.6948L49.6262 42.5232L52.4682 39.6812L49.6397 36.8528L52.4817 34.0108L56.7243 38.2535L59.5663 35.4115L55.3237 31.1689L56.7446 29.7479L53.9162 26.9195L40.9825 39.8532C39.0574 38.5866 36.7513 38.0246 34.453 38.262C32.1547 38.4994 30.0047 39.5216 28.3656 41.1563C24.448 45.074 24.4328 51.4333 28.3318 55.3323C32.2308 59.2313 38.5901 59.2161 42.5078 55.2985ZM31.1941 43.9848C33.5444 41.6345 37.3605 41.6253 39.6996 43.9645C42.0388 46.3036 42.0297 50.1197 39.6794 52.47C37.329 54.8203 33.5129 54.8294 31.1738 52.4903C28.8347 50.1512 28.8438 46.3351 31.1941 43.9848Z"
    fill="var(--zbp-svg-primary-color, black)"
  />
</svg>
`;
