export const wrenchesIcon = `
<svg xmlns="http://www.w3.org/2000/svg" width="139" height="140" viewBox="0 0 139 140"
     fill="none">
  <path
    d="M69.4955 61.5721L54.6326 46.7131C55.8674 43.8024 56.2046 40.5892 55.6007 37.4856C54.9968 34.382 53.4794 31.5296 51.2433 29.2943C46.5363 24.5834 39.9046 23.4602 34.1221 25.9089L42.012 33.5051V42.5142H33.0029L25.4107 34.6203C22.962 40.3949 24.0891 47.1774 28.796 51.8923C33.503 56.6072 40.4364 57.651 46.2149 55.2102L61.0818 70.0295M69.4955 78.4075L84.3665 93.2824C83.1291 96.1937 82.7902 99.4083 83.3932 102.514C83.9962 105.619 85.5137 108.473 87.7507 110.71C89.9877 112.946 92.8421 114.463 95.9475 115.066C99.053 115.668 102.268 115.328 105.179 114.091L97.5784 106.494V98.0767H105.996L113.592 105.673C114.825 102.762 115.16 99.5497 114.556 96.4471C113.951 93.3446 112.434 90.4932 110.199 88.2581C107.964 86.023 105.112 84.506 102.01 83.9014C98.9073 83.2969 95.6949 83.6323 92.7842 84.8647L77.9212 69.9978M54.6326 93.2824L92.7842 55.1308C95.6948 56.3662 98.9081 56.7036 102.012 56.0997C105.116 55.4958 107.968 53.9781 110.203 51.7415C114.914 47.0346 116.033 40.3949 113.588 34.6203L105.996 42.5102H97.5784V33.5011L105.179 25.9049C102.267 24.6701 99.0539 24.3328 95.9499 24.9363C92.8458 25.5398 89.9927 27.0565 87.7563 29.2921C85.5198 31.5276 84.002 34.3802 83.3974 37.484C82.7927 40.5878 83.1288 43.8015 84.3625 46.7131L46.2149 84.8647C43.3041 83.6323 40.0917 83.2969 36.9892 83.9014C33.8866 84.506 31.0352 86.023 28.8001 88.2581C26.565 90.4932 25.048 93.3446 24.4435 96.4471C23.8389 99.5497 24.1743 102.762 25.4067 105.673L33.0029 98.0767H42.012V106.494L34.1221 114.091C37.009 115.322 40.1996 115.658 43.2796 115.054C46.3596 114.449 49.1868 112.933 51.3941 110.701C56.105 105.998 57.0773 99.0569 54.6326 93.2824Z"
    stroke="var(--zbp-svg-primary-color, black)"
    stroke-width="3.96875"
    stroke-miterlimit="10"
    stroke-linejoin="round"
  />
</svg>
`;
