import { Injectable } from '@angular/core';
import { MobileBrowserTypes } from '@shared/enums/mobile-browser-types';
import { ModulePathName } from '@shared/enums/module-path-name';
import { RoleType } from '@shared/enums/role-type';
import { AppConfigService } from './appconfig.service';
import { CacheService } from './cache.service';
import { ZbPortalRedesignHelpers } from './services/feature-flagged-features/zb-portal-redesign-helpers';

@Injectable({
  providedIn: 'root',
})
export class AppRoutingService {
  constructor(
    private cache: CacheService,
    private appConfig: AppConfigService,
    private redesignHelpers: ZbPortalRedesignHelpers,
  ) {
  }

  /**
   * findLoginRoute will determine the most appropriate login page
   * that should be presented to the current user.
   * @returns the route most relevant to the user/system
   */
  findLoginRoute(): string {
    let route = '/login';

    // check for zbLoginInterop (filament requirement for superkids app)
    if (window.zbLoginInterop || false) {
      route = '/login/skof';
    }

    // check for presence of mobile app's WebView layer
    const unityBrowser = localStorage.getItem('myBrowser');
    if (unityBrowser && unityBrowser === MobileBrowserTypes.UNITY) {
      const returnUrl = localStorage.getItem('myBrowserReturnUrl');
      if (returnUrl) {
        localStorage.removeItem('myBrowserReturnUrl');
      }
      route = '/login/skof';
    } else if (this.appConfig.isHighlightsPortalUrl) {
      route = '/login/user';
    } else if (this.cache.lastLoginPathUsed) {
      route = `/login/${this.cache.lastLoginPathUsed}`;
    }
    return route;
  }

  /**
   * Centralized location to determine the default (aka home or dashboard) route
   * for the specified RoleType.
   * @param role
   * @returns
   */
  findDefaultRouteForRole(role: RoleType): string[] {
    switch (role) {
    case RoleType.DistrictAdministrator:
      return ['/districts'];
    case RoleType.SchoolAdministrator:
      return ['/schools'];
    case RoleType.Parent:
      return ['/parent'];
    case RoleType.ApplicationAdministrator:
      return ['/admin/app'];
    case RoleType.CustomerServiceAdministrator:
    case RoleType.CustomerServiceElevatedAdministrator:
      return ['/admin/cx'];
    case RoleType.Student:
      return ['/learning'];
    case RoleType.Teacher:
      if (this.appConfig.isHighlightsPortalUrl) {
        return ['/learning/products/ece'];
      }
      if (this.redesignHelpers.isZbPortal2024Active()) {
        return ['/teachers'];
      }
      return ['/learning'];
    default:
      return ['/learning'];
    }
  }

  /**
   * Centralized location to determine how to route to modules and its inner components.
   *
   * These are module routes used for the ZBRedesign.
   *
   * @param { ModulePathName } moduleName - Module name to get route for
   * @returns Matching module, if found.
   */
  findRouteToModule(moduleName: ModulePathName) {
    const routes = {
      [ModulePathName.Products]: '/learning/products/',
      [ModulePathName.Classes]: '/teachers/classes/',
      [ModulePathName.Districts]: '/districts',
      [ModulePathName.Schools]: '/schools',
    };

    const matchingModule = routes[moduleName];

    return matchingModule;
  }
}
