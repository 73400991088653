import { CommonModule } from '@angular/common';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { RouterModule } from '@angular/router';
import { NavigationMenuItem } from '@models/navigation-menu-item';
import { ButtonComponent } from '@shared/button/button.component';
import { BehaviorSubject, Subscription, tap } from 'rxjs';
import { HeaderService } from '../../header-service';

@Component({
  standalone: true,
  selector: 'zbp-subnavigation',
  templateUrl: './subnavigation.component.html',
  styleUrls: ['./subnavigation.component.scss'],
  imports: [RouterModule, CommonModule, ButtonComponent],
})
export class SubnavigationComponent implements OnDestroy, OnInit {
  primaryMenuItems: NavigationMenuItem[] = this.headerService.currentMenus ?? [];

  showSubnav: boolean = false;
  private subscriptions: Subscription[] = [];

  menuSubscription: BehaviorSubject<any> = new BehaviorSubject(null);

  constructor(
    private headerService: HeaderService,
  ) { }

  ngOnInit(): void {
    this.subscriptions.push(
      this.headerService.subNavMenuItems$.pipe(
        tap((res) => {
          this.menuSubscription.next(res);
        })
      ).subscribe(),
    );
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(subscription => subscription.unsubscribe());
  }
}
