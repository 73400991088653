<footer class="footer row align-self-end d-print-none {{ background }}"
        [class.zb-legacy-footer]="!isHighlightsPortalUser && !applyZbRedesign"
        [class.highlights-portal-footer]="isHighlightsPortalUser"
        [class.zb-portal-redesign-footer]="applyZbRedesign"
        data-cy="footer">
  <nav class="col" aria-label="Footer Navigation">
    <ng-container *ngIf="showLinks">
      <a *ngIf="isAndroidUser"
         id="privacyAndroid"
         [class.red-text-link]="isHighlightsPortalUser"
         (click)="open(androidPrivacyModal)">{{ 'privacyLink' | translate }}</a>
      <a *ngIf="!isAndroidUser"
         id="privacy"
         [attr.href]="getPrivacyLink()"
         [class.red-text-link]="isHighlightsPortalUser"
         data-cy="footer-privacy-link"
         target="_blank">{{ 'privacyLink' | translate }}</a>
      <a id="legal"
         [attr.href]="getLegalLink()"
         [class.red-text-link]="isHighlightsPortalUser"
         data-cy="footer-legal-link"
         target="_blank">{{ 'legalLink' | translate }}</a>
      <a id="systemRequirements"
         [attr.href]="getSystemRequirementsLink()"
         [class.red-text-link]="isHighlightsPortalUser"
         data-cy="footer-system-requirements-link"
         target="_blank">{{ 'sysRequirementsLink' | translate }}</a>
      <a id="techSupport"
         [attr.href]="getTechSupportLink()"
         [class.red-text-link]="isHighlightsPortalUser"
         data-cy="footer-tech-support-link"
         target="_blank">{{ 'techSupportLink' | translate }}</a>
    </ng-container>
    <span *ngIf="!isHighlightsPortalUser" class="ms-auto footerItemCopyright">
      © {{zbCopyrightText}}
    </span>
    <span *ngIf="isHighlightsPortalUser"
          class="ms-auto footerItemCopyright"
          [class.highlights-portal-footer-nav-copyright]="isHighlightsPortalUser"
          data-cy="footer-copyright">
      © Highlights for Children, Inc.
    </span>
  </nav>
</footer>

<ng-template #androidPrivacyModal let-modal>
  <div class="modal-header" zbpZbSwirl>
    <h4 class="modal-title" id="modal-basic-title">{{ 'privacyLink' | translate }}</h4>
    <button type="button" class="btn close" aria-label="Close" (click)="modal.dismiss()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <iframe src="https://www.zaner-bloser.com/policies/privacy-policy.php" style="width: 100%"></iframe>
  </div>
  <div class="modal-footer">
    <div class="row d-flex flex-row justify-content-end">
      <div class="text-end mb-3">
        <button id="closePrivacyModalButton"
                type="button"
                class="btn btn-outline-tertiary text-uppercase btn-sm"
                (click)="modal.close()">
          Ok
        </button>
      </div>
    </div>
  </div>
</ng-template>
