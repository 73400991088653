export const flipIcon = `
<svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 25 25"
     fill="none">
  <path
    d="M9.05859 12.9706C9.05859 14.6306 10.3986 15.9706 12.0586 15.9706C13.7186 15.9706 15.0586 14.6306 15.0586 12.9706C15.0586 11.3106 13.7186 9.97058 12.0586 9.97058C10.3986 9.97058 9.05859 11.3106 9.05859 12.9706ZM13.0586 12.9706C13.0586 13.5206 12.6086 13.9706 12.0586 13.9706C11.5086 13.9706 11.0586 13.5206 11.0586 12.9706C11.0586 12.4206 11.5086 11.9706 12.0586 11.9706C12.6086 11.9706 13.0586 12.4206 13.0586 12.9706Z"
    fill="var(--zbp-svg-primary-color, black)"
  />
  <path
    d="M8.05859 10.9706V8.97058H5.14859C6.52859 6.58058 9.10859 4.97058 12.0586 4.97058C15.7786 4.97058 18.9086 7.53058 19.7986 10.9706H21.8586C20.9286 6.41058 16.8986 2.97058 12.0586 2.97058C8.78859 2.97058 5.87859 4.55058 4.05859 6.98058V4.97058H2.05859V10.9706H8.05859Z"
    fill="var(--zbp-svg-primary-color, black)"
  />
  <path
    d="M16.0588 14.9706V16.9706H18.9688C17.5888 19.3606 15.0088 20.9706 12.0588 20.9706C8.33879 20.9706 5.20879 18.4106 4.31879 14.9706H2.25879C3.18879 19.5306 7.21879 22.9706 12.0588 22.9706C15.3288 22.9706 18.2388 21.3906 20.0588 18.9606V20.9706H22.0588V14.9706H16.0588Z"
    fill="var(--zbp-svg-primary-color, black)"
  />
</svg>
`;
