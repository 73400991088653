import { inject } from '@angular/core';
import { ActivatedRouteSnapshot, GuardResult, Router, RouterStateSnapshot } from '@angular/router';
import { AppRoutingService } from '@core/app-routing.service';

// eslint-disable-next-line @typescript-eslint/no-unused-vars,max-len
export function forceCorrectLoginPageGuard(_route: ActivatedRouteSnapshot, _state: RouterStateSnapshot): GuardResult {
  const appRoutingService = inject(AppRoutingService);

  const router = inject(Router);
  const correctLoginUrl = appRoutingService.findLoginRoute();
  if (_state.url === correctLoginUrl) {
    // already there, allow through guard
    return true;
  }
  // redirect to correct login url
  return router.createUrlTree([correctLoginUrl], { queryParamsHandling: 'preserve' });
}
