import { NavigationMenuItem } from '@models/navigation-menu-item';
import { RoleType } from '@shared/enums/role-type';
import { hlContactUsMenu, zbContactUsMenu } from './contact-us-menu';

export const DISTRICT_ADMIN_MANAGE_MENU_ID = 'DistrictManage';

export const districtHLAdminMainMenus: NavigationMenuItem[] = [
  hlContactUsMenu,
  { id: DISTRICT_ADMIN_MANAGE_MENU_ID, isRouted: false, isExternal: false, label: 'Manage', weight: -10 },
];

export const districtZBAdminMainMenus: NavigationMenuItem[] = [
  zbContactUsMenu,
  { id: DISTRICT_ADMIN_MANAGE_MENU_ID, isRouted: false, isExternal: false, label: 'Manage', weight: -10 },
];

/**
 * Default Submenu links for District Administrators.
 *
 * The label should have the first letter of the first word capitalized.
 */
export const districtAdminManageDropdownMenus: NavigationMenuItem[] = [
  {
    id: 'manage-users',
    isRouted: true,
    route: 'users',
    isExternal: false,
    label: 'Manage users',
    weight: 1,
    roleType: RoleType.DistrictAdministrator,
  },
  {
    id: 'manage-district-admins',
    isRouted: true,
    isExternal: false,
    route: 'admins',
    label: 'Manage district administrators',
    weight: 2,
    roleType: RoleType.DistrictAdministrator,
  },
  {
    id: 'assign-revoke-licenses',
    isRouted: true,
    isExternal: false,
    route: 'manage-licenses',
    label: 'Assign and revoke licenses',
    weight: 3,
    roleType: RoleType.DistrictAdministrator,
  },
  {
    id: 'distribute-licenses',
    isRouted: true,
    isExternal: false,
    route: 'distribute-licenses',
    label: 'Distribute licenses',
    weight: 4,
    roleType: RoleType.DistrictAdministrator,
  },
  {
    id: 'class-user-statistics',
    isRouted: true,
    isExternal: false,
    route: '/overview',
    label: 'Class and user statistics',
    weight: 5,
    roleType: RoleType.DistrictAdministrator,
  },
];

/**
 * Submenu links for School Administrators in Highlights Portal.
 *
 * The label should have the first letter of the first word capitalized.
 */
export const districtAdminManageDropdownMenusHighlightsPortal = districtAdminManageDropdownMenus.filter(menu => (
  !['class-user-statistics', 'manage-classes'].includes(menu.id)
));

/**
 * Default Submenu links for School Administrators.
 *
 * The label should have the first letter of the first word capitalized.
 */
export const districtAdminZBRedesignManageDropdownMenus: NavigationMenuItem[] = [
  {
    id: 'overview',
    isRouted: true,
    route: 'overview',
    isExternal: false,
    label: 'Overview',
    weight: 1,
    roleType: RoleType.DistrictAdministrator,
  },
  {
    id: 'manage-users',
    isRouted: true,
    route: 'users',
    isExternal: false,
    label: 'Manage users',
    weight: 2,
    roleType: RoleType.DistrictAdministrator,
  },
  {
    id: 'classrooms',
    isRouted: true,
    isExternal: false,
    route: 'classrooms',
    label: 'Manage classes',
    weight: 3,
    roleType: RoleType.DistrictAdministrator,
  },
  {
    id: 'manage-district-admins',
    isRouted: true,
    isExternal: false,
    route: 'admins',
    label: 'Manage district administrators',
    weight: 4,
    roleType: RoleType.DistrictAdministrator,
  },
  {
    id: 'distribute-licenses',
    isRouted: true,
    isExternal: false,
    route: 'distribute-licenses',
    label: 'Distribute licenses',
    weight: 5,
    roleType: RoleType.DistrictAdministrator,
  },
  {
    id: 'assign-revoke-licenses',
    isRouted: true,
    isExternal: false,
    route: 'manage-licenses',
    label: 'Assign and revoke licenses',
    weight: 6,
    roleType: RoleType.DistrictAdministrator,
  },
  {
    id: 'class-user-statistics',
    isRouted: true,
    isExternal: false,
    route: 'reports',
    label: 'Class and user statistics',
    weight: 7,
    roleType: RoleType.DistrictAdministrator,
  },
];
