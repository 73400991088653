<zbp-modal-template
  [modalHeader]="params.title"
  [bodyContent]="params.bodyRef ?? stringBodyRef"
  [footerContent]="footerRef">

  <ng-template #stringBodyRef>
    <div *ngIf="params.body"
         class="body-section"
         [innerHTML]="params.body"
    ></div>
  </ng-template>

  <ng-template #footerRef>
    <zbp-button-component
      (clickHandler)="onCancelButtonClick()"
      [label]="params.cancel?.label ?? DEFAULT_CANCEL_MESSAGE"
      [iconName]="params.cancel?.iconName"
      [iconPosition]="params.cancel?.iconPosition"
      theme="ghost"
      type="button">
    </zbp-button-component>
    <zbp-button-component
      (clickHandler)="onConfirmButtonClick()"
      [label]="params.confirm?.label ?? DEFAULT_CONFIRM_MESSAGE"
      [iconName]="params.confirm?.iconName"
      [iconPosition]="params.confirm?.iconPosition"
      theme="primary"
      type="submit">
    </zbp-button-component>
  </ng-template>
</zbp-modal-template>
