<div class="loginHeader fixed-top bg-primary">&nbsp;</div>
<div class="d-flex flex-wrap align-items-center justify-content-start loginContainer w-100">
  <div class="loginFormContainerWrapper text-center">
    <section class="loginFormContainer mx-auto">
      <img id="primaryLogo" src="assets/img/logo.svg" alt="My ZB Portal" class="mx-auto d-block loginImage">
      <h1 id="subTitle" class="subLogo mvBoli text-center">for teachers and school administrators - LTI Advantage Integration</h1>
      <div id="oauth2Errors"  *ngIf="errors.length > 0" class="alert alert-danger mx-auto" role="alert">
        <div *ngFor="let error of errors; index as i" class="text-center" id="error{{i}}">{{ error }}</div>
      </div>
      <nav *ngIf="portalUrl" class="text-center">
        <a id="returnToPortalLink" class="fw-bold" href={{portalUrl}}>Return to Provider</a>
      </nav>
    </section>
  </div>
  <div class="loginSwirlContainer mt-auto ms-auto align-self-end text-end">
    <img id="swirlImage" class="loginImage loginImageSwirl" src="assets/img/zb_greyswirl_right.png" role="presentation" alt="">
  </div>
</div>
<zbp-footer></zbp-footer>
