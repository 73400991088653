export const trashCanIcon = `
<svg xmlns="http://www.w3.org/2000/svg" width="25" height="24" viewBox="0 0 25 24"
     fill="none">
  <g clip-path="url(#clip0_367_46507)">
    <path
      d="M20.375 9L19.508 21.142C19.4721 21.6466 19.2463 22.1188 18.8761 22.4636C18.5059 22.8083 18.0189 23 17.513 23H7.237C6.73114 23 6.24407 22.8083 5.87389 22.4636C5.5037 22.1188 5.27792 21.6466 5.242 21.142L4.375 9"
      stroke="var(--zbp-svg-primary-color, black)"
      stroke-miterlimit="10"
      stroke-linecap="square"
    />
    <path d="M1.375 5H23.375"
          stroke="var(--zbp-svg-primary-color, black)"
          stroke-miterlimit="10"
          stroke-linecap="square"
    />
    <path d="M8.375 5V1H16.375V5"
          stroke="var(--zbp-svg-primary-color, black)"
          stroke-miterlimit="10"
    />
  </g>
  <defs>
    <clipPath id="clip0_367_46507">
      <rect width="24" height="24"
            transform="translate(0.375)"
            fill="white"
      />
    </clipPath>
  </defs>
</svg>
`;
