import { FormFieldModel } from '@models/form-field';

export const generalUserFields = {
  roleType: {
    name: 'roleType',
    label: 'Role Type',
  } as FormFieldModel,
  externalId: {
    name: 'externalId',
    label: 'External ID',
  } as FormFieldModel,
  password: {
    name: 'password',
    label: 'Password',
  } as FormFieldModel,
  confirmPassword: {
    name: 'confirmPassword',
    label: 'Retype Password',
  } as FormFieldModel,
  currentPassword: {
    name: 'currentPassword',
    label: 'Current Password',
  } as FormFieldModel,
  student: {
    name: 'student',
    label: 'Student',
  } as FormFieldModel,
  parentFirstName: {
    name: 'parentFirstName',
    label: 'Parent First Name',
  } as FormFieldModel,
  parentLastName: {
    name: 'parentLastName',
    label: 'Parent Last Name',
  } as FormFieldModel,
  parentUserName: {
    name: 'parentUserName',
    label: 'Parent Email',
  } as FormFieldModel,
  parentUserId: {
    name: 'parentUserId',
    label: 'Parent User ID',
  } as FormFieldModel,
  firstName: {
    name: 'firstName',
    label: 'First Name',
  } as FormFieldModel,
  lastName: {
    name: 'lastName',
    label: 'Last Name',
  } as FormFieldModel,
  username: {
    name: 'username',
    label: 'Email',
  } as FormFieldModel,
  userId: {
    name: 'userId',
    label: 'User Id',
  } as FormFieldModel,
  schoolId: {
    name: 'schoolId',
    label: 'School Id',
  } as FormFieldModel,
  isSetup: {
    name: 'isSetup',
    label: 'Is Setup',
  } as FormFieldModel,
  userAssociations: {
    name: 'userAssociations',
    label: 'User Associations',
  } as FormFieldModel,
  userAssociationsToRemove: {
    name: 'userAssociationsToRemove',
    label: 'User Associations to Remove',
  } as FormFieldModel,
  userAssociationsToAdd: {
    name: 'userAssociationsToAdd',
    label: 'User Associations to Add',
  } as FormFieldModel,
};

export const studentUserFields = {
  firstName: {
    name: 'firstName',
    label: 'Student First Name',
  } as FormFieldModel,
  lastName: {
    name: 'lastName',
    label: 'Student Last Name',
  } as FormFieldModel,
  schoolId: {
    name: 'schoolId',
    label: 'School ID',
  } as FormFieldModel,
  rolesToAdd: {
    name: 'rolesToAdd',
    label: 'Roles to Add',
  } as FormFieldModel,
  username: {
    name: 'username',
    label: 'Username',
  } as FormFieldModel,
};

export const newUsersFormGroupName = 'newUsersGroup';

export const primaryFormControlNames = {
  newUsers: 'newUsers',
  roleType: 'roleType',
};
