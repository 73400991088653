export const studentRoleIcon = `
<svg xmlns="http://www.w3.org/2000/svg" width="91" height="92" viewBox="0 0 91 92"
     fill="none">
  <path
    d="M31.6638 8.2799H76.3871V65.2511M31.6638 8.2799C25.6526 8.27997 14.3516 6.87315 14.3516 19.0646C14.3516 29.7555 14.5239 74.2188 14.5239 74.2188M31.6638 8.2799V65.2511M14.5239 74.2188C14.5239 74.2188 13.7392 82.1315 31.6638 82.1315C39.5986 82.1315 46.5737 82.1315 55.8288 82.1315M14.5239 74.2188C14.5239 74.2188 14.4901 65.2511 31.6638 65.2511M14.5239 74.2188H55.8288M31.6638 65.2511H76.3871M76.3871 65.2511V74.2188M76.3871 74.2188V82.1315C74.1369 82.1315 71.245 82.1315 67.9714 82.1315M76.3871 74.2188H67.9714M67.9714 74.2188H55.8288M67.9714 74.2188V82.1315M55.8288 74.2188V82.1315M55.8288 82.1315V89.6339L61.84 84.8277L67.9714 88.8133V82.1315M41.0413 33.6005H65.8074M39.5986 26.0981V16.0167H67.9714V26.0981H48.7356H39.5986Z"
    stroke="var(--zbp-svg-primary-color, black)"
    stroke-width="3.74339"
    stroke-linecap="round"
    stroke-linejoin="round"
  />
</svg>
`;
