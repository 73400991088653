<section class="modal-template">
  <header *ngIf="modalHeader"
          class="modal-template-header">
    <h2 class="modal-template-header-text"
        [innerHTML]="modalHeader">
    </h2>
    <zbp-button-component
      *ngIf="showCloseX"
      [iconName]="zbpIconNames.x_cross"
      class="x_cross control-button"
      (clickHandler)="dismiss()"
      theme="icon">
    </zbp-button-component>
  </header>
  <div *ngIf="bodyContent"
       class="modal-template-body-content"
       [class.is-scrollable]="isScrollable">
    <ng-container [ngTemplateOutlet]="bodyContent"></ng-container>
  </div>
  <footer *ngIf="footerContent"
          class="modal-template-footer-content">
    <ng-container [ngTemplateOutlet]="footerContent"></ng-container>
  </footer>
</section>
