import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Injector, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { AppConfigService } from '@core/appconfig.service';
import { ZbPortalRedesignHelpers } from '@core/services/feature-flagged-features/zb-portal-redesign-helpers';
import { UserService } from '@core/user.service';
import { ApplicationUser } from '@models/application-user';
import { NavigationMenuItem } from '@models/navigation-menu-item';
import { CompanyCode } from '@shared/enums/company-code';
import { filter } from 'rxjs';
import { BrandedHeaderComponent } from '../branded-header/branded-header.component';
import { HeaderService } from '../header-service';
import { HeaderComponent } from '../header.component';

/**
 * ZB Legacy and Internal Admins will set Primary Menu items directly
 * Admin Moduls is used when user is an internal admin
 * Admin Module displays the new header when redesign is active or when HLPortal
 * Admin Module uses legacy logic and sets the Primary menu items directly
 * When ZB Legacy or Admin, the menu items will be passed by this component and set directly onto HeaderService
 *
 * This layer will pass off those Primary Menu items to the HeaderService for the header to display
 * All external RoleTypes for Redesign & HLPortal will have the menu items configured by BrandedHeader and HeaderService
 * Not all Components show all Primary Menu Items (such as AccountComponent and ChooseRoleComponent)
 */
@Component({
  standalone: true,
  selector: 'zbp-header-brand-handler',
  template: '<ng-container *ngComponentOutlet="componentToUse; injector: customInjector"></ng-container>',
  imports: [CommonModule]
})
export class HeaderBrandHandlerComponent implements OnInit, OnChanges {
  @Input() areaLabel: string = '';
  @Input() menus: NavigationMenuItem[] = [];
  @Input() shouldDisplayPrimaryMenus: boolean = true;
  @Output() logoClicked: EventEmitter<void> = new EventEmitter<void>();

  // Used to inject values into ZBPortal header without making updates to its existing functionality
  customInjector: Injector = null;
  componentToUse: any;

  private isZbPortal: boolean = false;
  private user: ApplicationUser = null;

  headerComponentMap = {
    [CompanyCode.HighlightsPortal]: {
      component: BrandedHeaderComponent,
    },
    [CompanyCode.ZBPortal]: {
      component: HeaderComponent,
    },
    ZbRedesign: {
      component: BrandedHeaderComponent,
    },
  };

  ngOnInit(): void {
    this.isZbPortal = this.appConfig.loginBrand === CompanyCode.ZBPortal;

    let brandComponent = this.headerComponentMap[this.appConfig.loginBrand]?.component;

    const useRedesignedIfAvailable = this.appConfig.loginBrand === CompanyCode.ZBPortal
      && this.zbPortalRedesignHelpers.isZbPortal2024Active();

    /**
     * If isZbPortal2024Active Feature Flag is active and there is a redesigned ZB component version,
     * then use the redesigned version for ZB Portal.
     */
    if (useRedesignedIfAvailable && this.headerComponentMap.ZbRedesign?.component) {
      brandComponent = this.headerComponentMap.ZbRedesign?.component;
    }

    if (brandComponent) {
      this.componentToUse = brandComponent;
    }

    this.userService.user$.pipe(
      filter(user => !!user?.profileDetail)
    ).subscribe((user: ApplicationUser) => {
      this.user = user;
      // Setup header when Legacy ZB or Internal Admin
      if (user?.isInternal || !this.zbPortalRedesignHelpers.isZbPortal2024Active()) {
        this.setupHeaderOnInitForInternalAndLegacy();
      } else {
        // Modules and Components that call the header will set if Primary Menu items should be displayed
        // Not all components display the Primary Menu Items- this is controlled by the component calling the header
        // e.g. Account component does not display primary menu items
        this.headerService.displayPrimaryHeaderMenus = this.shouldDisplayPrimaryMenus;
      }
    });

  }

  ngOnChanges(changes: SimpleChanges): void {
    if (this.user?.isInternal || !this.zbPortalRedesignHelpers.isZbPortal2024Active()) {
      this.setupHeaderWithChangesForInternalAndLegacy(changes);
    }

    // Not all components display the Primary Menu Items- this is controlled by the component calling the header
    // e.g. Account component does not display primary menu items
    if (changes.displayPrimaryHeaderMenus) {
      this.headerService.displayPrimaryHeaderMenus = this.shouldDisplayPrimaryMenus;
    }
  }

  constructor(
    private appConfig: AppConfigService,
    private headerService: HeaderService,
    private zbPortalRedesignHelpers: ZbPortalRedesignHelpers,
    private userService: UserService,
  ) { }

  /**
   * Used for Internal Admins by both brands (since AdminModule hasn't been updated with redesign) and Legacy ZB.
   */
  private setupHeaderOnInitForInternalAndLegacy(): void {
    if (this.isZbPortal) {
      if (this.zbPortalRedesignHelpers.isZbPortal2024Active()) {
        this.setupZbRedesignPortalHeaderForInternalAdmin();
      } else {
        this.setupLZbLegacyPortalHeader();
      }
    } else {
      this.setupHlPortalHeaderForInternalAdmin();
    }
  }

  /**
   * Used for Internal Admins by both brands (since AdminModule hasn't been updated with redesign) and Legacy ZB.
   * @param {SimpleChanges} changes
   */
  private setupHeaderWithChangesForInternalAndLegacy(changes: SimpleChanges) {
    if (this.isZbPortal) {
      if (changes.menus?.currentValue || changes.areaLabel?.currentValue) {
        if (this.zbPortalRedesignHelpers.isZbPortal2024Active()) {
          this.setupZbRedesignPortalHeaderForInternalAdmin();
        } else {
          this.setupLZbLegacyPortalHeader();
        }
      }
    } else if (changes.menus?.currentValue || changes.shouldDisplayPrimaryMenus.currentValue) {
      this.setupHlPortalHeaderForInternalAdmin();
    } else {
      this.headerService.currentMenus = [];
      this.headerService.displayPrimaryHeaderMenus = false;
    }

    // Refresh the headers, this will rebuild the headers if they are different
    this.headerService.refreshHeader$.next(true);
  }

  private setupHlPortalHeaderForInternalAdmin() {
    this.headerService.currentMenus = this.menus;
    this.headerService.displayPrimaryHeaderMenus = this.shouldDisplayPrimaryMenus;
  }

  private setupZbRedesignPortalHeaderForInternalAdmin() {
    this.headerService.currentMenus = this.menus;
    this.headerService.displayPrimaryHeaderMenus = this.shouldDisplayPrimaryMenus;
  }

  private setupLZbLegacyPortalHeader() {
    this.customInjector = Injector.create({
      providers: [
        { provide: this.headerService.zbPortalMenusToken, useValue: this.menus },
        { provide: this.headerService.zbPortalAreaLabelToken, useValue: this.areaLabel },
      ],
    });
  }
}
