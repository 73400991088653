export const verticalEllipsesIcon = `
<svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 25 25"
     fill="none">
  <g id="Dots">
    <g id="Group">
      <path
        id="Vector"
        d="M12.7998 10.6338C11.6998 10.6338 10.7998 11.5338 10.7998 12.6338C10.7998 13.7338 11.6998 14.6338 12.7998 14.6338C13.8998 14.6338 14.7998 13.7338 14.7998 12.6338C14.7998 11.5338 13.8998 10.6338 12.7998 10.6338ZM12.7998 4.63379C11.6998 4.63379 10.7998 5.53379 10.7998 6.63379C10.7998 7.73379 11.6998 8.63379 12.7998 8.63379C13.8998 8.63379 14.7998 7.73379 14.7998 6.63379C14.7998 5.53379 13.8998 4.63379 12.7998 4.63379ZM12.7998 16.6338C11.6998 16.6338 10.7998 17.5338 10.7998 18.6338C10.7998 19.7338 11.6998 20.6338 12.7998 20.6338C13.8998 20.6338 14.7998 19.7338 14.7998 18.6338C14.7998 17.5338 13.8998 16.6338 12.7998 16.6338Z"
        fill="var(--zbp-svg-primary-color, black)"
      />
    </g>
  </g>
</svg>
`;
