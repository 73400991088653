<header class="d-print-none">
  <div id="primaryNavigationBar" class="row gx-0 portal-navbar">
    <div class="col">
      <nav class="navbar navbar-toggleable navbar-expand navbar-inverse background-pattern br-primary"
           data-cy="header-navbar">
        <div id="headerLeft">
          <a href="#" class="link-logo" rel="icon" (click)="goHome($event)">
            <img class="logo-image" [src]='brandLogoData?.src' [alt]="brandLogoData?.alt">
          </a>
        </div>
        <div class="collapse navbar-collapse" id="navbarNavDropdown">
          <div class="navbar-nav d-flex w-100">
            <div class="navbar-left">
              <div #primaryHeaderMenu *ngFor="let menu of primaryMenuItems">
                <!-- DROPDOWN MENU ITEM -->
                  <button
                    *ngIf="!menu.isRouted"
                    data-bs-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false"
                    data-bs-auto-close="outside"
                    class="nav-item nav-link defaultTransition dropdown-toggle routed-menu-item"
                    id="{{menu.id}}MenuLink"
                    (click)="primaryMenuItemClicked($event, primaryHeaderMenu)"
                  >
                    {{ menu.label }}
                  </button>
                <div
                  #navigationDropdownMenu
                  class="dropdown-menu dropdown-menu-right dropdown-menu-container navigation-dropdown"
                  id="{{menu.id}}MenuDropdownContainer"
                >
                  <!-- ADMIN MANAGE DROPDOWN (district admin or single school school admin) -->
                  <ng-container *ngIf="isSingleAdmin(menu)">
                    <a
                      *ngFor="let adminManageOption of dropdownMenuItems"
                      class="dropdown-item defaultTransition no-submenu"
                      [id]="adminManageOption.id+'ManageMenuLink'"
                      [class.disabled]="adminManageOption.disabled"
                      [ngbTooltip]="adminManageOption.tooltip"
                      (click)="handleManageNavigation($event, adminManageOption, primaryHeaderMenu, navigationDropdownMenu)"
                    >{{ adminManageOption.label }}
                    </a>
                  </ng-container>

                  <!-- ADMIN MANAGE DROPDOWN (multiple schools school admin) -->
                  <ng-container *ngIf="isMultiAdmin(menu)">
                    <a
                      *ngFor="let school of headerService.schools"
                      tabindex="0"
                      data-bs-auto-close="outside"
                      class="dropdown-item defaultTransition has-submenu"
                      data-bs-toggle="dropdown"
                      aria-expanded="false"
                      aria-haspopup="true"
                      #schoolDropdownItem
                      [id]="school.educationalUnitId+'MenuLink'"
                      (keydown)="expandSubmenu($event, primaryHeaderMenu, schoolDropdownItem)"
                      (click)="expandSubmenu($event, primaryHeaderMenu, schoolDropdownItem)">{{ school.name }}
                      <!-- SCHOOL SUBMENU -->
                      <div class="dropdown-menu dropdown-submenu school-submenu">
                        <div class="background-pattern-container background-pattern">
                          <a
                            *ngFor="let adminManageOption of headerService.adminSubMenus[school.educationalUnitId]"
                            class="dropdown-item defaultTransition"
                            href="#"
                            tabindex="0"
                            [id]="school.educationalUnitId+adminManageOption.id+'ManageMenuLink'"
                            [class.disabled]="adminManageOption.disabled"
                            [ngbTooltip]="adminManageOption.tooltip"
                            (click)="handleManageNavigation($event, adminManageOption, primaryHeaderMenu, navigationDropdownMenu)"
                          >{{ adminManageOption.label }}
                          </a>
                        </div>
                      </div>
                    </a>
                  </ng-container>

                  <!-- TEACHER PRODUCT DROPDOWN -->
                  <ng-container *ngIf="menu.id === teacherProductsMenuId">
                    <a
                      *ngFor="let product of headerService.currentMenuProducts"
                      tabindex="0"
                      data-bs-auto-close="outside"
                      class="dropdown-item defaultTransition has-submenu"
                      data-bs-toggle="dropdown"
                      aria-expanded="false"
                      aria-haspopup="true"
                      #productDropdownItem
                      [id]="product.productLineKey+'LicensesMenuLink'"
                      (keydown)="expandSubmenu($event, primaryHeaderMenu, productDropdownItem)"
                      (click)="expandSubmenu($event, primaryHeaderMenu, productDropdownItem)">{{ product.name }}
                      <!-- PRODUCT VARIANT SUBMENU -->
                      <div class="dropdown-menu dropdown-submenu products-submenu">
                        <div class="background-pattern-container background-pattern">
                          <a
                            *ngFor="let variant of product.variants"
                            [id]="product.productLineKey+variant.variantType+'SubmenuLink'"
                            href="#"
                            class="dropdown-item defaultTransition px-1 py-1"
                            tabindex="0"
                            (keydown)="clickedDropdownSubMenuItem($event, product, variant, primaryHeaderMenu, navigationDropdownMenu)"
                            (click)="clickedDropdownSubMenuItem($event, product, variant, primaryHeaderMenu, navigationDropdownMenu)">{{ variant.name }}
                          </a>
                        </div>
                      </div>
                    </a>
                  </ng-container>

                  <!-- TEACHER CLASSES DROPDOWN -->
                  <ng-container *ngIf="menu.id === teacherClassesMenuId">
                    <div *ngIf="(headerService.classesMenuItems$ | async )?.length > 0" id="classesList" class="list-group">
                      <div *ngFor="let school of headerService.classesMenuItems$ | async" class="classes-menu-group">
                          <p class="dropdown-group-header">{{school.schoolName}}</p>
                          <a *ngFor="let c of school.classGroupBySchool; index as i"
                            class="dropdown-item no-submenu group-item"
                            [id]="c.classroomId"
                            routerLinkActive="active"
                            [routerLinkActiveOptions]="{exact: false}"
                            href="#"
                            (click)="handleClassesNavigation($event, c.classroomId, primaryHeaderMenu, navigationDropdownMenu)"
                            routerLink="/teachers/classes/{{c.classroomId}}"
                          >{{ c.name }}</a>
                         </div>
                    </div>
                    <div *ngIf="canCreateClass" class="row newClassButtonWrapper">
                      <zbp-button-component
                          tabindex="0"
                         id="newClassButton"
                         (click)="newClass(primaryHeaderMenu, navigationDropdownMenu)"
                         (keydown)="newClass(primaryHeaderMenu, navigationDropdownMenu)"
                         label="New class"
                         theme="ghost"
                         [fullWidth]="true"
                         iconName="t_cross"
                         iconSize="font-size"
                      ></zbp-button-component>
                    </div>
                  </ng-container>
                </div>

                <!-- ROUTED EXTERNAL LINK -->
                <a
                  *ngIf="menu.isRouted && menu.isExternal"
                  class="nav-item nav-link defaultTransition"
                  id="{{menu.id}}MenuLink"
                  [attr.href]="menu.route"
                  target="_blank">
                  {{ menu.label }}
                </a>
                <!-- ROUTED INTERNAL LINK -->
                <a
                  *ngIf="menu.isRouted && !menu.isExternal"
                  class="nav-item nav-link defaultTransition"
                  id="{{menu.id}}MenuLink"
                  [routerLink]="menu.route"
                  [queryParams]="menu.params"
                  [attr.aria-current]="isRouteActive(menu.route) && 'page'"
                  [class.nav-link--student]="user.isStudent"
                  [class.active]="isRouteActive(menu.route)">
                  <i *ngIf="menu.iconClass" class="pe-3 fa {{ menu.iconClass }}"
                    aria-hidden="true"></i>
                  {{ menu.label }}
                </a>
              </div>
            </div>
            <div class="navbar-right">
              <!-- MASQUERADING INDICATOR -->
              <ng-container *ngIf="isMasqueraded">
                <zbp-button-component
                  internalId="stopMasqueradingButton"
                  class="nav-item"
                  theme="icon-white"
                  [iconName]="iconNames.mask"
                  [title]="'Stop masquerading as ' + userNameSchoolId"
                  [ariaLabel]="'Stop masquerading as ' + userNameSchoolId"
                  (click)="verifyStopMasquerade()" >
                </zbp-button-component>
              </ng-container>

              <!-- USER DROPDOWN MENU -->
              <div #secondaryHeaderMenu class="nav-item dropdown ms-auto user-menu-container">
                <button id="userMenuLink"
                  class="nav-link dropdown-toggle defaultTransition"
                  data-bs-toggle="dropdown"
                  data-bs-auto-close="outside"
                  aria-haspopup="true"
                  aria-expanded="false"
                  [attr.aria-label]="'User Menu for ' + userNameSchoolId"
                  data-cy="user-menu">
                  <i class="fa fa-user-alt currentUserNameIcon"></i>
                  <span id="currentUser"
                        class="currentUserName"
                  >{{ userNameSchoolId }}</span>
                </button>
                <div #dropdownItem id="userMenuDropdown"
                  class="dropdown-menu user-menu-dropdown dropdown-menu-right w-100"
                >
                  <a id="myAccountMenuLink"
                    class="dropdown-item defaultTransition"
                    routerLink="/account"
                    routerLinkActive="active"
                    ariaCurrentWhenActive="page"
                    data-cy="account-link">
                    {{ 'myAccountLink' | translate }}
                  </a>
                  <a *ngIf="canSelfAssignLicenses"
                    id="licensesMenuLink"
                    class="dropdown-item defaultTransition"
                    routerLink="/learning/licenses"
                    routerLinkActive="active"
                    ariaCurrentWhenActive="page"
                    data-cy="licenses-link"
                    (click)="licensesClicked(secondaryHeaderMenu, dropdownItem)">
                    Licenses
                  </a>
                  <a *ngIf="user.roles.length > 1"
                    id="chooseAreaMenuLink"
                    class="dropdown-item defaultTransition"
                    (click)="chooseRoleClick()"
                    >
                    Choose Role
                  </a>
                  <a
                    *ngIf="isMasqueraded"
                    class="dropdown-item defaultTransition"
                    id="stopMasqueradingLink"
                    [title]="'Stop masquerading as ' + userNameSchoolId"
                    (click)="verifyStopMasquerade()">Stop Masquerading</a>
                  <div class="dropdown-divider"></div>
                  <a id="logOutMenuLink"
                    class="dropdown-item"
                    routerLink="/logout"
                    routerLinkActive="active"
                    data-cy="logout-link">
                    {{ 'logoutLink' | translate }}
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </nav>
    </div>
  </div>
  <zbp-subnavigation id="subnavigationBar" *ngIf="(headerService.showSubnavigation$ | async )"></zbp-subnavigation>
</header>
