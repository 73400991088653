import { IProductProfile, ISupplementalProductProfile } from '@models/classroom-profile';
import { DotNetExceptionMessage, ExceptionMessage, OneRosterErrorDetail } from '@models/exception-message';
import { KeyValuePair } from '@models/key-value-pair';
import { IProductLine } from '@models/product-line';
import { IProductVariant } from '@models/product-variant';

import { IUser, IUserUpdate } from '@models/user';
import { VariantType } from '@models/variant-type';
import { Capabilities } from '@shared/enums/capabilities';
import { copyObject } from '@shared/zb-object-helper/object-helper';
import _ from 'lodash';
import { Observable, of } from 'rxjs';
import { GradeType } from './enums/grade-type';
import { ProductType } from './enums/product-type';
import { SpellingLevelType } from './enums/spelling-level-type';
import { TopicType } from './enums/topic-type';

/**
 * Contains functions that help streamline the processing of HTTP responses
 */
export namespace Helpers {
  /**
   * Transforms a key value object (where key is a string or number and the value is of type T)
   * into an array of type T
   *
   * @export
   * @template T
   * @param {{ [id: string]: T }} dictionary
   * @returns {Array<T>}
   */
  export function mapDictionaryToArray<T>(dictionary: { [id: string]: T }): Array<T> {
    const array: T[] = [];
    Object.keys(dictionary).forEach((key) => {
      if (Object.prototype.hasOwnProperty.call(dictionary, key)) {
        array.push(dictionary[key]);
      }
    });
    return array;
  }

  /**
   * Takes a string and trys to parse it to an object, otherwise returns null.
   *
   * @export
   * @param {string} jsonString
   * @returns {Object}
   */
  export function tryParseJSON(jsonString: string): Object {
    try {
      const o = JSON.parse(jsonString);
      if (o && o instanceof Object) {
        return o;
      }
    } catch (e) {
      console.error(e);
    }
    return null;
  }

  export function mapGradeToGradeType(grade: string): GradeType {
    const key = `Grade${grade.toUpperCase()}`;
    if (GradeType[key]) {
      return GradeType[key];
    }
    return grade === 'None' ? GradeType.None : GradeType.UnknownGrade;
  }

  export function mapGradeTypeToGrade(value: GradeType | string): string {
    if (value) {
      if (value === GradeType.GradePreK) {
        return 'Pre-K';
      }

      const matches = value.toString().match(/([K0-9]+[CM]{0,1})$/i);
      if (matches) {
        return matches[1];
      }
      return value === GradeType.None ? 'None' : '';
    }

    return 'N';
  }

  export function mapGradeTypeOptions(): KeyValuePair[] {
    return Object.keys(GradeType)
      .filter(key => key !== GradeType.UnknownGrade)
      .map((key: GradeType) => ({
        key,
        value: key === GradeType.None ? 'None' : `Grade ${mapGradeTypeToGrade(key)}`,
      }));
  }

  export function mapVariantToGradeType(value: string): GradeType | string {
    let grade: GradeType | string = GradeType.None;
    // Gets the gum navigation for this classroom and product so that the topic list can be built.
    if (value && GradeType[value]) {
      grade = GradeType[value];
    } else if (value === 'Grade2C' || value === 'Grade2M') {
      grade = GradeType.Grade2;
    } else if (value === 'GradePreK') {
      grade = GradeType.GradePreK;
    } else if (value
      && (value.substring(0, 5) !== 'Grade'
      || value.substring(0, 9) === VariantType.GradeKto2
      || value.substring(0, 9) === VariantType.Grade3to5)) {
      // Fallback to using variant for non grade-based variants.
      grade = value;
    }

    return grade;
  }

  export function sortByWeight(list: any[]): any[] {
    return list.sort((a, b) => {
      if (a.weight < b.weight) {
        return -1;
      }
      if (a.weight > b.weight) {
        return 1;
      }
      return 0;
    });
  }

  export function getSchoolYearRange(year: string): string {
    const secondYear = parseInt(year, 10) + 1;

    return `${year}-${secondYear}`;
  }

  export function getSchoolYearRangeOptions(now: Date = new Date(), range: number = 5): string[] {
    const options: string[] = [];
    const schoolYear = Helpers
      .getSchoolYearRange(now.getFullYear().toString())
      .replace(/-\d+$/, '');
    for (let i = (-1 * range); i <= range; i++) {
      const schoolYearOption: string = (+schoolYear + i).toString();
      options.push(schoolYearOption);
    }
    return options;
  }

  export function mapProductTypeKeyFromValue(value: string): ProductType {
    return Object.keys(ProductType).reduce((productType, key) => (!productType && ProductType[key] === value
      ? ProductType[key]
      : productType), null);
  }

  export function downloadFile(component: {startDownload: (url: string, file: string) => Observable<boolean>}, suggestedName: string, file?: Blob): Observable<boolean> {
    if (file) {
      // Do some pre-formatting before Windows will automatically replace invalid filename characters with spaces.
      // eslint-disable-next-line no-useless-escape
      const filename = suggestedName.replace(/[\s\n\t\+=&@$^\*<>{}\(\)\[\],\.\?!:\\\/]+/g, '_').toLocaleLowerCase();
      const url = URL.createObjectURL(file);
      return component.startDownload(url, `${filename}.csv`);
    }

    return of(false);
  }

  export function buildUrlWithParameters(apiUrl: string, endpoint: string, params: KeyValuePair[]): string {
    const baseUrl = `${apiUrl}${endpoint}`;
    return params.length > 0
      ? params
        .reduce((url, param) => `${url}${param.key}=${encodeURIComponent(param.value)}&`, `${baseUrl}?`)
        .replace(/&$/, '')
      : baseUrl;
  }

  // Derives the product type based on the product line id adding missing suffix when necessary.
  export function getProductTypeFromProductLineId(productLineId: string): ProductType {
    if (productLineId) {
      const matches = productLineId.match(/^([a-z]+[0-9]+)([a-z]){0,}/i).filter(m => m);
      if (matches && matches.length > 1) {
        const key = matches[0].toLocaleLowerCase();
        return ProductType[key] ? ProductType[key] : ProductType.None;
      }
    }
    return ProductType.None;
  }

  // There is nothing relating a product to a quest in ZbPortal-Api so we have to hard code our own mapping here.
  export function getBasicProductNameFromProductTypeGrade(productType: ProductType, gradeType: GradeType | string): string {
    let productName = '';
    const grade = Helpers.mapGradeTypeToGrade(gradeType);
    switch (productType) {
    case ProductType.gum2016n:
      productName = 'Grammar, Usage, & Mechanics 2016';
      break;
    case ProductType.gum2021n:
      productName = 'Grammar, Usage, & Mechanics 2021';
      break;
    case ProductType.hw2016n:
      productName = 'Zaner-Bloser Handwriting 2016';
      break;
    case ProductType.hw2020n:
      productName = 'Zaner-Bloser Handwriting 2020';
      break;
    case ProductType.hw2025n:
      productName = 'Zaner-Bloser Handwriting 2025';
      break;
    case ProductType.hw2020tx:
      productName = 'Zaner-Bloser Handwriting 2020 Texas';
      break;
    case ProductType.hw2020txs:
      productName = 'Zaner-Bloser Handwriting 2020 Texas Sample';
      break;
    case ProductType.laesc2020n:
      productName = 'Zaner-Bloser La Escritura 2020';
      break;
    case ProductType.laesc2020tx:
      productName = 'Zaner-Bloser La Escritura 2020 Texas';
      break;
    case ProductType.laesc2020txs:
      productName = 'Zaner-Bloser La Escritura 2020 Texas Sample';
      break;
    case ProductType.spcn2016n:
      productName = 'Spelling Connections 2016';
      break;
    case ProductType.spcn2020tx:
      productName = 'Spelling Connections 2020 Texas';
      break;
    case ProductType.spcn2020txs:
      productName = 'Spelling Connections 2020 Texas Sample';
      break;
    case ProductType.spcn2022:
      productName = 'Spelling Connections 2022';
      break;
    case ProductType.jiw2021n:
      productName = 'Jump into Writing 2021';
      break;
    case ProductType.irtw2016n:
      productName = 'I Read To Write 2016';
      break;
    case ProductType.irtw2021n:
      productName = 'I Read To Write 2021';
      break;
    case ProductType.wordh2017n:
      productName = 'Word Heroes 2017';
      break;
    case ProductType.ww2017n:
      productName = 'Word Wisdom 2017';
      break;
    case ProductType.sk2015:
      productName = 'Superkids 2015';
      break;
    case ProductType.sk2017:
      productName = 'Superkids 2017';
      break;
    case ProductType.sk2026:
      productName = 'Superkids 2026';
      break;
    case ProductType.fsk2021:
      productName = 'Superkids Foundational Skills Kit';
      break;
    case ProductType.hea2011:
      productName = 'Happily Ever After';
      break;
    case ProductType.bff2025:
      productName = 'Building Fact Fluency';
      break;
    default:
      productName = 'Unknown Product';
    }

    return `${productName}, Grade ${grade}`;
  }

  export function getFullProductNameFromProductType(productType: ProductType): string {
    let productName = '';
    switch (productType) {
    case ProductType.gum2016n:
      productName = 'Grammar, Usage, and Mechanics © 2016';
      break;
    case ProductType.gum2021n:
      productName = 'Grammar, Usage, and Mechanics © 2021';
      break;
    case ProductType.hw2016n:
      productName = 'Zaner-Bloser Handwriting © 2016';
      break;
    case ProductType.hw2020n:
      productName = 'Zaner-Bloser Handwriting © 2020';
      break;
    case ProductType.hw2025n:
      productName = 'Zaner-Bloser Handwriting © 2025';
      break;
    case ProductType.hw2020tx:
      productName = 'Zaner-Bloser Handwriting © 2020 Texas';
      break;
    case ProductType.hw2020txs:
      productName = 'Zaner-Bloser Handwriting © 2020 Texas Sample';
      break;
    case ProductType.laesc2020n:
      productName = 'Zaner-Bloser La escritura © 2020';
      break;
    case ProductType.laesc2020tx:
      productName = 'Zaner-Bloser La escritura © 2020 Texas';
      break;
    case ProductType.laesc2020txs:
      productName = 'Zaner-Bloser La escritura © 2020 Texas Sample';
      break;
    case ProductType.spcn2016n:
      productName = 'Spelling Connections © 2016';
      break;
    case ProductType.spcn2020tx:
      productName = 'Spelling Connections © 2020 Texas';
      break;
    case ProductType.spcn2020txs:
      productName = 'Spelling Connections © 2020 Texas Sample';
      break;
    case ProductType.spcn2022:
      productName = 'Spelling Connections © 2022';
      break;
    case ProductType.jiw2021n:
      productName = 'Jump Into Writing © 2021';
      break;
    case ProductType.irtw2016n:
      productName = 'I Read to Write © 2016';
      break;
    case ProductType.irtw2021n:
      productName = 'I Read to Write © 2021';
      break;
    case ProductType.wordh2017n:
      productName = 'Word Heroes © 2017';
      break;
    case ProductType.ww2017n:
      productName = 'Word Wisdom © 2017';
      break;
    case ProductType.sk2015:
      productName = 'The Superkids Reading Program © 2015';
      break;
    case ProductType.sk2017:
      productName = 'The Superkids Reading Program © 2017';
      break;
    case ProductType.sk2026:
      productName = 'The Superkids Reading Program © 2026';
      break;
    case ProductType.fsk2021:
      productName = 'Superkids Foundational Skills Kit';
      break;
    case ProductType.hea2011:
      productName = 'Happily Ever After';
      break;
    case ProductType.bff2025:
      productName = 'Building Fact Fluency';
      break;
    default:
      productName = 'Unknown Product';
    }

    return productName;
  }

  export function formatWordListLevel(level: SpellingLevelType): string {
    if (level) {
      return ![SpellingLevelType.OnLevel, SpellingLevelType['on-level']].includes(level)
        ? level
        : 'On-level';
    }
    return '';
  }

  /**
   * Product types that use the Z-B supplemental experience.
   *
   * Note: some supplemental products now use the Superkids-style experience.
   */
  export const supplementalProductTypes: ProductType[] = [
    ProductType.gum2016n,
    ProductType.gum2021n,
    ProductType.hw2016n,
    ProductType.hw2020n,
    ProductType.hw2020tx,
    ProductType.hw2020txs,
    ProductType.laesc2020n,
    ProductType.laesc2020tx,
    ProductType.laesc2020txs,
    ProductType.spcn2016n,
    ProductType.spcn2020tx,
    ProductType.spcn2020txs,
    ProductType.spcn2022,
    ProductType.irtw2016n,
    ProductType.irtw2021n,
    ProductType.jiw2021n,
    ProductType.wordh2017n,
    ProductType.ww2017n,
    ProductType.kns2021,
    ProductType.kenc2023,
  ];

  // TODO: Remove with 12584 spike for student logic
  export const superkidsLegacyProductTypes: ProductType[] = [
    ProductType.hea2011,
    ProductType.sk2015,
    ProductType.sk2017,
    ProductType.sk2026,
    ProductType.fsk2021,
  ];

  /**
   * Product types that support lesson or material pages.
   */
  export const lessonMaterialPageProductTypes: ProductType[] = [
    ProductType.hea2011,
    ProductType.sk2015,
    ProductType.sk2017,
    ProductType.fsk2021,
    ProductType.hw2025n,
    ProductType.ms2024,
  ];

  /**
   * Product types that support lesson or material pages with ebooks.
   */
  export const lessonMaterialPageBookProductTypes: ProductType[] = [
    ProductType.hw2025n,
    ProductType.ms2024,
  ];

  /**
   * Product types that are for texas samples.
   */
  export const texasSampleProductTypes: ProductType[] = [
    ProductType.spcn2020txs,
    ProductType.hw2020txs,
    ProductType.laesc2020txs,
  ];

  /**
   * Product line keys to be used for route match/activate on lesson pages only.
   */
  export const lessonPageProductLineKeys: string[] = [
    'hea2011',
    'sk2015',
    'sk2017',
    'sk2026',
    'fsk2021',
    'hw2025n',
  ];

  export const productTypesWithNoClassLicenses: ProductType[] = [
    ProductType.irtw2021n,
    ProductType.jiw2021n,
    ProductType.wordh2017n,
    ProductType.ww2017n,
    ProductType.kns2021,
    ProductType.kenc2023,
    ProductType.ms2024,
    ProductType.ece2024,
  ];

  export function getProductVariantRoute(productLine: IProductLine, variant: IProductVariant, isTeacher: boolean = true): string[] {
    const route = ['/learning/products'];
    const hasLessonPage = Helpers.hasProductCapability(variant, Capabilities.LessonPage);
    const hasMaterialsPage = Helpers.hasProductCapability(variant, Capabilities.MaterialsPage);
    const hasFiveDayPlanner = Helpers.hasProductCapability(variant, Capabilities.FiveDayPlanner);

    if (hasLessonPage || hasMaterialsPage || hasFiveDayPlanner) {

      // TODO: Refactor with 12584 spike for student logic
      if (productLine.productLineKey.includes('HW2025') && !isTeacher) {
        return route.concat(productLine.productLineKey, variant.variantType);
      }

      if (!hasLessonPage && hasMaterialsPage && isTeacher) {
        return route.concat(productLine.productLineKey, variant.variantType, 'teacher', 'material');
      }

      return isTeacher
        ? route.concat(productLine.productLineKey, variant.variantType, 'teacher', 'lesson')
        : route.concat(productLine.productLineKey, variant.variantType, 'classroom');
    }

    if (Helpers.hasProductCapability(variant, Capabilities.WeeklyPlanner)) {
      return ['/learning/products/ece/weekly-planner'];
    }

    return route.concat(productLine.productLineKey, variant.variantType);
  }

  export const schoolIdToolTipMessage: string = 'You must set a school ID before managing this school.';

  export const oneRosterToolTipMessage: string = 'This feature is controlled by your institution';

  /**
   * Apply a username for passing values using IUser and/or IUserUpdate
   *  - i.e. when ApplicationUser and its constructor usename
   *    logic are not to be applied
   * @param sourceUser
   * @param targetUser
   * @param usernameToApply
   */
  export function applyUsername(sourceUser: IUser, targetUser: IUser | IUserUpdate,
    usernameToApply): IUser | IUserUpdate {
    let username;
    // Student
    if (sourceUser.isStudent) {
      if (!usernameToApply) {
        // use current
        username = sourceUser.systemName;
      } else if (!usernameToApply.endsWith(`_${sourceUser.abbreviatedSchoolId}`)) {
        // apply new
        username = `${usernameToApply}_${sourceUser.abbreviatedSchoolId}`;
      } else {
        username = usernameToApply;
      }
    } else if (!usernameToApply) {  // non-student
      // use current
      username = sourceUser.username;
    } else {
      // apply new
      username = usernameToApply;
    }
    // cleanup user[N]ame
    const cleanup = (targetUser as any);
    delete cleanup.userName;
    // write to target
    return Object.assign(targetUser, {
      username
    });
  }

  export function getEnumKeyByEnumValue(myEnum: any, enumValue: number | string): string {
    const keys = Object.keys(myEnum).filter(x => myEnum[x] === enumValue);
    return keys.length > 0 ? keys[0] : '';
  }

  /** gets the full <base href=""> path */
  export function getBaseUrl(): string {
    return (document.getElementsByTagName('base')[0] || { href: '' }).href;
  }

  export function sanitizePageNumber(page: number): number {
    const pattern = /^\d{1,3}$/;
    let pageNumber: number;

    if (typeof page === 'number' && page >= 0) {
      pageNumber = page;
    } else if (typeof page === 'string' && pattern.test(page)) {
      pageNumber = Number.parseInt(page, 10);
    } else {
      pageNumber = 0;
    }

    return pageNumber;
  }

  export function sortClassroomProfilesByProductAndGrade(profiles: IProductProfile[] | ISupplementalProductProfile[]): IProductProfile[] | ISupplementalProductProfile[] {
    let clonedProfiles = profiles;

    // Replace Grade K with Grade 0 so that it will sort before Grade 1
    clonedProfiles = clonedProfiles.map((profile) => {
      let { description } = profile;
      // if (profile.description.includes('Grade K')) {
      description = profile.description.replace('Grade K', 'Grade 0');
      // }
      return copyObject({ ...profile, description },
        (clonedProfiles[0] instanceof ISupplementalProductProfile ? ISupplementalProductProfile : IProductProfile));
    });

    clonedProfiles = clonedProfiles.sort(
      (a, b) => (a.description.localeCompare(b.description))
    );

    // Return the descriptions back to how we got them
    const clonedProfile: IProductProfile[] | ISupplementalProductProfile[] = clonedProfiles.map((profile) => {
      let { description } = profile;
      description = profile.description.replace('Grade 0', 'Grade K');
      return copyObject({ ...profile, description },
        (clonedProfiles[0] instanceof ISupplementalProductProfile ? ISupplementalProductProfile : IProductProfile));
    });
    return clonedProfile;
  }

  export function setMessages(messages: string[] | any[] | DotNetExceptionMessage[] | OneRosterErrorDetail[]) {
    if (messages.length > 0) {
      if (typeof messages[0] === 'string') {
        return messages;
      }
      if (_.has(messages[0], 'ExceptionDetail')) {
        const exceptionMessages: DotNetExceptionMessage[] = messages as DotNetExceptionMessage[];
        return exceptionMessages.map(em => em.ExceptionDetail.Message as string);
      }
      if (_.has(messages[0], 'exceptionDetail')) {
        const exceptionMessages: ExceptionMessage[] = messages as ExceptionMessage[];
        return exceptionMessages.map(em => em.exceptionDetail.message as string);
      }
      if (_.has(messages[0], 'Message')) {
        // Most likely a OneRosterError.
        const exceptionMessages: OneRosterErrorDetail[] = messages as OneRosterErrorDetail[];
        return exceptionMessages.map(em => em.Message as string);
      }
      return ['An error occurred during this request'];
    }
    return [];
  }

  export function getProductLineFolder(productLine: string): string {
    let productLineFolder = productLine;
    if (productLine.endsWith('n')) {
      productLineFolder = productLine.slice(0, -1);
    }
    return productLineFolder;
  }

  export const vendorTopicTypes: TopicType[] = [
    TopicType.CustomFreeWrite,
    TopicType.FreeWrite,
    TopicType.Trace,
  ];

  export const licenseDurationInDays: number = 366;

  export const oAuthMissingStateMessage: string = 'State cannot be null';

  export const schoolIdMinLength: number = 4;

  export const schoolIdMaxLength: number = 30;

  export function hasProductCapability(productVariant: IProductVariant, capability: Capabilities): boolean {
    return productVariant.skus.length > 0
      && productVariant.skus.some(s => s.teacherCapabilities?.split(',').includes(capability));
  }

  export function isNullOrUndefined(value: any) {
    return value === undefined || value === null;
  }

  export function getLastActivityTime(time: string) {
    return time === 'not setup' ? 'N/A' : time;
  }
}
