<nav class="loginHeader fixed-top bg-primary" aria-hidden="true">&nbsp;</nav>
<div class="d-flex flex-wrap align-items-center justify-content-start loginContainer w-100" [ngClass]="{'loginContainerIE': browserService.isIE }">
  <div class="backToZanerBloserContainer align-self-start">
    <div id="backToZanerBloserLinkContainer" class="text-bold ms-4 mt-2 mvBoli">
      <span class="d-block loginText">back to</span>
      <a id="backToZanerBloserLink" class="d-block ps-1 btn-link" [attr.href]="backToZanerBloserLink">zaner-bloser.com</a>
    </div>
  </div>
  <div class="loginFormContainerWrapper text-center">
    <div class="loginFormContainer mx-auto">
      <img id="primaryLogo" src="assets/img/logo.svg" alt="My ZB Portal" class="mx-auto d-block loginImage">
      <h1 class="subLogo mvBoli">for students</h1>
      <div id="error" *ngIf="error" class="alert alert-danger mx-auto" role="alert" [innerHTML]="error"></div>

      <div id="'studentLoginContainer" class="d-flex flex-wrap justify-content-between align-items-start w-100">

        <div *ngIf="!isScanningQRCode" class="w-50 h-100 vertical-separator">
          <form (ngSubmit)="onSubmit()" id="studentLoginForm" #Login="ngForm" class="loginForm text-center" *ngIf="!showPasswordResetMessage">
            <div class="mb-3">
              <input aria-label="username" placeholder="username" type="text" class="form-control" id="username" required [(ngModel)]="username" name="username" #usernameV="ngModel" [class.is-invalid]="(!usernameV.valid && usernameV.touched)" />
            </div>
            <div class="mb-3">
              <input aria-label="password" placeholder="password" type="password" class="form-control" id="password" required [(ngModel)]="password" name="password" #passwordV="ngModel" autocomplete="off" [class.is-invalid]="(!passwordV.valid && passwordV.touched)" />
            </div>
            <div class="mb-3 mx-auto">
              <input aria-label="School ID" placeholder="School ID" type="text" class="form-control" id="schoolId" required [(ngModel)]="schoolId" name="schoolId" #schoolIdV="ngModel" [class.is-invalid]="(!schoolIdV.valid && schoolIdV.touched)" />
            </div>
            <div id="links" class="text-end">
              <button id="forgotPassword" type="button" (click)="showPasswordResetMessage=true" class="d-inline-block mb-2 px-0 btn btn-link">Forgot your password?</button>
            </div>
            <div>
              <button id="loginSubmit" [disabled]="loginProcessing" type="submit" class="btn btn-outline-tertiary text-uppercase btn-sm" [attr.aria-disabled]="!Login.form.valid">Log In</button>
              <i *ngIf="loginProcessing" class="fa fa-spin fa-circle-notch" aria-label="Logging in" id="loginIndicator"></i>
            </div>
          </form>
          <div id="passwordResetMessage" *ngIf="showPasswordResetMessage" class="loginForm text-center">
            If you can’t remember your password, please ask your teacher to reset it for you.
            <br />
            <button id="dismissResetMessage" (click)="showPasswordResetMessage=false" class="btn btn-outline-tertiary text-uppercase btn-sm mt-3">OK</button>
          </div>
          <zbp-login-sso></zbp-login-sso>
        </div>

        <div *ngIf="!isScanningQRCode" class="w-50 h-100">
          <img class="qr-code-help" src="assets/img/QRcode_graphic.png" alt="" (click)="getQRCodeHelp()"/>
          <button id="scanYourBadge" (click)="prepQRCodeScan()" class="btn btn-outline-tertiary text-uppercase btn-sm mt-3">Scan Your QR Code</button>
        </div>

        <div class="w-100">
          <!-- need to use the style attribute as it overrides the underlying canvas and video elements styling - class-based CSS ineffective -->
          <div id="qrCodeScanner" style="width: 400px; height: auto; margin: 0 25%"></div>
          <div *ngIf="isScanningQRCode" class="py-2">
            <button id="cancelScan" (click)="cancelQRCodeScan()" class="btn btn-outline-tertiary text-uppercase btn-sm mt-3">Cancel</button>
          </div>
        </div>

      </div>
    </div>
  </div>
  <div class="loginOperationsContainer">
    <a id="switchToTeacher" data-cy="switch-teacher-link" class="align-self-end btn-link" routerLink="/login/user">
      <span class="fw-bold">I'm a teacher/admin!</span> (switch to the teacher portal)
    </a>
    <a id="switchToParent" class="align-self-end btn-link" routerLink="/login/parent">
      <span class="fw-bold">I'm a parent!</span> (switch to the parent portal)
    </a>
  </div>
  <div class="loginSwirlContainer mt-auto ms-auto align-self-end text-end">
    <img id="swirlImage" class="loginImage loginImageSwirl" src="assets/img/zb_greyswirl_right.png" role="presentation" alt="">
  </div>
</div>
<zbp-footer></zbp-footer>
