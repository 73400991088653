import { Injectable } from '@angular/core';
import { environment } from '@environments/environment';
import { UserStatusResponse } from '@models/user-response';
import { CompanyCode } from '@shared/enums/company-code';
import { CompanyHost } from '@shared/enums/company-host';
import { BehaviorSubject, Observable } from 'rxjs';
import { CacheService } from './cache.service';

@Injectable({
  providedIn: 'root',
})
export class AppConfigService {
  private _apiUrl: string = null;
  private _assetUrl: string = null;
  private _siteStatus: BehaviorSubject<boolean>;
  private _ltiLaunchPresentationReturnUrl: string = null;

  siteStatusMessage: string = '';
  siteTitle: string = 'Site Maintenance';
  siteStatus: Observable<boolean>;
  inSkofApp = false;

  // store the URL so we can redirect after logging in.
  redirectUrl: string;

  /**
   * The login brand represents brand identified by the URL when the app is loaded.
   */
  loginBrand$: BehaviorSubject<CompanyCode> = new BehaviorSubject(CompanyCode.ZBPortal);

  constructor(
    private cache: CacheService,
  ) {
    this.useProxyApi();
    this._siteStatus = new BehaviorSubject<boolean>(true);
    this.siteStatus = this._siteStatus.asObservable();
  }

  get apiUrl(): string {
    return this._apiUrl;
  }

  set apiUrl(value: string) {
    this._apiUrl = value;
  }

  get avatarUrl(): string {
    return `${this._assetUrl}avatar`;
  }

  set assetUrl(assetUrl: string) {
    this._assetUrl = assetUrl;
  }

  get assetUrl(): string {
    return this._assetUrl;
  }

  setSiteStatus(value: boolean) {
    this._siteStatus.next(value);
  }

  get isProduction(): boolean {
    return environment.environment === 'prod';
  }

  get isHighlightsPortalUrl(): boolean {
    return this.loginBrand === CompanyCode.HighlightsPortal;
  }

  getAuthUrl(path: string): string {
    return `${this.apiUrl}/${path}`;
  }

  setLoginBrand(value: CompanyCode) {
    this.loginBrand$.next(value);
  }

  get loginBrand(): CompanyCode {
    return this.loginBrand$.value;
  }

  setBrandFromUrl() {
    // Set loginBrand based on the URL
    let loginBrand = CompanyCode.ZBPortal;
    if (window.location.hostname.includes(CompanyHost.HighlightsPortal)) {
      loginBrand = CompanyCode.HighlightsPortal;
    }
    this.setLoginBrand(loginBrand);
  }

  useProxyApi(enabled: boolean = false) {
    if (enabled && environment.proxyApiUrl) {
      this._apiUrl = environment.proxyApiUrl;
    } else {
      this._apiUrl = environment.apiUrl;
    }
  }

  clearCustomData(): void {
    this._assetUrl = null;
  }

  setCustomUrls(data: UserStatusResponse) {
    this._assetUrl = data.assetUrl;
  }

  set ltiExternalUrl(value: string) {
    this._ltiLaunchPresentationReturnUrl = value;
  }

  get ltiExternalUrl(): string {
    return this._ltiLaunchPresentationReturnUrl;
  }

  get getProductsRoute(): string[] {
    switch (this.loginBrand) {
    case CompanyCode.HighlightsPortal:
      return ['/learning', 'products', 'ece'];
    case CompanyCode.ZBPortal:
    default:
      return ['/learning', 'products'];
    }
  }

  brandOptions<T>(options: {
    highlights?: T,
    zbportal?: T,
  }): T | undefined {
    switch (this.loginBrand) {
    case CompanyCode.HighlightsPortal:
      return options.highlights;
    default:
      return options.zbportal;
    }
  }
}
