import { CommonModule } from '@angular/common';
import { Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges } from '@angular/core';
import {
  AbstractControl,
  ControlContainer,
  FormArray,
  FormBuilder,
  FormControl,
  FormControlStatus,
  FormGroup,
  ReactiveFormsModule,
  ValidatorFn,
  Validators
} from '@angular/forms';
import { Router } from '@angular/router';
import { UserService } from '@core/user.service';
import { ApiResponse } from '@models/api-response';
import { Classroom } from '@models/classroom';
import { FormFieldModel } from '@models/form-field';
import { School } from '@models/school';
import { IUser } from '@models/user';
import {
  NgbActiveModal,
  NgbModal,
  NgbModule,
  NgbTooltip,
} from '@ng-bootstrap/ng-bootstrap';
import { AlertBarContainerComponent } from '@shared/alert-bar-container/alert-bar-container.component';
import { ButtonComponent } from '@shared/button/button.component';
import { RoleGroup, RoleType } from '@shared/enums/role-type';
import { FormHelpers } from '@shared/form-helpers';
import { Helpers } from '@shared/helpers';
import { GenericConfirmModalComponent } from '@shared/modals/generic-confirm-modal/generic-confirm-modal.component';
import {
  PasswordRequirementsTooltipComponent
} from '@shared/password-requirements-tooltip/password-requirements-tooltip.component';
import { PreloaderModule } from '@shared/preloader/preloader.module';
import { UserSearchWithDropdownComponent } from '@shared/user-search-with-dropdown/user-search-with-dropdown.component';
import { zbpIconNames } from '@shared/zbp-icon/zbp-icon-names';
import { ToastrService } from 'ngx-toastr';
import { BehaviorSubject, from, Observable, of, Subscription } from 'rxjs';
import { catchError,  map,  mergeMap } from 'rxjs/operators';

import { generalUserFields, primaryFormControlNames, studentUserFields } from './constants/form-field-setup-data';
import { UserFormMode } from './constants/form-mode';
import { ParentRoleHelpers } from './helpers/parent-role-helpers';
import { StudentRoleHelpers } from './helpers/student-role-helpers';
import { TeacherRoleHelpers } from './helpers/teacher-role-helpers';

/**
 * Form handles managing (creating and updating) all school-related users.
 * Form is reusable and can be added to any existing form.
 *
 * - In Edit mode, the form supports editing 1 user.
 *
 * - In Add mode, the form can support creating 1 or multiple users of the same RoleType.
 *
 * Manages a single role (e.g. Can add multiple Teachers, but cannot add a Parent on the same instance of the form.)
 *
 * Utilizes ControlContainer to access its containing form and add a new group to the containing form.
 * - By default the formGroup will be added to the containing form with a name of `newUsersGroup`.
 * - A custom name can be provided (through `newUsersFormGroupName`)
 *   to reference this form group by its containing form.
 * - **Must provide a unique newUsersFormGroupName when adding more than 1 instance of this component
 *  to the same containing form.**
 *
 * The below example uses 'containingForm' as an example for a containing form:
 *
 *       containingForm.get('theValueOfNewUsersFormGroupName')

 * ___
 *
 * **If receiving the ExpressionChangedAfterItHasBeenCheckedError, the parent form may not be aware of the changes.
 * See below explanation:**
 *
 * When the roleType is configured by the parent component (and not directly set),
 * the component containing the parent form must call the ChangeDetectorRef in ngAfterViewInit().
 * This will enable the parent form to be aware of the changes made to set the roleType form field.
 * If receiving the ExpressionChangedAfterItHasBeenCheckedError, the parent form may not be aware of the changes.
 */
@Component({
  standalone: true,
  selector: 'zbp-branded-manage-users-form',
  templateUrl: './manage-users-form.component.html',
  styleUrls: ['./manage-users-form.component.scss'],
  imports: [
    ButtonComponent, ReactiveFormsModule, CommonModule,
    NgbModule, UserSearchWithDropdownComponent, AlertBarContainerComponent,
    PasswordRequirementsTooltipComponent, PreloaderModule
  ]
})
export class ManageUsersFormComponent implements OnDestroy, OnInit, OnChanges {
  constructor(
    private fb: FormBuilder,
    private userService: UserService,
    private toastr: ToastrService,
    private controlContainer: ControlContainer,
    private router: Router,
    public activeModal: NgbActiveModal,
    private modalService: NgbModal,
  ) {
    // Callback used when students add a parent from search, needs bound to class when called by other component
    this.addParentRowFromUserSearchToStudent  =  this.addParentRowFromUserSearchToStudent.bind(this);
    this.setAddParentButtonText  =  this.setAddParentButtonText.bind(this);
    this.addAnother  =  this.addAnother.bind(this);
    this.userSearchSelectionFunction  =  this.userSearchSelectionFunction.bind(this);
    this._originalSchoolId = this.school?.schoolId;
  }

  /**
   * Form has 2 modes: Add and Edit.
   *
   * Use 'Add' when creating a new user.
   *
   * Use 'Edit' when updating an existing user.
   */
  @Input() formMode: UserFormMode = UserFormMode.Add;
  /** Role type being managed */
  @Input() roleType: RoleType = null;
  /** School the user belongs to or will be added to. */
  @Input() school: School;
  /** Specify the min and max number of users */
  @Input() minRequired: number = 1;
  /** Limits how many users can be added. */
  @Input() maxRequired: number = undefined;
  /** Flag to indicate if multiple users can be added when form in Add mode. */
  @Input() canAddMultipleUsers: boolean = false;
  /** Flag to hide the delete button on the first row. */
  @Input() hideFirstRowDeleteButton: boolean = false;
  /** Name of the form group for new users. How the group can be referenced by its containing form. */
  @Input() usersAndRoleGroupName = 'newUsersGroup';
  /** Label for the "Add Another" user button if canAddMultipleUsers is true. */
  @Input() addAnotherUserLabel = 'Add Another';
  /** Custom class if needed applied to component*/
  @Input() containerClass: string = undefined;
  /** Flag for displaying existing user search */
  @Input() canUserSearch = false;
  /** Flag for if userId should be included in use form data */
  @Input() includeUserId = false;
  /** If supplied, will display text in the alert bar container */
  @Input() alertBarText: string = undefined;
  /** User to edit */
  @Input() user: IUser = null;
  /** Tracked by its containing component for better visibility when submitting from different components. */
  @Input() hasUnsavedChanges: BehaviorSubject<boolean>;
  /**
   * Provided by the outer component when needed to track which user is being edited.
   *
   * Edit modal tracks user so modal will switch from displaying Parent username to Student
   * when editing a student from the parent form.
   */
  @Input() trackSelectedUser: Function;
  /** If the first user on the form should be set to the current user */
  @Input() setFirstUser: boolean;

  /**
   * User that is currently authenticated in the app.
   *
   * This is not the user that is being edited.
   */
  currentApplicationUser: IUser;
  /** Form Group containing roleType field and newUsers Array */
  form: FormGroup = new FormGroup({});
  /** Search box used to search users. Parents can search Students and vice versa. */
  searchBox: FormControl = new FormControl();
  /** Containing form that this manage users form is added into. */
  controlContainerForm: FormGroup = null;
  /** These are dynamically configured based on roleTypeToAdd. Student names field labels have 'student' prepended. */
  formFieldNames: { [key: string]: FormFieldModel };
  /**If form is Editing an existing user */
  isInEditMode: boolean = false;
  /** Toggles between 'Add Parent' and 'Add Another Parent'  */
  addParentButtonText: string = 'Add Parent';
  /** Form has completed setup for the supplied role and user, when applicable. */
  initialConfigurationComplete: boolean = false;
  /** When form is loading any data. */
  isLoading: boolean = true;
  /** Schools from current authenticated user to use in the dropdown */
  schools: School[] = [];
  /** Will show dropdown of currentApplicationUser's schools */
  showSchoolDropdown: boolean = false;
  /** When editing Students and Parents, a list of classrooms they're associated with displays
   * If this value is `true` and the User is not associated to any classrooms, then the classroom list will not display.
  */
  showClassroomList: boolean;
  /** When editing Parent, a list of students they're associated with displays
   * If this value is `true` and the User is not associated to any students, then the students list will not display.
  */
  showStudentsList: boolean;
  educationalUnitId: string = null;
  usesOneRoster: boolean = false;
  isManagingDistrictAdmin: boolean = false;
  isManagingSchoolAdmin: boolean = false;
  isManagingTeacher: boolean = false;
  isManagingStudent: boolean = false;
  isManagingParent: boolean = false;
  /**
   * By default, all OneRoster users cannot have personal info edited,
   * except for Parents (unless Parent has a higher role, such as Teacher or Admin).
   */
  userCanBeEditedIfOneRoster: boolean = false;

  readonly oneRosterTooltipText: string = Helpers.oneRosterToolTipMessage;
  readonly zbpIconNames = zbpIconNames;
  readonly RoleType = RoleType;

  /**
   * Initializes to the current School's ID.
   *
   * In Edit Mode when managing a Student, a school dropdown displays of the currentApplicationUser's Schools
   * that will have this value as the selected School.
   */
  private _currentSelectedSchoolId: string;
  private _originalSchoolId: string;
  private subscriptions: Subscription[] = [];
  private usernameValidators = [Validators.required];
  private initialFormValues: string = null;
  private formChangesSubscription: Subscription;

  private readonly firstNameValidators = [Validators.required];
  private readonly lastNameValidators = [Validators.required];
  private readonly userIdValidators = [Validators.required];

  /**
   * Gets the FormArray of users being added
   * @returns {FormArray} The FormArray for new users.
   */
  get newUsers(): FormArray {
    return this.form.get(primaryFormControlNames.newUsers) as FormArray;
  }

  /**
   * Checks if the form has password fields.
   * Not all roleTypes have password fields.
   * @returns {boolean} True if the form has password fields, false otherwise.
   */
  get hasPasswordFields(): boolean {
    if (this.isInEditMode) {
      const isAdmin = this.user.isSchoolAdmin || this.user.isDistrictAdmin || this.user.isElevatedAdmin;
      // Cannot change a user's password if they're also an admin.
      if (isAdmin) {
        return false;
      }
      if (this.isManagingStudent && !this.currentApplicationUser.profileDetail?.canChangeStudentPassword) {
        return false;
      }

      if (this.isManagingTeacher) {
        return true;
      }
    }

    return this.isManagingStudent;
  }

  /**
   * Checks if the form has parent fields.
   * Not all roleTYpes have parent fields.
   * @returns {boolean} True if the form has parent fields, false otherwise.
   */
  get hasParentFields(): boolean {
    return this.isManagingStudent && this.canManageParent;
  }

  /**
   * Checks if the user can manage parents.
   * @returns {boolean} True if the user can manage parents, false otherwise.
   */
  get canManageParent(): boolean {
    return this.userService.user.profileDetail?.canManageParent;
  }

  /**
   * Gets the student form control for specified index in the newUsers form array.
   * @param {number} i - The index of the form control.
   * @returns {FormControl} The student form control.
   */
  getStudentFormControl(i): FormControl {
    return this.newUsers.at(i)?.get(this.formFieldNames.student.name) as FormControl;
  }

  /**
   * Gets userAssociations for Student
   * @param {AbstractControl} studentFormGroup FormGroup for Student
   */
  getParentsArray(studentFormGroup: AbstractControl): FormArray {
    return ParentRoleHelpers.getExistingParents(studentFormGroup, this.setAddParentButtonText);
  }

  ngOnInit(): void {
    this.controlContainerForm = this.controlContainer.control as FormGroup;
    this.isInEditMode = this.formMode === UserFormMode.Edit;

    // Dynamically built based on role types.
    this.formFieldNames = generalUserFields;
    this.currentApplicationUser = this.userService.user;
    this.setupIsAddingRoleType();
    this.setupSchoolData();

    if (this.roleType) {
      this.setupFormGroupForAllFormModes();
      if (!this.isInEditMode) {
        // Prepopulate minimum number of rows if no users already exist in form
        if (this.newUsers?.length === 0) {
          this.addMinimumNumberOfRows();
        }
      }
    }

    if (this.setFirstUser && !this.isInEditMode) {
      const userArray = this.form.controls.newUsers as FormArray;
      const firstUser = userArray.controls.at(0) as FormGroup;
      firstUser.controls.firstName.setValue(this.currentApplicationUser.firstName);
      firstUser.controls.lastName.setValue(this.currentApplicationUser.lastName);
      firstUser.controls.userId.setValue(this.currentApplicationUser.userId);
      firstUser.controls.username.setValue(this.currentApplicationUser.username);
      firstUser.disable();
    }

    this.initialConfigurationComplete = true;
    this.isLoading = false;
    this.initialFormValues = this.form.getRawValue();
    this.subscribeToCheckForFormChanges();
  }

  /** Updates the form group based on any roleType changes after the initial form has been configured */
  ngOnChanges(changes: SimpleChanges): void {
    if (this.initialConfigurationComplete && changes.user) {
      // Update user and handle the new role
      if (this.isInEditMode && (changes.user || changes.roleType)) {
        this.formChangesSubscription?.unsubscribe();
        this.isLoading = true;

        this.setupIsAddingRoleType();
        this.assignFieldNames();
        this.setupSchoolData();

        this.setupFormGroupForAllFormModes();
        this.subscribeToCheckForFormChanges();

        this.isLoading = false;
      }
    } else if (this.initialConfigurationComplete && changes.roleType) {
      // handle a role change (when adding user, the user will be null)
      this.roleType = changes.roleType.currentValue;
      this.form.get(primaryFormControlNames.roleType).setValue(this.roleType);

      this.setupIsAddingRoleType();
      this.assignFieldNames();

      if (!this.isInEditMode) {
        this.resetUsersForm();
      } else {
        this.setupFormGroupForAllFormModes();

        if (this.newUsers?.length === 0) {
          this.addMinimumNumberOfRows();
        }
      }
    }
    this.isLoading = false;
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(subscription => subscription.unsubscribe());
  }

  /** Used to display the form has unsaved changes message. */
  subscribeToCheckForFormChanges(): void {
    // If hasUnsavedChanges was provided, then check for unsaved changes
    if (this.hasUnsavedChanges) {
      this.hasUnsavedChanges.next(false);
      this.formChangesSubscription =  this.form.valueChanges.subscribe(() => {
        if (this.initialConfigurationComplete && !this.isLoading) {
          const currentValues = this.form?.getRawValue();
          const isBackToOriginal = JSON.stringify(currentValues) === JSON.stringify(this.initialFormValues);
          this.hasUnsavedChanges.next(!isBackToOriginal);
        }
      });

      this.subscriptions.push(this.formChangesSubscription);
    }
  }

  /** Updates the 'add parent' button text on Student form. */
  private setAddParentButtonText(updatedText: string): void {
    this.addParentButtonText = updatedText;
  }

  /** Gets userAssociations for parent
   *  @param {AbstractControl} parentFormGroup FormGroup for Parent
  */
  getStudentsArray(parentFormGroup: AbstractControl): FormArray {
    return ParentRoleHelpers.getExistingStudents(parentFormGroup);
  }

  /**
   * Adds another user to the newUsers form array.
   *
   * Will push it into the newUsers array and all new fields for that user will be mapped to that index.
   */
  addAnother(): void {
    this.addNewUserRow(this.newUsers?.length);
  }

  /**
   * Removes a user row from the newUsers form array.
   * @param {number} index - The index of the user in the newUsers FormArray to remove.
   */
  removeRow(index: number): void {
    this.newUsers.removeAt(index);
  }

  /**
   * Clears newUsers form array.
   * Then adds a number of rows equal to the minimum required configuration.
   */
  resetUsersForm(addMinimumRows: boolean = true): void {
    this.newUsers.clear();
    if (addMinimumRows) {
      this.addMinimumNumberOfRows();
    }
  }

  /**
   * Adds a number of new rows equal to the minimum required configuration.
   */
  addMinimumNumberOfRows(): void {
    if (!this.isInEditMode) {
      if (this.minRequired > 0) {
        for (let i = this.newUsers?.length; i < this.minRequired; i++) {
          const nextRowIndex = this.newUsers.length;
          this.addNewUserRow(nextRowIndex);
        }
      }
    }
  }

  addParentRowFromUserSearchToStudent(parentResultClickEvent) {
    StudentRoleHelpers
      .addParentRowFromUserSearchToStudent(parentResultClickEvent, this.newUsers, this.formFieldNames);
  }

  addAnotherParentClickHandler(indexOfStudentToAdd: number) {
    StudentRoleHelpers
      .addParentRowToEditStudentUserForm(null, indexOfStudentToAdd, this.newUsers, this.formFieldNames);
  }

  removeParentFromFormClickHandler(userIndex: number, parentIndex: number) {
    StudentRoleHelpers.removeParentAssociationsFromForm(userIndex, parentIndex, this.newUsers, this.formFieldNames);
  }

  /**
   * Shows a confirmation modal that Student will be not be accessible by Parent when removing Student.
   *
   * @param {string} parentIndex: Index of Parent in NewUsers array
   * @param {number} studentIndex: Index of Student in Parent's user associations being removed
   * @param {string} studentDisplayName: Username of Student being removed
   */
  removeStudentFromParentClickHandler(parentIndex: number, studentIndex: number, studentDisplayName: string) {
    const confirmModal = GenericConfirmModalComponent.open(this.modalService, {
      title: `Remove Student -  ${studentDisplayName}`,
      body: `Once removed, this parent will be unable to view information relating to this student in the Parent Portal.`,
      options: { size: 'md' },
      confirm: {
        label: 'Remove',
      }
    });

    this.subscriptions.push(
      from(confirmModal.result)
        .pipe(
          catchError(() => of(false)),
          mergeMap((confirm: boolean) => {
            if (confirm) {
              ParentRoleHelpers.removeStudentAssociationFromAssociations(
                parentIndex,
                studentIndex,
                this.newUsers,
                this.formFieldNames
              );
            }
            return of(null);
          })
        )
        .subscribe()
    );
  }

  isAddAnotherParentDisabled(studentIndexInNewUsers: number): boolean {
    const userAssociations = this.newUsers
      .at(studentIndexInNewUsers)
      ?.get(generalUserFields.userAssociations.name) as FormArray;

    return userAssociations?.length >= 4;
  }

  userSearchSelectionFunction = (event) => {
    if (this.roleType === RoleType.Student) {
      const warningReturned = StudentRoleHelpers
        .addExistingStudent(event, this.newUsers, this.addAnother, this.formRowHasEmptyValues);
      if (warningReturned) {
        this.toastr.warning(warningReturned);
      }
    } else if (this.roleType === RoleType.Teacher) {
      TeacherRoleHelpers.addExistingTeacher(
        event,
        this.newUsers,
        this.formRowHasEmptyValues,
        this.addAnother,
        this.toastr
      );
    } else {
      throw new Error('Role Type not implemented');
    }
  };

  /**
   * Navigates to the Classroom for the User
   *
   * @param event: Click event that will have its default prevented.
   * @param classroom: Classroom to navigate user to
   * @returns boolean if navigation to classroom was possible
   */
  goToClassroom(event: Event, classroom: Classroom): boolean {
    event.preventDefault();

    const pattern = /^\/districts\/([a-z0-9-]+)/i;
    const matches = this.router.url.match(pattern);
    const id = matches ? matches[1] : classroom.educationalUnitId;
    // Currently routes to districts and schools experience for Class Mangagement.
    const base = matches ? '/districts' : '/schools';

    this.router.navigate([base, id, 'classrooms', classroom.classroomId])
      // Call dismiss on modal to close and prompt user if any unsaved changes before closing modal
      .then(() => this.activeModal.dismiss())
      .catch((err) => {
        console.error(err);
        this.toastr.error('An error occurred navigating to this classroom.');
      });

    return false;
  }

  openEditStudentModal(event: Event, studentId: string) {
    event.preventDefault();
    if (this.hasUnsavedChanges?.value) {
      this.subscriptions.push(
        from(this.confirmUnsavedChanges())
          .pipe(
            catchError(() => of(false)),
            map((confirmed: boolean) => {
              // If user wants to discard changes, switch modal to Student
              if (confirmed) {
                this.isLoading = true;
                this.getNewUser(studentId);
              }
            }),
          ).subscribe()
      );
    } else {
      this.isLoading = true;
      this.getNewUser(studentId);
    }
  }

  getNewUser(userId: string) {
    this.isLoading = true;
    this.userService.getUser(userId).pipe(
    ).subscribe((response: ApiResponse<IUser>) => {
      if (response) {
        if (response.success) {
          this.resetEditFormWithNewUser(response.response, RoleType.Student);
          if (this.trackSelectedUser) {
            this.trackSelectedUser(this.user, RoleType.Student);
          }
        }
      }
    });
  }

  /**
   * Setups all data for the form related to the current School.
   *
   * Sets form's `usesOneRoster` to `true` if School is OneRoster.
   */
  private setupSchoolData() {
    if (this.school) {
      this.educationalUnitId = this.school.schoolId;
      this.usesOneRoster = this.school.isOneRoster;
      if (this.isInEditMode) {
        this._currentSelectedSchoolId = this.school.schoolId;

        if (this.usesOneRoster) {
          this.alertBarText = Helpers.oneRosterToolTipMessage;
        }
      }
    }

    if (this.isManagingStudent && !this.school?.abbreviatedSchoolId) {
      this.toastr.error('You must set an abbreviated school id for this school prior to adding students.');
      this.form.disable();
    }
  }

  private resetEditFormWithNewUser(user: IUser, roleType: RoleType) {
    this.controlContainerForm.removeControl(this.usersAndRoleGroupName);
    this.user = user;
    this.roleType = roleType;
  }

  async confirmUnsavedChanges() {
    const modalRef = GenericConfirmModalComponent.open(this.modalService, {
      title: '',
      body: 'You have unsaved changes, please click confirm to continue.',
    });

    return modalRef.result;
  }

  /**
   * Shows a confirmation modal that user will be transferred when selecting a new school from the dropdown.
   *
   * @param {string} selectedSchoolId: School ID to transfer Student to
   * @param {number} userIndex: Index of Student being transferred in NewUsers array
   */
  onSchoolSelected(selectedSchoolId: any, userIndex: number) {
    if (this._currentSelectedSchoolId !== selectedSchoolId && selectedSchoolId !== this._originalSchoolId) {
      this._currentSelectedSchoolId = selectedSchoolId;

      const selectedSchool = this.schools.find(school => school.schoolId === selectedSchoolId);
      const confirmModal = GenericConfirmModalComponent.open(this.modalService, {
        title: `Do you want to transfer ${this.user.firstName} ${this.user.lastName}?`,
        body: `They will be removed from all classrooms at <span class="fw-700">${this.school.name}</span>. Please add them to a new class at <span class="fw-700">${selectedSchool.name}</span> to ensure they can use digital products.`,
        options: { size: 'md' },
        confirm: {
          label: 'Transfer',
        }
      });

      this.subscriptions.push(
        from(confirmModal.result)
          .pipe(
            catchError(() => of(false)),
            mergeMap((confirm: boolean) => {
              if (confirm) {
                this.newUsers.at(userIndex).get(this.formFieldNames.schoolId.name).setValue(selectedSchoolId);
                this._currentSelectedSchoolId = selectedSchoolId;
              } else {
                this.newUsers.at(userIndex).get(this.formFieldNames.schoolId.name).setValue(this._originalSchoolId);
                this.newUsers.at(userIndex).get(this.formFieldNames.schoolId.name).markAsPristine();
              }

              return of(null);
            })
          )
          .subscribe()
      );
    } else {
      this._currentSelectedSchoolId = this._originalSchoolId;
    }
  }

  /**
   * Observes changes in the Password field to update field validation.

  * @returns Observable<FormControlStatus> with current form control status for password field
   */
  handleNewPasswordFieldChanges$(userGroup: FormGroup): Observable<FormControlStatus> {
    return userGroup.controls.password.valueChanges.pipe(
      map((values: FormControlStatus) => {
        userGroup.get(this.formFieldNames.confirmPassword.name)?.updateValueAndValidity();
        return values;
      })
    );
  }

  hideTooltip(tooltip: NgbTooltip) {
    tooltip.close();
  }

  /**
   * Sets up the role type flags based on the roleType input.
   */
  private setupIsAddingRoleType(): void {
    this.isManagingDistrictAdmin = false;
    this.isManagingSchoolAdmin = false;
    this.isManagingTeacher = false;
    this.isManagingStudent = false;
    this.isManagingParent = false;

    if (this.roleType === RoleType.DistrictAdministrator) {
      this.isManagingDistrictAdmin = true;
    } else if (this.roleType === RoleType.SchoolAdministrator) {
      this.isManagingSchoolAdmin = true;
    } else if (this.roleType === RoleType.Teacher) {
      this.isManagingTeacher = true;
    } else if (this.roleType === RoleType.Parent) {
      this.isManagingParent = true;
    } else if (this.roleType === RoleType.Student) {
      this.isManagingStudent = true;
    }
  }

  /**
   * Sets up the form group for new users.
   * All validation for this group is handled within this form.
   * Validation can be referenced by its containing form for complete form validation.
   */
  private setupFormGroupForAllFormModes() {
    const controlContainerAsFormGroup = this.controlContainer.control as FormGroup;
    const currentFormForThisComponent = controlContainerAsFormGroup?.get(this.usersAndRoleGroupName) as FormGroup;
    // If this form isn't already on the Control Container, instantiate newUsers & roleType
    const userListValidators: ValidatorFn[] = [];

    this.assignFieldNames();

    if (!this.isInEditMode) {
      if (this.minRequired) {
        userListValidators.push(FormHelpers.getArrayMinValidator(this.minRequired));
      }
      if (this.maxRequired) {
        userListValidators.push(FormHelpers.getArrayMaxValidator(this.maxRequired));
      }
      if (!currentFormForThisComponent) {
        this.form = new FormGroup({
          newUsers: new FormArray([]),
          roleType: new FormControl(this.roleType),
        });
        const controlContainerAsFormGroup2 = this.controlContainer.control as FormGroup;
        controlContainerAsFormGroup2.addControl(this.usersAndRoleGroupName, this.form);
      } else {
        // Use the existing form group data if it already exists.
        this.form = currentFormForThisComponent;
      }
    } else {
      // e.g. Editing an existing user in the user management table
      this.form =  new FormGroup({
        newUsers: new FormArray([]),
        roleType: new FormControl(this.roleType),
      });

      (this.controlContainer.control as FormGroup).addControl(this.usersAndRoleGroupName, this.form);

      this.setupEditFormWithOriginalUser();
    }
  }

  /**
   * Adds standard name-related fields and validators to this form group based on roleType.
   */
  private setupBasicUserFieldsAndPushUser(): FormGroup {
    let newUser: FormGroup;
    this.assignFieldNames();

    if (!this.isInEditMode) {
      if (this.roleType === RoleType.Student) {
        // rset validators in case role type being added changed
        this.usernameValidators = [];
        this.usernameValidators.push(FormHelpers.getStudentUsernameValidator());
      } else {
        // reset validators in case role type being added changed
        this.usernameValidators = [];
        this.usernameValidators.push(Validators.email);
        this.formFieldNames = generalUserFields;
      }

      newUser = new FormGroup({
        [this.formFieldNames.userId.name]: new FormControl(''),
        [this.formFieldNames.firstName.name]: new FormControl('', this.firstNameValidators),
        [this.formFieldNames.lastName.name]: new FormControl('', this.lastNameValidators),
        [this.formFieldNames.username.name]: new FormControl('', this.usernameValidators)
      });

    } else {
      newUser = this.fb.group({
        firstName: ['', this.firstNameValidators],
        lastName: ['', this.lastNameValidators],
        username: ['', this.usernameValidators],
      }) as FormGroup;

      if (this.isManagingStudent || this.isManagingParent) {
        newUser.addControl(this.formFieldNames.externalId.name, new FormControl(null));
      }
    }

    this.newUsers.push(newUser);

    return newUser;
  }

  /**
   * Adds passwords fields and validators to this form group
   */
  private setupPasswordFields(newUsersIndex: number = 0) {
    const user = this.newUsers.at(newUsersIndex) as FormGroup;

    user.addControl(
      this.formFieldNames.password.name,
      this.fb.control('', FormHelpers.getPasswordValidator(true))
    );

    user.addControl(
      this.formFieldNames.confirmPassword.name,
      this.fb.control('', [FormHelpers.getConfirmPasswordValidator(true)])
    );

    // Will show password doesn't meet requirements while user typing
    this.subscriptions.push(
      this.handleNewPasswordFieldChanges$(user).subscribe(),
    );
  }

  /**
   * Adds fields and validators when adding a Parent roleType.
   */
  private setupParentFields(newUsersIndex: number = 0) {
    const user = this.newUsers.at(newUsersIndex) as FormGroup;

    if (user) {
      user.addControl(
        this.formFieldNames.student.name,
        this.fb.control('', [this.studentFormControlValidator(), Validators.required])
      );
    }
  }

  private assignFieldNames() {
    if (this.isManagingStudent) {
      // Updates First and Last Name fields to prepend "Student"
      this.formFieldNames = {
        ...generalUserFields,
        ...studentUserFields,
      };
    } else {
      this.formFieldNames = generalUserFields;
    }
  }

  /**
   * Adds a new form row to this form group and set up its fields & validators based on roleType
   * @returns This form group with new row added.
   */
  private addNewUserRow(newUsersIndex: number = 0): FormGroup {
    // initialize an empty user form group with basic user fields
    this.setupBasicUserFieldsAndPushUser();
    if (this.hasPasswordFields) {
      this.setupPasswordFields(newUsersIndex);

      if (this.canManageParent) {
        StudentRoleHelpers
          .setupNewStudentWithParentFields(newUsersIndex, this.newUsers, this.formFieldNames);
      }
    }

    if (this.roleType === RoleType.Parent) {
      this.setupParentFields(newUsersIndex);
    }
    if (this.includeUserId) {
      const newlyAddedUser =  this.newUsers.at(newUsersIndex) as FormGroup;
      newlyAddedUser.addControl(this.formFieldNames.externalId.name, this.fb.control(null, []));
      newlyAddedUser.addControl(this.formFieldNames.userId.name, this.fb.control('', []));
    } else if (this.roleType === RoleType.Student) {
      const newlyAddedUser =  this.newUsers.at(newUsersIndex) as FormGroup;
      newlyAddedUser.addControl(this.formFieldNames.externalId.name, this.fb.control(null, []));
    }

    return this.newUsers.at(newUsersIndex) as FormGroup;
  }

  /**
   * Checks if a form group (row) within the given form array has empty string values.
   *
   * @param {number} index
   * @param {FormArray} form
   */
  formRowHasEmptyValues(index: number, form: FormArray): boolean {
    let isEmpty = true;
    const values = { ...form.at(index).value };
    Object.keys(values).forEach((key: string) => {
      if (
        Object.prototype.hasOwnProperty.call(values, key)
        && !!values[key] && values[key].length > 0
      ) {
        isEmpty = false;
      }
    });
    return isEmpty;
  }

  /**
   * Used when Editing a User
   *
   * Initializes the form based on role using User data.
   */
  private setupEditFormWithOriginalUser(): void {
    // this.originalUserAssociations = this.user.userAssociations;
    const newUsersArray = this.form.get(primaryFormControlNames.newUsers) as FormArray;
    const userFormGroup: FormGroup = new FormGroup({});

    this.usernameValidators = [];
    if (this.isManagingStudent) {
      // Student's username is not an email address, unlike other role types.
      this.usernameValidators.push(FormHelpers.getStudentUsernameValidator());
    } else {
      this.usernameValidators.push(Validators.email);
    }

    userFormGroup
      .addControl(this.formFieldNames.firstName.name, new FormControl(this.user.firstName, this.firstNameValidators));
    userFormGroup
      .addControl(this.formFieldNames.lastName.name, new FormControl(this.user.lastName, this.lastNameValidators));
    userFormGroup
      .addControl(this.formFieldNames.username.name, new FormControl(this.user.username, this.usernameValidators));
    userFormGroup
      .addControl(this.formFieldNames.userId.name, new FormControl(this.user.userId, this.userIdValidators));

    if (this.isManagingStudent) {
      userFormGroup.get(this.formFieldNames.username.name).setValue(this.user.studentUserName);
      userFormGroup.addControl(this.formFieldNames.externalId.name, new FormControl(this.user.externalId));
    } else {
      userFormGroup.get(this.formFieldNames.username.name).setValue(this.user.username);
    }

    if (this.isManagingStudent || this.isManagingTeacher) {
      // Only add password fields when user is not also an Admin
      const notAdmin = !this.user.isSchoolAdmin && !this.user.isDistrictAdmin && !this.user.isElevatedAdmin;
      if (notAdmin) {
        // Add password control
        const passwordControl = new FormControl('', FormHelpers.getPasswordValidator(true));
        userFormGroup
          .addControl(this.formFieldNames.password.name, passwordControl);

        // Add confirm password control
        const confirmPasswordControl = new FormControl('', FormHelpers.getConfirmPasswordValidator(true));
        userFormGroup
          .addControl(this.formFieldNames.confirmPassword.name, confirmPasswordControl);

        // If editing a Student, but current authed user cannot change Student Password, disable the fields.
        if (this.isManagingStudent && !this.currentApplicationUser.profileDetail?.canChangeStudentPassword) {
          passwordControl.disable();
          confirmPasswordControl.disable();
        } else {
          // Subscribe for password field errors
          this.subscriptions.push(
            this.handleNewPasswordFieldChanges$(userFormGroup).subscribe(),
          );
        }
      }

      if (this.isManagingStudent) {
        userFormGroup.addControl(this.formFieldNames.userAssociationsToAdd.name, new FormArray([]));
        userFormGroup.addControl(this.formFieldNames.userAssociationsToRemove.name, new FormArray([]));
        userFormGroup.addControl(this.formFieldNames.userAssociations.name, new FormArray([]));
        userFormGroup.get(this.formFieldNames.username.name).setValue(this.user.studentUserName);

        const allSchools = this.currentApplicationUser.schools;
        this.schools = allSchools?.sort((a: School, b: School) => a.name?.localeCompare(b.name));

        if (this.school) {
          this._originalSchoolId = this.school.schoolId;
          // Add school dropdown
          const schoolIdControl = new FormControl(this._originalSchoolId, [Validators.required]);
          userFormGroup.addControl(this.formFieldNames.schoolId.name, schoolIdControl);

          if (this.usesOneRoster) {
            schoolIdControl.disable();
          }
        }
      }


    } else if (this.isManagingParent) {
      userFormGroup.addControl(this.formFieldNames.userAssociationsToAdd.name, new FormArray([]));
      userFormGroup.addControl(this.formFieldNames.userAssociationsToRemove.name, new FormArray([]));
      userFormGroup.addControl(this.formFieldNames.userAssociations.name, new FormArray([]));
      this.removeExtraControls(userFormGroup);
      this.userCanBeEditedIfOneRoster = true;

      if (this.usesOneRoster) {
        RoleGroup.digitalAdmins.forEach((role: RoleType) => {
          // Parent cannot be edited for OneRoster when also an Admin or Teacher
          if (this.user.roles?.includes(role)) {
            this.userCanBeEditedIfOneRoster = false;
            this.alertBarText = Helpers.oneRosterToolTipMessage;
          } else {
            this.alertBarText = null;
          }
        });

        if (this.user.roles?.includes(RoleType.Teacher)) {
          this.userCanBeEditedIfOneRoster = false;
        } else {
          this.alertBarText = null;
        }
      }

    } else {
      // Remove controls that do not apply for the remaining Role Types in case they were mistakenly added.
      this.removeExtraControls(userFormGroup);
    }

    // Disable non-password fields if OneRoster or CanOnlyChangePassword is true
    // Users that also have Parent or Admin roles have higher role's OneRoster logic take presedence
    if (this.usesOneRoster && (!this.userCanBeEditedIfOneRoster)) {
      userFormGroup.get(this.formFieldNames.firstName.name).disable();
      userFormGroup.get(this.formFieldNames.lastName.name).disable();
      userFormGroup.get(this.formFieldNames.username.name).disable();
      userFormGroup.get(this.formFieldNames.externalId.name)?.disable();
      userFormGroup.get(this.formFieldNames.schoolId.name)?.disable();
    }

    this.setUIFlagsForEditMode();

    // Add user to newUsersArray
    newUsersArray.push(userFormGroup);

    // Add existing UserAssociations to user by its index of the newUsersArray
    if (this.isManagingStudent || this.isManagingParent) {
      this.addAssociationsToForm();
    }
  }

  private setUIFlagsForEditMode() {
    if (this.isInEditMode) {
      this.showClassroomList = (this.isManagingStudent || this.isManagingParent) && this.user.classrooms?.length > 0;
      this.showStudentsList = this.isManagingParent && this.user.userAssociations?.length > 0;
      this.showSchoolDropdown = this.isManagingStudent;
    }
  }

  private addAssociationsToForm() {
    if (this.isInEditMode) {
      if (this.isManagingStudent) {
        // if students, add list of parent form field rows to the form
        this.user.userAssociations?.forEach((parent: IUser) => {
          const indexOfNewUser = this.newUsers.length - 1;
          StudentRoleHelpers
            .addParentRowToEditStudentUserForm(parent, indexOfNewUser, this.newUsers, this.formFieldNames);
        });
      } else if (this.isManagingParent) {
        this.user.userAssociations?.forEach((student: IUser) => {
          const indexOfNewUser = this.newUsers.length - 1;
          ParentRoleHelpers
            .addStudentRowToParentForm(student, indexOfNewUser, this.newUsers, this.formFieldNames);
        });
      }
    }
  }

  private removeExtraControls(userFormGroup: FormGroup) {
    userFormGroup.removeControl(this.formFieldNames.password.name);
    userFormGroup.removeControl(this.formFieldNames.currentPassword.name);
    userFormGroup.removeControl(this.formFieldNames.confirmPassword.name);
    userFormGroup.removeControl(this.formFieldNames.externalId.name);
    userFormGroup.removeControl(this.formFieldNames.schoolId.name);
  }

  /**
   * Checks if a form control is invalid and touched.
   *
   * @param {number} index - The index of the form control.
   * @param {string} name - The name of the form control.
   * @returns {boolean} True if the form control is invalid and touched, false otherwise.
   */
  formControlInvalidAndTouched(index: number, name: string): boolean {
    return FormHelpers.formControlInvalidAndTouched(this.newUsers, index, name);
  }

  /**
     * Used for validating a specific parent's field for a specific user.
     * Uses the userAssociations array as the parents collection.
     *
     * @param {number} parentIndex - The index parent within the userAssociations array
     * @param {number} userIndex  - The index user within the newUsers array
     * @param {string} name The name of the form control.
      * @returns {boolean} True if the form control is invalid and touched, false otherwise.
    */
  parentFormControlInvalidAndTouched(parentIndex: number, userIndex: number, name: string): boolean {
    if (!this.isInEditMode) {
      return this.formControlInvalid(userIndex, name);
    }
    // Edit Mode uses UserAssociations array which is empty until the new student is submitted
    return FormHelpers.parentFormControlInvalid(this.newUsers, userIndex, parentIndex, name);
  }

  /**
     * Checks if a form control is invalid for a specific user within the newUsers form array.
     * @param {number} index - The index of the newUser form array.
     * @param {string} name - The name of the form control for the specific user in the newUser form array.
     * @returns {boolean} True if the form control is invalid, false otherwise.
     */
  formControlInvalid(index: number, name: string): boolean {
    return FormHelpers.formControlInvalid(this.newUsers, index, name);
  }

  /**
     * Checks if the confirm password field is invalid.
     * Confirm Password must validate when not touched if user adds a Password
     * @param {number} index - The index of the newUser form array.
     * @returns {any} The validation errors or null.
     */
  confirmPasswordInvalid(index: number): any {
    return FormHelpers.confirmPasswordInvalid(this.newUsers, index, this.formFieldNames.confirmPassword.name);
  }

  /**
     * Checks if the student form control is invalid.
     * @param {FormControl} control - The form control.
     * @returns {boolean} True if the form control is invalid, false otherwise.
     */
  studentFormControlInvalid(control: FormControl): boolean {
    return FormHelpers.studentFormControlInvalid(control);
  }

  /**
     * Validator function for the student form control.
     * @returns {ValidatorFn} The validator function.
     */
  studentFormControlValidator(): ValidatorFn {
    return FormHelpers.studentSearchControlValidator();
  }

  /**
     * Provides real-time feedback for the Confirm Password field
     * Allows the user knows when Confirm Password matches the New Password field
     * Show error when user updates New Password field and it doesn't match existing Confirm Password value
     * Do not show error when the Confirm Password field doesn't have a value
     * @returns {boolean} if field should be marked as valid
     */
  isConfirmPasswordValid(): boolean {
    const confirmPasswordControl = this.form.controls[this.formFieldNames.confirmPassword.name];
    const passwordControl = this.form.controls[this.formFieldNames.password.name];

    // Start validation as soon as the field is not pristine
    if (!confirmPasswordControl?.pristine) {
      if (!confirmPasswordControl?.value && !passwordControl?.value) {
        return true;
      }
      confirmPasswordControl.updateValueAndValidity();
      return confirmPasswordControl.valid;
    }

    // return true while there is no value & field has not been interacted with yet
    return true;
  }

  /**
     * Gets the error text for the confirm password field.
     * @param {number} index - The index of the newUser form array.
     * @returns {string} The error text.
     */
  confirmPasswordFieldErrorText(index: number): string {
    return FormHelpers.confirmPasswordFieldErrorText(this.newUsers, this.formFieldNames, index);
  }

  /**
     * Gets the error text for the name field.
     * @param {number} index - The index of the newUser form array.
     * @param {string} name - The name of the form control for the specific user in the newUser form array.
     * @returns {string} The error text.
     */
  nameFieldErrorText(index: number, name: string): string {
    return FormHelpers.nameFieldErrorText(this.newUsers, this.formFieldNames, index, name);
  }

  /**
     * Gets the error text for the name field.
     * @param {number} index - The index of the newUser form array.
     * @param {string} name - The name of the form control for the specific user in the newUser form array.
     * @returns {string} The error text.
     */
  parentNameFieldErrorText(parentIndex: number, studentIndex: number, name: string): string {
    return FormHelpers.parentNameFieldErrorText(this.newUsers, this.formFieldNames, parentIndex, studentIndex, name);
  }
}
