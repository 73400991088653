export const copyIcon = `
<svg xmlns="http://www.w3.org/2000/svg" width="19" height="20" viewBox="0 0 19 20"
     fill="none">
    <path fill-rule="evenodd"
          clip-rule="evenodd"
          d="M6.625 2H16.625V14H6.625V2ZM4.625 16V0H18.625V16H4.625ZM2.625 3V18H15.625V20H0.625V3H2.625Z"
          fill="var(--zbp-svg-primary-color, black)"
    />
</svg>
`;
