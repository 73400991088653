import { FormArray, FormGroup, AbstractControl, FormControl } from '@angular/forms';
import { FormFieldModel } from '@models/form-field';
import { IUser } from '@models/user';

import { LoginStatus } from '@shared/enums/login-status';
import { generalUserFields } from '@shared/manage-users/manage-users-form/constants/form-field-setup-data';

/**
 * Helper Functions used when managing a Parent user.
 */
export namespace ParentRoleHelpers {
  /**
   * Checks if a Parent has logged in.
   *
   * @param {IUser} parentUser Parent to evaluate.
   * @returns  {boolean} If Parent has logged in.
   */
  export function parentIsSetup(parentUser: IUser): boolean {
    return parentUser ? parentUser.loginStatus !== LoginStatus.NotSetup : false;
  }

  /**
   * Gets UserAssociations from Student.
   *
   * Will update Add Parent button text based on if Parents already exist.
   *
   * @param {AbstractControl} studentFormGroup Form Group for Student.
   * @param {Function} setParentButtonText If provided, will add 'another' in the button text if Parents already exist.
   * @returns
   */
  export function getExistingParents(studentFormGroup: AbstractControl, setParentButtonText?: Function): any {
    // Edit mode for Student displays list of current parents from the userAssociations
    const userFormGroup = studentFormGroup as FormGroup;
    const parents = userFormGroup.controls.userAssociations as FormArray;
    if (setParentButtonText) {
      if (parents?.length > 0) {
        setParentButtonText('Add Another Parent');
      } else {
        setParentButtonText('Add Parent');
      }
    }
    return  userFormGroup.controls.userAssociations;
  }

  /**
   * Gets the UserAssociations from a Parent form group.
   *
   * @param parentFormGroup Form group that contains the Parent.
   * @returns {AbstractControl} Array of user associations for Parent.
   */
  export function getExistingStudents(parentFormGroup: AbstractControl): any {
    // Edit mode for Student displays list of current parents from the userAssociations
    const userFormGroup = parentFormGroup as FormGroup;
    return  userFormGroup.controls.userAssociations;
  }

  /**
   * Adds a row of student fields to a parent.
   *
   * If adding an existing student, form fields will use Student data.
   *
   * If adding a new still, form fields will be empty.
   *
  * @param {IUser} student Student to add parent to
   * @param {number} indexOfParentToAdd Index of parent in the student's userAssociations list to remove
   * @param {FormArray} newUsersFormArray Array of New Users on form
   * @param { [key: string]: FormFieldModel } formFieldNames Collection of form field names used on form
   */
  export function addStudentRowToParentForm(
    student: IUser,
    indexOfParentToAdd: number,
    newUsersFormArray: FormArray,
    formFieldNames:{ [key: string]: FormFieldModel }) {
    const studentGroup = new FormGroup({
      associatedUserId: new FormControl(student?.userId || null, []),
      associatedUsername: new FormControl(student?.username || null, []),
      displayName: new FormControl(student?.getDisplayName || null, []),
    });

    // Add new group to userAssociations in form for Student
    const associationsForUserFormArray = newUsersFormArray
      .at(indexOfParentToAdd).get(formFieldNames.userAssociations.name) as FormArray;

    // Push form group into userAssociations FormArray
    associationsForUserFormArray.push(studentGroup);
  }

  /**
 * Removes a Student association from the Parent's UserAssociations list on the form.
 *
 * Updates the userAssociation list for the parent.
 *
 *
 * @param parentIndex Index of Parent from array of new users.
 * @param studentIndex Index of Student in the Parents's userAssociations list to remove.
 */
  export function removeStudentAssociationFromAssociations(
    parentIndex: number,
    studentIndex: number,
    newUsersFormArray: FormArray,
    formFieldNames:{ [key: string]: FormFieldModel }
  ): void {
    const parent = newUsersFormArray.at(parentIndex) as FormGroup;
    const currentFormUserAssociations = parent.get(formFieldNames.userAssociations.name) as FormArray;
    currentFormUserAssociations.removeAt(studentIndex);
  }

  /**
   * Additional users are added to the newUsers form that are for submission only and not display.
   *
   * e.g. When editing a Student and adding a new Parent to the Student,
   * the Parent is sent to the API as a separate user the array of users that will be updated.
   *
   */
  export function createHiddenParentUser(newParent: AbstractControl): FormGroup {
    const parentToAddToNewUsers = new FormGroup({
      firstName: new FormControl(newParent.get(generalUserFields.parentFirstName.name)?.value),
      lastName: new FormControl(newParent.get(generalUserFields.parentLastName.name)?.value),
      userId: new FormControl(newParent.get(generalUserFields.parentUserId.name)?.value),
      username: new FormControl(newParent.get(generalUserFields.parentUserName.name)?.value),
    });

    return parentToAddToNewUsers;
  }

  /**
 * Removes a parent association from the student's UserAssociations list.
 *
 * Updates the userAssociationsToRemove list for the student.
 *
 * Form fields will be removed.
 *
 * @param userIndex Index of user from array of new users. Will always be 0 when editing a single user.
 * @param parentIndex Index of parent in the student's userAssociations list to remove.
 */
  export function addAssociationToRemove(
    originalUser: IUser,
    parentFormGroup: FormGroup,
    studentId: string
  ): FormGroup {
    // Remove existing association from UserAssociations
    const existingParentAssociation = originalUser.userAssociations?.find(user => user.userId === studentId);

    if (existingParentAssociation) {
      // Add entry to UserAssociationsToRemove
      const newAssociationToRemove = new FormGroup({
        associatedUserId: new FormControl(existingParentAssociation?.userId),
        associatedUserName: new FormControl(existingParentAssociation?.username)
      });

      const associationsToRemoveFormArray = parentFormGroup
        .get(generalUserFields.userAssociationsToRemove.name) as FormArray;
      associationsToRemoveFormArray.push(newAssociationToRemove);

      return newAssociationToRemove;
    }

    return null;
  }
}
