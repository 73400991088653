export const adminTeacherRoleIcon = `
<svg xmlns="http://www.w3.org/2000/svg" width="84" height="71" viewBox="0 0 84 71"
  fill="none">
  <path
    d="M2.02539 58.0211V5.74187C2.02539 3.66873 3.70193 1.99219 5.77507 1.99219H78.317C80.3902 1.99219 82.0667 3.66873 82.0667 5.74187V64.6371C82.0667 66.7103 80.3902 68.3868 78.317 68.3868H56.9727"
    stroke="var(--zbp-svg-primary-color, black)"
    stroke-width="3.60546"
    stroke-linecap="round"
    stroke-linejoin="round"
  />
  <path
    d="M31.4609 17.8945C37.5541 17.8945 42.5117 22.852 42.5117 28.9453C42.5117 35.0385 37.5541 39.996 31.4609 39.996C25.3677 39.996 20.4102 35.0385 20.4102 28.9453C20.4102 22.852 25.3677 17.8945 31.4609 17.8945ZM31.4609 16.0918C24.3581 16.0918 18.6074 21.8425 18.6074 28.9453C18.6074 36.048 24.3581 41.7988 31.4609 41.7988C38.5637 41.7988 44.3144 36.048 44.3144 28.9453C44.3144 21.8425 38.5637 16.0918 31.4609 16.0918Z"
    fill="var(--zbp-svg-primary-color, black)"
    stroke="none"
  />
  <path
    d="M36.3852 44.6079H23.3515C18.9709 44.6079 15.4375 48.1593 15.4375 52.5219V67.0158H44.3173V55.8028H62.0562C62.0562 55.8028 68.6542 55.8028 68.6542 44.5898H36.3852V44.6079Z"
    stroke="var(--zbp-svg-primary-color, black)"
    stroke-width="1.80273"
    stroke-linecap="round"
    stroke-linejoin="round"
  />
  <path
    d="M66.7577 36.7812C67.7852 36.7812 68.6325 37.6285 68.6325 38.6561V44.6051H64.8828V38.6561C64.8828 37.6285 65.7301 36.7812 66.7577 36.7812Z"
    stroke="var(--zbp-svg-primary-color, black)"
    stroke-width="1.80273"
    stroke-miterlimit="10"
  />
  <path d="M50.3438 12.8975H72.1748"
        stroke="var(--zbp-svg-primary-color, black)"
        stroke-width="1.80273"
        stroke-linecap="round"
        stroke-linejoin="round"
  />
  <path d="M50.3438 18.8105H72.1748"
        stroke="var(--zbp-svg-primary-color, black)"
        stroke-width="1.80273"
        stroke-linecap="round"
        stroke-linejoin="round"
  />
  <path d="M50.3438 24.8525H72.1748"
        stroke="var(--zbp-svg-primary-color, black)"
        stroke-width="1.80273"
        stroke-linecap="round"
        stroke-linejoin="round"
  />
</svg>
`;
