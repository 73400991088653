import { NgFor, NgIf } from '@angular/common';
import { Component } from '@angular/core';
import { passwordRequirements } from '@shared/password-requirement-list';

@Component({
  selector: 'zbp-password-requirements-tooltip',
  templateUrl: './password-requirements-tooltip.component.html',
  styleUrls: ['./password-requirements-tooltip.component.scss'],
  standalone: true,
  imports: [
    NgFor,
    NgIf,
  ],
})
export class PasswordRequirementsTooltipComponent {
  passwordRequirements = passwordRequirements;

  constructor() {}
}
