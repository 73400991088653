export const eyeIcon = `
<svg xmlns="http://www.w3.org/2000/svg" width="38" height="25" viewBox="0 0 38 25"
     fill="none">
  <path fill-rule="evenodd"
        clip-rule="evenodd"
        d="M19.0173 20.9473C13.6258 20.9473 8.16934 18.1507 4.22821 12.3338C7.91309 6.75102 13.3456 3.86393 19.0173 3.86393C24.9691 3.86393 30.1129 6.78006 33.8353 12.503C30.3162 17.9457 24.993 20.9473 19.0173 20.9473ZM19.0173 0.447266C26.9644 0.447266 33.5415 4.79156 37.8089 12.4859C33.8165 19.9393 26.9644 24.3639 19.0173 24.3639C11.7346 24.3639 4.72704 20.2178 0.225586 12.3663C4.41442 4.71297 11.422 0.447266 19.0173 0.447266ZM22.4339 12.4056C22.4339 13.3118 22.0739 14.1808 21.4332 14.8215C20.7924 15.4623 19.9234 15.8223 19.0173 15.8223C18.1111 15.8223 17.2421 15.4623 16.6013 14.8215C15.9606 14.1808 15.6006 13.3118 15.6006 12.4056C15.6006 11.4994 15.9606 10.6304 16.6013 9.98965C17.2421 9.3489 18.1111 8.98893 19.0173 8.98893C19.9234 8.98893 20.7924 9.3489 21.4332 9.98965C22.0739 10.6304 22.4339 11.4994 22.4339 12.4056ZM25.8506 12.4056C25.8506 14.2179 25.1306 15.956 23.8491 17.2375C22.5676 18.519 20.8296 19.2389 19.0173 19.2389C17.2049 19.2389 15.4669 18.519 14.1854 17.2375C12.9039 15.956 12.1839 14.2179 12.1839 12.4056C12.1839 10.5933 12.9039 8.8552 14.1854 7.5737C15.4669 6.2922 17.2049 5.57227 19.0173 5.57227C20.8296 5.57227 22.5676 6.2922 23.8491 7.5737C25.1306 8.8552 25.8506 10.5933 25.8506 12.4056Z"
        fill="var(--zbp-svg-primary-color, black)"
  />
</svg>
`;
