
import { LoginStatus } from '@shared/enums/login-status';
import { RoleGroup, RoleType } from '@shared/enums/role-type';
import { ClassCast } from '@shared/zb-object-helper/class-cast';
import { copyObject, instantiateFromJsonReverseParam } from '@shared/zb-object-helper/object-helper';
import * as moment from 'moment';

import { Classroom, IUserClassroom } from './classroom';
import { District } from './district';
import { IEducationalUnitMembership } from './educational-unit-membership';
import { School } from './school';
import { StudentQRCode } from './student-qr-code';
import { isIUserAssociationPartial, IUser, IUserAssociationPartial } from './user';
import { IUserProfileDetail } from './user/profile-detail';
import { IUserProductUsage } from './user/user-product';
import { UserLti } from './user-lti';

export class UserProfile {
  profileId?: string = undefined;
  profileDetail: IUserProfileDetail = undefined;
}

export class UserSetting {
  settingId: string = undefined;
  name: string = undefined;
  value: string | null = undefined;
}

export class ApplicationUser implements IUser, Omit<object, 'isRole'> {
  userId: string = undefined;
  firstName: string = undefined;
  lastName: string = undefined;
  studentUserName: string = undefined;
  _lastLogin?: moment.Moment = undefined;
  loginStatus: LoginStatus = undefined;
  assignedLicenseCount: number = 0;
  isActive: boolean = undefined;
  isLockedOut: boolean = undefined;
  canMasquerade: boolean = false;
  externalId: string = undefined;
  _createdOn: moment.Moment = undefined;
  _deletedOn: moment.Moment = undefined;
  locale: string = null;
  // @todo rename this to 'roleTypes' to reflect ZBPortal-Api nomenclature once legacy is refactored out
  _roles: RoleType[] = [];
  @ClassCast(IUserProfileDetail)
    profileDetail: IUserProfileDetail = copyObject({}, IUserProfileDetail);
  @ClassCast(UserSetting)
    settings: UserSetting[] = [];
  private _classrooms: Classroom[] = [];
  @ClassCast(School)
    schools: School[] = [];
  @ClassCast(District)
    districts: District[] = [];
  // eslint-disable-next-line no-use-before-define
  private _userAssociations: ApplicationUser[] = [];
  @ClassCast(IUserProductUsage)
    productUsages: IUserProductUsage[] = [];
  @ClassCast(UserLti)
    lti: UserLti = null;
  @ClassCast(StudentQRCode)
    studentQRCode: StudentQRCode = undefined;
  ssoLoginUrl: string = undefined;
  viewingAsRole: RoleType | null = undefined;

  private _userName: string = '';
  private _roletypes: RoleType[] = [];

  set roleTypes(value) {

    this.roles = value;
  }

  set roles(value) {
    this._roles = value;
  }

  get roles() {
    return this._roles ? this._roles : [];
  }

  set licenses(value) {
    this.productUsages = value;
  }

  get licenses() {
    return this.productUsages;
  }

  set classrooms(value: any[]) {
    this._classrooms = (value).map((v) => {
      if (Classroom.isIUserClassroom(v)) {
        return Classroom.fromUserStatus(v);
      }
      return copyObject(v, Classroom);
    });
  }

  get classrooms() {
    return this._classrooms;
  }

  set userAssociations(userAssociations: any[]) {
    this._userAssociations = userAssociations.map((userAssociation) => {


      if (isIUserAssociationPartial(userAssociation)) {
        return ApplicationUser.fromAssociation(userAssociation);
      }
      return copyObject(userAssociation, ApplicationUser);
    });
  }

  get userAssociations() {
    return this._userAssociations;
  }

  onInit() {
    //As an example. You can implement code here that will occur after the constructor and setters have been called.
    if (this.profileDetail && !this.viewingAsRole && this.roles.length < 2) {
      //eslint-disable-next-line
      this.viewingAsRole = this.roles[0];
    }
  }

  static fromSearch(values: { [key: string]: any }): ApplicationUser {
    let classrooms = [];
    let schools = [];
    let districts = [];

    if (values.memberships) {
      classrooms = values.memberships
        .filter(m => !!m.classroomId)
        .map((m: IEducationalUnitMembership) => ({
          educationalUnitId: m.educationalUnitId,
          classroomId: m.classroomId,
          name: m.classroomName,
          schoolAssignedId: null,
          roleType: m.roleType,
        } as IUserClassroom));
      schools = values.memberships
        .filter(m => m.educationalUnitType === 'School' && !m.classroomId)
        .map((m: IEducationalUnitMembership) => instantiateFromJsonReverseParam(School, {
          educationalUnitId: m.educationalUnitId,
          abbreviatedSchoolId: m.abbreviatedSchoolId ? m.abbreviatedSchoolId : null,
          roleType: m.roleType,
          name: m.educationalUnitName,
        }));
      districts = values.memberships
        .filter(m => m.educationalUnitType === 'District' && !m.classroomId)
        .map((m: IEducationalUnitMembership) => instantiateFromJsonReverseParam(District, {
          educationalUnitId: m.educationalUnitId,
          name: m.educationalUnitName,
          abbreviatedSchoolId: null,
          roleType: m.roleType,
        }));
    }

    return copyObject({
      ...values,
      classrooms,
      schools,
      districts,
    }, ApplicationUser);
  }

  // Special snowflake static factory because property names are different from standard user responses.
  static fromAssociation(values: IUserAssociationPartial): ApplicationUser {
    let classrooms = [];
    let schools = [];
    let districts = [];
    let roleTypes = [];
    let studentUserName = null;

    if (values.educationalMemberships) {
      classrooms = values.educationalMemberships
        .filter(m => !!m.classroomId)
        .map((m: IEducationalUnitMembership) => ({
          educationalUnitId: m.educationalUnitId,
          classroomId: m.classroomId,
          name: m.classroomName,
          schoolAssignedId: null,
          roleType: m.roleType,
        } as IUserClassroom));
      schools = values.educationalMemberships
        .filter(m => m.educationalUnitType === 'School' && !m.classroomId)
        .map((m: IEducationalUnitMembership) => instantiateFromJsonReverseParam(School, {
          educationalUnitId: m.educationalUnitId,
          abbreviatedSchoolId: m.abbreviatedSchoolId ? m.abbreviatedSchoolId : null,
          roleType: m.roleType,
          name: m.educationalUnitName,
        }));
      districts = values.educationalMemberships
        .filter(m => m.educationalUnitType === 'District' && !m.classroomId)
        .map((m: IEducationalUnitMembership) => instantiateFromJsonReverseParam(District, {
          educationalUnitId: m.educationalUnitId,
          name: m.educationalUnitName,
          abbreviatedSchoolId: null,
          roleType: m.roleType,
        }));
      roleTypes = values.educationalMemberships
        .reduce((ret, value) => (!ret.includes(value.roleType) ? ret.concat(value.roleType) : ret), []);
    }

    // Figure out the studentUserName property because it's not provided.
    if (schools.length > 0) {
      const school = schools.find(s => s.roleType === RoleType.Student);
      if (school) {
        studentUserName = values.userName.substr(0, values.userName.length - school.abbreviatedSchoolId.length - 1);
      }
    }

    return copyObject({
      ...values,
      username: values.userName,
      studentUserName,
      classrooms,
      schools,
      districts,
      roleTypes,
    }, ApplicationUser);
  }

  static from(role: string, firstName: string, lastName: string, username: string): ApplicationUser {
    return copyObject({
      firstName,
      lastName,
      username,
      createdOn: moment().toISOString(),
      roles: [RoleType[role]],
    }, ApplicationUser);
  }

  isViewingAsRole(role: RoleType): boolean {
    if (this.viewingAsRole) {
      return this.viewingAsRole === role;
    }

    return this.roles.includes(role);
  }

  isViewingAsRoleGroup(roleGroup: RoleType[]): boolean {
    if (this.viewingAsRole) {
      return roleGroup.includes(this.viewingAsRole);
    }
    return roleGroup.some(role => this.roles.includes(role));
  }

  isInGroup(group: Classroom | School | District): boolean {
    if (!group) {
      return false;
    }
    if (Object.prototype.hasOwnProperty.call(group, 'classroomId')) {
      const classroom = group as Classroom;
      return !!this.classrooms.find(c => c.classroomId === classroom.classroomId);
    }
    if (Object.prototype.hasOwnProperty.call(group, 'schoolId')) {
      const school = group as School;
      return !!this.schools.find(s => s.schoolId === school.schoolId);
    }
    if (Object.prototype.hasOwnProperty.call(group, 'districtId')) {
      const district = group as District;
      return !!this.districts.find(d => d.districtId === district.districtId);
    }
    throw new Error('Invalid group');
  }

  set createdOn(value: string) {
    this._createdOn = value ? moment(value) : null;
  }


  set deletedOn(value: string) {
    this._deletedOn = value ? moment(value) : null;
  }

  set lastLogin(lastLogin: string | moment.Moment) {
    if (lastLogin && !moment.isMoment(lastLogin)) {
      this._lastLogin = moment(lastLogin);
    }
  }

  get lastLogin(): moment.Moment {
    return this._lastLogin;
  }

  get fullName(): string {

    if (this.firstName && this.lastName) {
      return `${this.firstName.trim()} ${this.lastName.trim()}`;
    }
    if (this.firstName) {
      return this.firstName.trim();
    }
    return this.userName;
  }

  get username(): string {
    return this._userName;
  }

  set username(value: string) {
    if (this.isStudent && this.abbreviatedSchoolId && !value.endsWith(`_${this.abbreviatedSchoolId}`)) {
      this._userName = value?.length > 0 ? `${value}_${this.abbreviatedSchoolId}` : value;
    } else {
      this._userName = value;
    }
  }

  get studentUsernameWithoutSchoolId(): string {
    const matches = this._userName.match(/([a-z0-9@.-]*)_([a-z0-9]*)$/);
    if (matches !== null && matches.length > 1) {
      return matches[1];
    }

    return this._userName;
  }

  get userName(): string {
    return this._userName;
  }

  set userName(value: string) {
    if (this.isStudent && this.abbreviatedSchoolId && !value.endsWith(`_${this.abbreviatedSchoolId}`)) {
      this._userName = value?.length > 0 ? `${value}_${this.abbreviatedSchoolId}` : value;
    } else {
      this._userName = value;
    }
  }

  get abbreviatedSchoolId(): string {
    // Returns the abbreviatedSchoolId in the first found school for a student or teacher (unless admin).
    if (this.schools.length === 1) {
      return this.schools[0].abbreviatedSchoolId;
    }
    if (this.isStudent) {
      const matches = this._userName.match(/_([a-z0-9]{4,50})$/);
      if (matches !== null && matches.length > 1) {
        return matches[1];
      }
    }

    return null;
  }

  get canChooseRole(): boolean {
    return this.roles.length > 1;
  }

  get isStudent(): boolean {
    if (this.viewingAsRole) {
      return this.viewingAsRole === RoleType.Student;
    }

    return this.roles.includes(RoleType.Student);
  }

  get isTeacher(): boolean {
    if (this.viewingAsRole) {
      return this.viewingAsRole === RoleType.Teacher;
    }

    return this.roles.includes(RoleType.Teacher);
  }

  get isParent(): boolean {
    if (this.viewingAsRole) {
      return this.viewingAsRole === RoleType.Parent;
    }

    return this.roles.includes(RoleType.Parent);
  }

  get isSchoolAdmin(): boolean {
    if (this.viewingAsRole) {
      return this.viewingAsRole === RoleType.SchoolAdministrator;
    }

    return this.roles.includes(RoleType.SchoolAdministrator);
  }

  get isDistrictAdmin(): boolean {
    if (this.viewingAsRole) {
      return this.viewingAsRole === RoleType.DistrictAdministrator;
    }

    return this.roles.includes(RoleType.DistrictAdministrator);
  }

  get isInternal(): boolean {
    return this.isViewingAsRoleGroup(RoleGroup.routeInternalRoles);
  }

  get isElevatedAdmin(): boolean {
    return this.isViewingAsRoleGroup(RoleGroup.elevatedAdminRoles);
  }

  // @deprecated
  get isAuthenticated(): boolean {
    return this.roles && this.roles.length > 0;
  }

  // @deprecated
  get getSystemName(): string {
    return this.systemName;
  }

  get systemName(): string {
    return this._userName;
  }

  // @deprecated
  get getDisplayName(): string {
    return this.displayName;
  }

  get displayName(): string {
    return this.isStudent ? this.fullName : this._userName;
  }

  get isDeleted(): boolean {
    return !!this.deletedOn;
  }

  get isNew(): boolean {
    return !this.userId;
  }

  /**
   * Formats the last access timestamp to a date or "never logged in" string.
   */
  get lastActivityTime(): string {
    if (this.lastLogin) {
      return this.lastLogin.format('M/D/YYYY @ h:mma').toString();
    }

    return this.loginStatus === LoginStatus.NeverLoggedIn ? 'never logged in' : 'not setup';
  }
}
