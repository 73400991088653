export enum RoleType {
  ApplicationAdministrator = 'ApplicationAdministrator',
  CustomerServiceAdministrator = 'CustomerServiceAdministrator',
  CustomerServiceElevatedAdministrator = 'CustomerServiceElevatedAdministrator',
  DistrictAdministrator = 'DistrictAdministrator',
  Parent = 'Parent',
  SchoolAdministrator = 'SchoolAdministrator',
  Student = 'Student',
  Teacher = 'Teacher',
}

export enum RoleTypeLegacy {
  anonymous = 1,
  authenticated = 2,
  administrator = 3,
  student = 4,
  teacher = 5,
  school_admin = 6,
  account_admin = 10,
}

export const roleTypeNames = [
  { roleType: RoleType.Student, name: 'Student' },
  { roleType: RoleType.Teacher, name: 'Teacher' },
  { roleType: RoleType.DistrictAdministrator, name: 'District Admin' },
  { roleType: RoleType.SchoolAdministrator, name: 'School Admin' },
  { roleType: RoleType.CustomerServiceAdministrator, name: 'CX Support' },
  { roleType: RoleType.CustomerServiceElevatedAdministrator, name: 'CX Admin' },
  { roleType: RoleType.ApplicationAdministrator, name: 'App Admin' },
  { roleType: RoleType.Parent, name: 'Parent' },
];

export function getRoleName(type: RoleTypeLegacy): string {
  switch (type) {
  case RoleTypeLegacy.anonymous:
    return 'anonymous user';
  case RoleTypeLegacy.authenticated:
    return 'authenticated user';
  case RoleTypeLegacy.student:
    return 'student user';
  case RoleTypeLegacy.teacher:
    return 'teacher user';
  case RoleTypeLegacy.school_admin:
    return 'school admin user';
  case RoleTypeLegacy.account_admin:
    return 'account administrator';
  default:
    return null;
  }
}

export namespace RoleGroup {

  export const routeAppAdminRole = [RoleType.ApplicationAdministrator];
  export const routeSchoolAdminRole = [RoleType.SchoolAdministrator];
  export const routeDistrictAdminRole = [RoleType.DistrictAdministrator];
  export const routeParentRole = [RoleType.Parent];
  export const teachers = [RoleType.Teacher];
  export const routeStudentRole = [RoleType.Student];
  export const routeProductsRole = [RoleType.Teacher, RoleType.Student];

  export const routeInternalRoles = [
    RoleType.ApplicationAdministrator,
    RoleType.CustomerServiceAdministrator,
    RoleType.CustomerServiceElevatedAdministrator,
  ];

  export const routeReportsRole = [
    RoleType.DistrictAdministrator,
    RoleType.SchoolAdministrator,
    RoleType.Teacher,
    RoleType.Student,
    RoleType.Parent,
  ];

  export const routeLearningReportsRole = [
    RoleType.DistrictAdministrator,
    RoleType.SchoolAdministrator,
    RoleType.Teacher,
    RoleType.Parent
  ];

  // Other Role Groups

  export const allButStudentRole = [
    RoleType.ApplicationAdministrator,
    RoleType.CustomerServiceAdministrator,
    RoleType.CustomerServiceElevatedAdministrator,
    RoleType.DistrictAdministrator,
    RoleType.SchoolAdministrator,
    RoleType.Teacher,
    RoleType.Parent,
  ];

  export const externalRoles: RoleType[] = [
    RoleType.Teacher,
    RoleType.Student,
    RoleType.Parent,
    RoleType.SchoolAdministrator,
    RoleType.DistrictAdministrator,
  ];

  export const digitalAdmins = [
    RoleType.SchoolAdministrator,
    RoleType.DistrictAdministrator
  ];

  export const rolesDigitalAdminCanMasqueradeAs = [
    RoleType.Teacher,
  ];

  export const externalRolesThatCanMasquerade = [
    RoleType.DistrictAdministrator,
    RoleType.SchoolAdministrator,
  ];

  export const internalRolesThatCanMasquerade = [
    RoleType.ApplicationAdministrator,
    RoleType.CustomerServiceAdministrator,
    RoleType.CustomerServiceElevatedAdministrator
  ];

  export const customerServiceRoles = [
    RoleType.CustomerServiceAdministrator,
    RoleType.CustomerServiceElevatedAdministrator
  ];

  export const elevatedAdminRoles = [
    RoleType.ApplicationAdministrator,
    RoleType.CustomerServiceElevatedAdministrator
  ];

}
