import { CommonModule } from '@angular/common';
import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { NgbTooltip } from '@ng-bootstrap/ng-bootstrap';
import { ZbpIconComponent } from '@shared/zbp-icon/zbp-icon';

@Component({
  standalone: true,
  selector: 'zbp-button-component',
  templateUrl: './button.component.html',
  styleUrls: ['./button.component.scss'],
  imports: [CommonModule, ZbpIconComponent, NgbTooltip]
})
export class ButtonComponent implements OnInit {

  /** Text label for the button */
  @Input() label: string = '';

  /** Name of icon from zbpIconNames if button needs icon  */
  @Input() iconName: string = null;

  /** See {@link ZbpIconComponent#primaryColor} */
  @Input() iconPrimaryColor?: string;

  /** See {@link ZbpIconComponent#secondaryColor} */
  @Input() iconSecondaryColor?: string;

  /** Adjusts the size of the icon rendered.
   * <ul>
   * <li><strong>default</strong>: lets the icon do whatever it wants.</li>
   * <li><strong>font-size</strong>: forces icon to match font size.</li>
   * </ul>
   */
  @Input() iconSize: 'font-size' | 'default' = 'default';
  @Input() iconPosition: 'left' | 'right' = 'left';
  @Input() tooltip: string | undefined;
  @Input() tooltipClass: string | undefined;
  /** Aria label to be used when extra accessibility is needed */
  @Input() ariaLabel: string = null;
  /** Theme of the button which can be 'primary', 'ghost', or 'greyGhost' */
  @Input() theme: 'primary' | 'secondary' | 'ghost' | 'greyGhost' | 'greySolid'
    | 'text' | 'primaryText' | 'blackText' | 'primaryTextLink' | 'icon' | 'icon-alt'
    | 'icon-white' | 'breadcrumbText' | 'tealSolid' | 'tealGhost' = 'primary';
  /** Disabled state of the button */
  @Input() disabled: boolean = false;
  @Input() useMinWidth: boolean = false;
  /** Uses $spacing-S for top and bottom padding */
  @Input() topBottomPaddingSpacingS: boolean = false;
  /** Sets button width to 100% */
  @Input() fullWidth: boolean = false;
  /** Will add additional left and right padding to button so that label and border have more space */
  @Input() extraHorizontalPadding: boolean = false;
  /**
   * There are a couple instances of buttons that are slightly off from the common themes
   * Instead of creating a theme for each of those cases, provide a class name to add to the button
   * Any unique styling can be done in that class. You are still able to provide a theme to design off of.
   */
  @Input() customClass: string = '';
  /**
   * Choose unit to use for button font size.
   * <ul>
   * <li><strong>em</strong>: Useful for allowing iconSize='font-size' to respect the button's parent's font size.</li>
   * <li><strong>rem</strong>: Is the default, allows buttons to have a standard font size across the app.</li>
   * </ul>
   **/
  @Input() fontSizeUnit: 'em' | 'rem' = 'rem';
  /** Unique identifier of the button */
  @Input() internalId: string;
  /** Type of the button which can be 'submit', 'button', or 'reset' */
  @Input() type: 'submit' | 'button' | 'reset' = 'button';
  /** Flag for showing a spinner instead regular content */
  @Input() showSpinner: boolean = false;
  /**Attaches the button to the form ID provided. Can be used when the submit button lies outside of the form element.
   * This enables them form to be submitted using an 'enter' keypress.
   * ________
   * Example:
   *
   * `<form id="test-form"></form>`
   *
   * `<zbp-button-component label="Save" formId="test-form"></zbp-button-component>`
   */
  @Input() formId: string = null;
  /** Event emitter for click event */
  @Output() clickHandler: EventEmitter<Event> = new EventEmitter();

  /** Width of button after initial load */
  originalWidth: number;
  /** Height of button after initial load */
  originalHeight: number;

  /** Removes point events when button is disabled and there is no tooltip */
  removePointerEvents = false;

  ngOnInit(): void {
    this.removePointerEvents = this.disabled && !this.tooltip;
  }

  /** HTML button element as an ElementRef*/
  @ViewChild('buttonComponent') buttonElement!: ElementRef;

  /**
   * Handles the click event and emits the event if the button is not disabled
   * @param {Event} event - The DOM event triggered on button click
   */
  handleClick(event: Event): void {
    if (!this.showSpinner) {
      if (!this.disabled && !!this.clickHandler) {
        this.clickHandler.emit(event);
      }
    } else {
      // Do not allow events when button spinner is showing
      event.preventDefault();
    }
  }

  constructor() {
  }

}
