export const arrowChevronRightIcon = `
<svg xmlns="http://www.w3.org/2000/svg" width="8" height="11" viewBox="0 0 8 11"
     fill="none">
  <path fill-rule="evenodd"
        clip-rule="evenodd"
        d="M2.02787 0.0616121L0.754882 1.36007L4.69914 5.38322L0.756549 9.40467L2.02954 10.7031L5.97213 6.68167L5.97248 6.68203L7.24547 5.38357L7.24512 5.38322L7.24544 5.3829L5.97245 4.08444L5.97213 4.08476L2.02787 0.0616121Z"
        fill="var(--zbp-svg-primary-color, black)"
  />
</svg>
`;
