export const ellipseIcon = `
<svg xmlns="http://www.w3.org/2000/svg" width="87" height="87" viewBox="0 0 87 87"
     fill="inherit">
    <circle cx="43.1173" cy="43.7843" r="38"
            fill="var(--zbp-svg-secondary-color, white)"
            stroke="var(--zbp-svg-primary-color, black)"
            stroke-width="6"
    />
</svg>
`;

