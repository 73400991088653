import { ProductService } from '@core/product.service';
import { NavigationMenuItem } from '@models/navigation-menu-item';
import { BehaviorSubject } from 'rxjs';

/**
 * Used to build subnavigation menu for Products Module.
 * Products module will route to either ECE Module, Products Component, and TeacherComponent for Teachers
 * Currently the products subnav is only shown in the Teacher experience.
 *
 * @param {boolean} advancedMenu - Indicates if the product has a more complex subnavigation.
 * @param {BehaviorSubject<NavigationMenuItem[]>} subNavMenuItems
 *  - A BehaviorSubject that holds the sub-navigation menu items.
 * @param {ProductService} productService - The service that provides product-related information.
 * @param {boolean} isHighlightsPortalUser - Indicates if the user is a Highlights Portal user.
 * @param {boolean} showFiveDayPlanner - Indicates if the Five-Day Planner should be shown.
 * @param {boolean} showLessonPage - Indicates if the Lesson Page should be shown.
 */
export function  buildProductsSubnav(
  advancedMenu: boolean,
  subNavMenuItems: BehaviorSubject<NavigationMenuItem[]>,
  productService: ProductService,
  isHighlightsPortalUser: boolean,
  showFiveDayPlanner: boolean,
  showLessonPage: boolean,
): void {
  if (isHighlightsPortalUser) {
    // When ECE, Products routes to ECE Component
    subNavMenuItems.next([
      {
        id: 'ece-planner-sub-menu-item',
        label: 'Planner',
        weight: 0,
        route: 'products/ece/weekly-planner',
      },
      {
        id: 'ece-materials-sub-menu-item',
        weight: 1,
        route: 'products/ece/materials',
        label: 'Materials',
      }
    ]);
  } else {
    const newMenus: NavigationMenuItem[] = [];
    // For products that have a more complex subnavigation, such as Superkids and Handwriting 2025.
    // These use the Teacher component.
    // Routing will route to /teacher for Superkids and products with the materials and five-day planner
    if (advancedMenu) {
      newMenus.push(
        {
          id: 'teacher-material-sub-menu-item',
          weight: 1,
          route: `../learning/products/${productService.currentProductLineKey$?.value}/${productService.currentVariantType$?.value}/teacher/material`,
          label: 'Materials'
        },

      );

      if (showFiveDayPlanner) {
        newMenus.push(
          {
            id: 'teacher-planner-sub-menu-item',
            weight: 2,
            route: `../learning/products/${productService.currentProductLineKey$?.value}/${productService.currentVariantType$?.value}/teacher/planner`,
            label: 'Five-Day Planner'
          }
        );
      }

      if (showLessonPage) {
        newMenus.push(
          {
            id: 'teacher-lesson-sub-menu-item',
            weight: 0,
            route: `../learning/products/${productService.currentProductLineKey$?.value}/${productService.currentVariantType$?.value}/teacher/lesson`,
            label: 'Lesson Page'
          },
        );
      }
    } else {
      // Add product page menu item for products that shouldn't have
      // the five-day planner, materials, or lesson page (supplemental).
      // Thee use the Product component.
      newMenus.push(
        {
          id: 'product-lesson-sub-menu-item',
          weight: 0,
          route: `../learning/products/${productService.currentProductLineKey$?.value}/${productService.currentVariantType$?.value}`,
          label: 'Lesson Page'
        },
      );
    }

    newMenus.sort((x, y) => x.weight - y.weight);

    subNavMenuItems.next(newMenus);
  }
}
