<section *ngIf="form && rows">
  <zbp-data-table
    [theme]="tableTheme"
    [columns]="columns"
    [rows]="rows"
    [isLoading]="!licensesLoaded"
    [formArray]="licensesFormArray"
    [noResultsMessage]="getNoResultsMessage()"
  ></zbp-data-table>
</section>
