export const arrowRightIcon = `
<svg xmlns="http://www.w3.org/2000/svg" width="25" height="24" viewBox="0 0 25 24"
     fill="none">
  <path fill-rule="evenodd"
        clip-rule="evenodd"
        d="M24.3664 10.7812C24.7268 11.1417 24.7268 11.7262 24.3664 12.0867L18.4919 17.9611C18.1314 18.3216 17.547 18.3216 17.1865 17.9611C16.826 17.6006 16.826 17.0161 17.1865 16.6557L21.4851 12.357L1.5598 12.357C1.04999 12.357 0.636719 11.9437 0.636719 11.4339C0.636719 10.9241 1.05 10.5109 1.5598 10.5109L21.4851 10.5109L17.1865 6.21223C16.826 5.85175 16.826 5.26729 17.1865 4.9068C17.547 4.54632 18.1314 4.54632 18.4919 4.9068L24.3664 10.7812Z"
        fill="var(--zbp-svg-primary-color, black)"
  />
</svg>
`;
