import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
  TemplateRef
} from '@angular/core';
import { School } from '@models/school';
import { IUser } from '@models/user';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { LicenseAssignmentType } from '@shared/enums/license-status';
import { RoleType } from '@shared/enums/role-type';
import { UserSortColumns } from '@shared/enums/user-sort-columns';
import { Helpers } from '@shared/helpers';
import { ZbpDataTableComponent } from '@shared/zbp-data-table/zbp-data-table.component';
import { ZbpDataTable } from '@shared/zbp-data-table/zbp-data-table.model';
import { ToastrService } from 'ngx-toastr';
import { first, from, of, Subscription, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import {
  BrandedLicenseModalComponent
} from 'src/app/schools/school/license-modal/branded-license-modal/branded-license-modal.component';
import { SchoolsFacade } from 'src/app/schools/store/schools.facade';
import SORTABLE_COLUMN = ZbpDataTable.SORTABLE_COLUMN;
import SIMPLE_COLUMN = ZbpDataTable.SIMPLE_COLUMN;
import BLANK_COLUMN = ZbpDataTable.BLANK_COLUMN;
import TEXT_CELL = ZbpDataTable.TEXT_CELL;
import TEXT_LIST_CELL = ZbpDataTable.TEXT_LIST_CELL;
import LINK_CELL = ZbpDataTable.LINK_CELL;
import ACTION_LIST_CELL = ZbpDataTable.ACTION_LIST_CELL;
import PageChangeEvent = ZbpDataTable.PageChangeEvent;

@Component({
  selector: 'zbp-users-table',
  standalone: true,
  imports: [
    ZbpDataTableComponent
  ],
  templateUrl: './users-table.component.html',
  styleUrl: './users-table.component.scss'
})
export class UsersTableComponent implements OnInit, OnChanges, OnDestroy {
  // eslint-disable-next-line max-len
  protected readonly NO_SCHOOL_EDIT_LICENSE_TOOLTIP: string = 'You must select a specific school in your district before editing licenses for a user.';

  @Input({ required: true }) roleType: RoleType;
  @Input({ required: true }) users: IUser[];
  @Input() schoolId?: string;
  @Input() noResultsMessage?: string;
  @Input() isLoading: boolean = false;
  @Input() isDistSearch: boolean = false;
  @Input() leftFooterContent: TemplateRef<any>;
  @Input() rightFooterContent: TemplateRef<any>;
  @Input() buildActions: (user: IUser, roleType?: RoleType) => ZbpDataTable.Action[] = () => [];

  // COLUMN CONTROLS
  @Input() hideExternalIdColumn: boolean = false;
  @Input() hideStatusColumn: boolean = false;
  @Input() hideActionsColumn: boolean = false;

  // PAGE CONTROLS
  @Input() pageSize: number = 25;
  @Input() maxPageCount: number = 10;
  @Input() collectionSize: number;
  @Input() page: number;
  @Output() pageChange = new EventEmitter<PageChangeEvent>();

  /** Emitted when the user clicks on a sortable column heading, changing the sort value */
  @Output() orderByParamChange = new EventEmitter<string>();

  dataHasExternalId = false;
  dataHasUsername = false;
  dataHasEmail = false;
  dataHasSchools = false;
  dataHasLicenses = false;

  columns: ZbpDataTable.Column[] = [];
  rows: ZbpDataTable.Row[] = [];

  school: School | null = null;

  private subscriptions: Subscription = new Subscription();

  constructor(
    private ngbModal: NgbModal,
    private schoolsFacade: SchoolsFacade,
    private toastr: ToastrService,
  ) {
  }

  ngOnInit() {
    this.loadSchool();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes && changes.schoolId) {
      this.loadSchool();
    }
    this.updateFlagsByRoleType();
    this.columns = this.buildColumns();
    this.rows = this.buildRows();
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }

  onPageChange(event: PageChangeEvent): void {
    this.pageChange.emit(event);
  }

  loadSchool(): void {
    if (this.hasSchoolId()) {
      this.schoolsFacade.get(this.schoolId)
        .pipe(first())
        .subscribe((school) => {
          this.school = school ?? null;
        });
    }
  }

  updateFlagsByRoleType(): void {
    this.resetFlagsToDefaults();
    if (this.isDistSearch) {
      this.dataHasSchools = true;
    }
    switch (this.roleType) {
    case RoleType.Teacher:
      this.dataHasEmail = true;
      this.dataHasLicenses = true;
      break;
    case RoleType.Student:
      this.dataHasExternalId = true;
      this.dataHasUsername = true;
      break;
    case RoleType.Parent:
      this.dataHasEmail = true;
      break;
    case RoleType.SchoolAdministrator: // fall through
    case RoleType.DistrictAdministrator:
      this.dataHasEmail = true;
      break;
    default:
      break;
    }
  }

  resetFlagsToDefaults(): void {
    this.dataHasExternalId = false;
    this.dataHasUsername = false;
    this.dataHasEmail = false;
    this.dataHasSchools = false;
    this.dataHasLicenses = false;
  }

  buildColumns(): ZbpDataTable.Column[] {
    const newColumns: ZbpDataTable.Column[] = [
      {
        type: SORTABLE_COLUMN,
        key: UserSortColumns.firstName,
        header: 'First',
        tooltip: 'Click to sort by first name',
      },
      {
        type: SORTABLE_COLUMN,
        key: UserSortColumns.lastName,
        header: 'Last',
        tooltip: 'Click to sort by last name',
      }
    ];
    if (this.dataHasEmail) {
      newColumns.push({
        type: SORTABLE_COLUMN,
        key: UserSortColumns.username,
        header: 'Email',
        tooltip: 'Click to sort by email',
      });
    }
    if (this.dataHasExternalId && !this.hideExternalIdColumn) {
      newColumns.push({
        type: SORTABLE_COLUMN,
        key: UserSortColumns.externalId,
        header: 'External Id',
        tooltip: 'Click to sort by external id',
      });
    }
    if (this.dataHasUsername) {
      newColumns.push({
        type: SORTABLE_COLUMN,
        key: UserSortColumns.username,
        header: 'Username',
        tooltip: 'Click to sort by username',
      });
    }
    if (this.dataHasSchools) {
      newColumns.push({
        type: SIMPLE_COLUMN,
        key: 'school',
        header: 'School',
      });
    }
    if (this.dataHasLicenses) {
      newColumns.push({
        type: SIMPLE_COLUMN,
        key: 'licenses',
        header: 'Licenses',
      });
    }
    newColumns.push(
      {
        type: SORTABLE_COLUMN,
        key: UserSortColumns.lastLogin,
        header: 'Last Login',
        tooltip: 'Click to sort by last login',
      }
    );
    if (!this.hideStatusColumn) {
      newColumns.push(
        {
          type: SORTABLE_COLUMN,
          key: UserSortColumns.status,
          header: 'Status',
          tooltip: 'Click to sort by status',
        }
      );
    }
    if (!this.hideActionsColumn) {
      // only add the actions column if we have a function provided by the parent for creating the actions
      newColumns.push({
        type: BLANK_COLUMN,
        key: 'actions',
        ariaLabel: 'actions'
      });
    }
    return newColumns;
  }

  buildRows(): ZbpDataTable.Row[] {
    const newRows: ZbpDataTable.Row[] = [];
    this.users.forEach(user => newRows.push(this.buildRow(user)));
    return newRows;
  }

  buildRow(user: IUser): ZbpDataTable.Row {
    const newCells: Record<string, ZbpDataTable.Cell> = {
      [UserSortColumns.firstName]: {
        type: TEXT_CELL,
        text: user.firstName,
      },
      [UserSortColumns.lastName]: {
        type: TEXT_CELL,
        text: user.lastName,
      }
    };
    if (this.dataHasEmail) {
      newCells[UserSortColumns.username] = {
        type: TEXT_CELL,
        text: user.username,
      };
    }
    if (this.dataHasExternalId) {
      newCells[UserSortColumns.externalId] = {
        type: TEXT_CELL,
        text: user.externalId,
      };
    }
    if (this.dataHasUsername) {
      newCells[UserSortColumns.username] = {
        type: TEXT_CELL,
        text: user.studentUserName,
      };
    }
    if (this.dataHasSchools) {
      newCells.school = {
        type: TEXT_LIST_CELL,
        textList: this.getSchoolNamesList(user),
      };
    }
    if (this.dataHasLicenses) {
      const licensesText = `${this.getLicensesCount(user)}`;
      if (this.hasSchoolId()) {
        newCells.licenses = {
          type: LINK_CELL,
          text: licensesText,
          onClick: () => this.openEditLicensesModal(user),
        };
      } else {
        newCells.licenses = {
          type: TEXT_CELL,
          tooltip: this.NO_SCHOOL_EDIT_LICENSE_TOOLTIP,
          text: licensesText,
        };
      }
    }
    newCells[UserSortColumns.lastLogin] = {
      type: TEXT_CELL,
      text: Helpers.getLastActivityTime(user.lastActivityTime),
    };
    newCells[UserSortColumns.status] = {
      type: TEXT_CELL,
      text: this.getStatusText(user),
    };
    if (!this.hideActionsColumn) {
      newCells.actions = {
        type: ACTION_LIST_CELL,
        actions: this.buildActions(user, this.roleType),
      };
    }
    return { cells: newCells };
  }

  hasSchoolId(): boolean {
    return !Helpers.isNullOrUndefined(this.schoolId);
  }

  getSchoolNamesList(user: IUser): string[] {
    return user.schools
      .map((school: School) => school.name)
      .map(name => name.toLowerCase()
        .split(' ')
        .map(s => s.charAt(0).toLocaleUpperCase() + s.substring(1))
        .join(' '));
  }

  getLicensesCount(user: IUser): number {
    return user.assignedLicenseCount ?? 0;
  }

  getStatusText(user: IUser): string {
    return user.isActive ? 'active' : 'deactivated';
  }


  // EVENTS

  openEditLicensesModal(user: IUser): void {
    const modalRef = BrandedLicenseModalComponent.open(this.ngbModal, {
      school: this.school,
      assignedId: user.userId,
      assignmentType: LicenseAssignmentType.Teacher,
    });
    this.subscriptions.add(
      from(modalRef.result)
        .pipe(
          catchError((err) => {
            if (err instanceof Error) {
              return throwError(() => err);
            }
            return of(null);
          })
        )
        .subscribe((newCount?: number) => {
          if (newCount !== null && newCount !== undefined) {
            // eslint-disable-next-line no-param-reassign
            user.assignedLicenseCount = newCount;
            this.toastr.success('Successfully updated the user licenses.');
          }
        })
    );
  }

}
