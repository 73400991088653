export const resizeHandleIcon = `
<svg width="10" height="17" viewBox="0 0 10 17" fill="inherit"
     xmlns="http://www.w3.org/2000/svg">
  <path fill-rule="evenodd"
        clip-rule="evenodd"
        d="M3.60195 1.30037C2.45999 0.898333 1.23605 0.691406 0 0.691406V8.69141V16.6914C1.23605 16.6914 2.45999 16.4845 3.60195 16.0824C4.74391 15.6804 5.78152 15.0911 6.65554 14.3483C7.52956 13.6054 8.22286 12.7235 8.69588 11.7529C9.16889 10.7823 9.41235 9.74198 9.41235 8.69141C9.41235 7.64083 9.16889 6.60054 8.69588 5.62994C8.22286 4.65933 7.52956 3.77742 6.65554 3.03455C5.78152 2.29168 4.74391 1.70241 3.60195 1.30037ZM2.51064 4.69141L2.51064 13.6914H3.76562L3.76562 4.69141H2.51064Z"
        fill="var(--zbp-svg-primary-color, black)"
  />
</svg>
`;
