import { FormArray } from '@angular/forms';
import { UserPartial } from '@models/user';
import { NgbTypeaheadSelectItemEvent } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';

/**
 * Helper Functions used when managing a Teacher user.
 */
export namespace TeacherRoleHelpers {
   /**
   * Fills in values to an existing teacher row.
   *
   * @param {number} index
   * @param {UserPartial} data
  */
  export function updateTeacherRow(index: number, data: UserPartial, newUsersFormArray: FormArray): void {
    newUsersFormArray.at(index).setValue({
      userId: data.userId,
      firstName: data.firstName,
      lastName: data.lastName,
      username: data.userName,
    });
  }

  /**
   * Adds an existing teacher from typeahead selection.
   *
   * @param {NgbTypeaheadSelectItemEvent} event Special event with item and preventDefault properties.
   */
  export function addExistingTeacher(event: NgbTypeaheadSelectItemEvent, newUsersFormArray: FormArray, formRowHasEmptyValuesFunc, addAnotherFunc, toastr: ToastrService): void {
    const { item } = event;
    const notFound = newUsersFormArray.value.findIndex(u => u?.username === item?.username) === -1;
    if (notFound) {
      const lastRowIndex = newUsersFormArray.length - 1;
      if (!(lastRowIndex < 0) && formRowHasEmptyValuesFunc(lastRowIndex, newUsersFormArray)) {
        // Updates existing empty row instead of adding a new one.
        TeacherRoleHelpers.updateTeacherRow(
          lastRowIndex,
          {
            ...item,
            userName: item.username,
          } as UserPartial,
          newUsersFormArray);
      } else {
        addAnotherFunc();

        TeacherRoleHelpers.updateTeacherRow(lastRowIndex + 1, {
          ...item,
          userName: item.username,
        } as UserPartial, newUsersFormArray);
      }
      newUsersFormArray
        .at(newUsersFormArray.length - 1)
        .get('username')
        .markAsDirty();
      newUsersFormArray.updateValueAndValidity();
    } else {
      toastr.warning(
        `${item?.username} is already a teacher for this classroom.`
      );
    }
  }
}
