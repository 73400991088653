import { InjectionToken } from '@angular/core';
import {
  createAction,
  ActionReducer,
  ActionReducerMap,
  INIT,
  MetaReducer,
} from '@ngrx/store';

import * as fromAdmin from '../../admin/store/admin.reducer';
import * as fromLearning from '../../classrooms/store/learning.reducer';
import * as fromDistricts from '../../districts/store/districts.reducer';
import * as fromParent from '../../parents/store/parent.reducer';
import * as fromSchools from '../../schools/store/schools.reducer';

export interface State {
  [fromDistricts.districtsFeatureKey]: fromDistricts.DistrictsState;
  [fromSchools.schoolsFeatureKey]: fromSchools.SchoolsState;
  [fromAdmin.adminFeatureKey]: fromAdmin.AdminState;
  [fromLearning.learningFeatureKey]: fromLearning.LearningState;
  [fromParent.parentFeatureKey]: fromParent.ParentState;
}

export const CORE_REDUCERS = new InjectionToken<ActionReducerMap<State>>('Core reducers token', {
  factory: () => ({
    [fromDistricts.districtsFeatureKey]: fromDistricts.reducer,
    [fromSchools.schoolsFeatureKey]: fromSchools.reducer,
    [fromAdmin.adminFeatureKey]: fromAdmin.reducer,
    [fromLearning.learningFeatureKey]: fromLearning.reducer,
    [fromParent.parentFeatureKey]: fromParent.reducer,
  }),
});

export const resetStore = createAction('[Core] Reset state');

export function reset(reducer: ActionReducer<any>): ActionReducer<any> {
  return (state, action) => {
    if (action && action.type === resetStore.type) {
      return reducer(undefined, { type: INIT });
    }
    return reducer(state, action);
  };
}

export const metaReducers: MetaReducer<State>[] = [reset];
