export const downloadIcon = `
<svg xmlns="http://www.w3.org/2000/svg" width="25" height="24" viewBox="0 0 25 24"
     fill="none">
  <path d="M20.25 10.5H15.75V0H9.75V10.5H5.25V12L12.75 19.5L20.25 12V10.5Z"
        fill="var(--zbp-svg-primary-color, black)"
  />
  <path d="M21.75 21H3.75V24H21.75V21Z"
        fill="var(--zbp-svg-primary-color, black)"
  />
</svg>
`;
