import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class TeacherService {
  currentSchoolId$: BehaviorSubject<string> = new BehaviorSubject<string>(null);

  constructor() {}
}
