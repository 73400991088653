export const pencilIcon = `
<svg xmlns="http://www.w3.org/2000/svg"
     xmlns:xlink="http://www.w3.org/1999/xlink"
     viewBox="0 0 22.27 22.27"
     width="24" height="23">
  <defs>
    <clipPath id="clip-path" transform="translate(-0.77 -0.37)">
      <rect width="24" height="24" style="fill:none"/>
    </clipPath>
  </defs>
  <g id="Layer_2" data-name="Layer 2">
    <g id="Layer_1-2" data-name="Layer 1">
      <g style="clip-path:url(#clip-path)">
        <path
          d="M18.32,1.6h0a1.62,1.62,0,0,0-1.15-.48A1.6,1.6,0,0,0,16,1.6h0L13.57,4.05,13,4.58l.53.53,4.74,4.74.53.53.54-.53L21.82,7.4h0a1.6,1.6,0,0,0,.48-1.15,1.25,1.25,0,0,0-.35-1Z"
          transform="translate(-0.77 -0.37)"
          stroke-width="1.4999899864196777px"
          stroke="var(--zbp-svg-primary-color, black)"
          fill="none"
        />
        <path
          d="M13.39,5.29l-.53-.54-.53.54L3.75,13.86h0a1.52,1.52,0,0,0-.39.63h0L1.61,19.76h0a1.62,1.62,0,0,0-.07.76,1.65,1.65,0,0,0,.87,1.2,1.59,1.59,0,0,0,.74.18h0a1.54,1.54,0,0,0,.52-.09h0l5.25-1.75h0a1.63,1.63,0,0,0,.64-.39h0l8.58-8.58.53-.53L18.14,10Z"
          transform="translate(-0.77 -0.37)"
          stroke-width="1.4999899864196777px"
          stroke="var(--zbp-svg-primary-color, black)"
          fill="none"
        />
      </g>
    </g>
  </g>
</svg>
`;
