export const printerIcon = `
<svg xmlns="http://www.w3.org/2000/svg" width="24" height="25" viewBox="0 0 24 25">
  <g clip-path="url(#clip0_3133_17411)">
    <path
      d="M3.75 12.4573C3.94891 12.4573 4.13968 12.3783 4.28033 12.2376C4.42098 12.097 4.5 11.9062 4.5 11.7073C4.5 11.5084 4.42098 11.3176 4.28033 11.1769C4.13968 11.0363 3.94891 10.9573 3.75 10.9573C3.55109 10.9573 3.36032 11.0363 3.21967 11.1769C3.07902 11.3176 3 11.5084 3 11.7073C3 11.9062 3.07902 12.097 3.21967 12.2376C3.36032 12.3783 3.55109 12.4573 3.75 12.4573Z"
      fill="var(--zbp-svg-primary-color, black)"
    />
    <path
      d="M7.5 1.95728C6.70435 1.95728 5.94129 2.27335 5.37868 2.83596C4.81607 3.39856 4.5 4.16163 4.5 4.95728V7.95728H3C2.20435 7.95728 1.44129 8.27335 0.87868 8.83595C0.316071 9.39856 0 10.1616 0 10.9573L0 15.4573C0 16.2529 0.316071 17.016 0.87868 17.5786C1.44129 18.1412 2.20435 18.4573 3 18.4573H4.5V19.9573C4.5 20.7529 4.81607 21.516 5.37868 22.0786C5.94129 22.6412 6.70435 22.9573 7.5 22.9573H16.5C17.2956 22.9573 18.0587 22.6412 18.6213 22.0786C19.1839 21.516 19.5 20.7529 19.5 19.9573V18.4573H21C21.7956 18.4573 22.5587 18.1412 23.1213 17.5786C23.6839 17.016 24 16.2529 24 15.4573V10.9573C24 10.1616 23.6839 9.39856 23.1213 8.83595C22.5587 8.27335 21.7956 7.95728 21 7.95728H19.5V4.95728C19.5 4.16163 19.1839 3.39856 18.6213 2.83596C18.0587 2.27335 17.2956 1.95728 16.5 1.95728H7.5ZM6 4.95728C6 4.55945 6.15804 4.17792 6.43934 3.89662C6.72064 3.61531 7.10218 3.45728 7.5 3.45728H16.5C16.8978 3.45728 17.2794 3.61531 17.5607 3.89662C17.842 4.17792 18 4.55945 18 4.95728V7.95728H6V4.95728ZM7.5 12.4573C6.70435 12.4573 5.94129 12.7733 5.37868 13.336C4.81607 13.8986 4.5 14.6616 4.5 15.4573V16.9573H3C2.60218 16.9573 2.22064 16.7992 1.93934 16.5179C1.65804 16.2366 1.5 15.8551 1.5 15.4573V10.9573C1.5 10.5595 1.65804 10.1779 1.93934 9.89662C2.22064 9.61531 2.60218 9.45728 3 9.45728H21C21.3978 9.45728 21.7794 9.61531 22.0607 9.89662C22.342 10.1779 22.5 10.5595 22.5 10.9573V15.4573C22.5 15.8551 22.342 16.2366 22.0607 16.5179C21.7794 16.7992 21.3978 16.9573 21 16.9573H19.5V15.4573C19.5 14.6616 19.1839 13.8986 18.6213 13.336C18.0587 12.7733 17.2956 12.4573 16.5 12.4573H7.5ZM18 15.4573V19.9573C18 20.3551 17.842 20.7366 17.5607 21.0179C17.2794 21.2992 16.8978 21.4573 16.5 21.4573H7.5C7.10218 21.4573 6.72064 21.2992 6.43934 21.0179C6.15804 20.7366 6 20.3551 6 19.9573V15.4573C6 15.0595 6.15804 14.6779 6.43934 14.3966C6.72064 14.1153 7.10218 13.9573 7.5 13.9573H16.5C16.8978 13.9573 17.2794 14.1153 17.5607 14.3966C17.842 14.6779 18 15.0595 18 15.4573Z"
      fill="var(--zbp-svg-primary-color, black)"
    />
  </g>
  <defs>
    <clipPath id="clip0_3133_17411">
      <rect width="24" height="24"
            transform="translate(0 0.457275)"
            fill="white"
      />
    </clipPath>
  </defs>
</svg>
`;

