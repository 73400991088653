import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { AppConfigService } from '@core/appconfig.service';
import { EducationalUnitApiService } from '@core/services/educational-unit/educational-unit-api.service';
import { ApiResponse } from '@models/api-response';
import { Classroom, IClassroom, IClassroomCreate } from '@models/classroom';
import { IEducationalUnit } from '@models/educational-unit';
import { KeyValuePair } from '@models/key-value-pair';
import { ILicense } from '@models/license/license';
import { LicenseAssignee } from '@models/license/license-assignee';
import { SchoolLicense } from '@models/license/school-license';
import { PagedResponse } from '@models/paged-response';
import { School } from '@models/school';
import { ISchoolSettings } from '@models/school-settings';
import { IUser, UserPartial } from '@models/user';
import { IUserSettings } from '@models/user/user-settings';
import { EducationalUnitService } from '@shared/educational-unit/educational-unit.service';
import { LicenseFilterType } from '@shared/enums/license-filter-type';
import { LicenseAssignmentType } from '@shared/enums/license-status';
import { ProductType } from '@shared/enums/product-type';
import { RoleType } from '@shared/enums/role-type';
import { VariantType } from '@shared/enums/variant-type';
import { copyArray, copyObject, instantiateFromJsonReverseParam } from '@shared/zb-object-helper/object-helper';
import * as _ from 'lodash';
import * as moment from 'moment';
import { Observable } from 'rxjs';
import { map, shareReplay } from 'rxjs/operators';

@Injectable()
export class SchoolsService extends EducationalUnitService {

  constructor(
    public http: HttpClient,
    educationalUnitApiService: EducationalUnitApiService,
    appConfig: AppConfigService,
  ) {
    super(http, educationalUnitApiService, appConfig);
  }

  getSchoolUrl(educationalUnitId?: string, params: KeyValuePair[] = []): string {
    const baseUrl = `${this.appConfig.apiUrl}/educational-unit`;
    if (educationalUnitId) {
      return `${baseUrl}/${educationalUnitId}`;
    }

    return this.validateParams(params).reduce((ret, param) => (
      `${ret}${encodeURIComponent(param.key)}=${encodeURIComponent(param.value)}&`
    ), `${baseUrl}?`);
  }

  getSchoolSettingsUrl(educationalUnitId: string): string {
    return `${this.getSchoolUrl(educationalUnitId)}/user-setting`;
  }

  getProductUsageReportUrl(classroomId: string, productType: ProductType, variant: VariantType): string {
    const offset = `offsetInMinutes=${moment().utcOffset()}`;
    return `${this.getClassroomUrl(classroomId)}/product/${productType}/variant/${variant}/usage?${offset}`;
  }

  searchClassroomUrl(params: KeyValuePair[] = []): string {
    const baseUrl = `${this.appConfig.apiUrl}/classroom`;
    if (params.length > 0) {
      return params.reduce((ret, param) => (
        `${ret}${encodeURIComponent(param.key)}=${encodeURIComponent(param.value)}&`
      ), `${baseUrl}?`);
    }
    return baseUrl;
  }

  getClassroomUrl(classroomId?: string): string {
    const baseUrl = `${this.appConfig.apiUrl}/classroom`;
    return classroomId ? `${baseUrl}/${classroomId}` : baseUrl;
  }

  getSchoolImportUrl(zbNum: string, classroomId: string): string {
    return classroomId
      ? `${this.appConfig.apiUrl}/integration/import/zbnum/${zbNum}/classroom/${classroomId}`
      : `${this.appConfig.apiUrl}/integration/import/zbnum/${zbNum}`;
  }

  getSchoolExportUrl(zbNum: string, sheetName: string): string {
    return `${this.appConfig.apiUrl}/integration/proprietary-roster/zbnum/${zbNum}/sheet/${sheetName}`;
  }

  getImportTemplateUrl(filename: string): string {
    return `${this.appConfig.apiUrl}/integration/import/filename/${filename}`;
  }

  getSchools(params: KeyValuePair[] = []): Observable<ApiResponse<School[]>> {
    return this.http.get<ApiResponse<IEducationalUnit[]>>(this.getSchoolUrl(null, params))
      .pipe(
        map(res => new ApiResponse<School[]>(true, {
          response: res.response
            .map((values => instantiateFromJsonReverseParam(School, values))),
          messages: res.messages,
        })),
      );
  }

  getSchool(educationalUnitId: string, params: KeyValuePair[] = []): Observable<ApiResponse<School[]>> {
    return this.http.get<ApiResponse<IEducationalUnit[]>>(this.getSchoolUrl(educationalUnitId, params))
      .pipe(
        map(res => new ApiResponse<School[]>(true, {
          response: [instantiateFromJsonReverseParam(School, res.response)],
          messages: res.messages,
        })),
      );
  }

  getSchoolLicenseAssignments(schoolId: string, params: KeyValuePair[]): Observable<PagedResponse<LicenseAssignee[]>> {
    const url = params
      .reduce((result, param) => (
        `${result}${param.key}=${param.value}&`
      ), `${this.getSchoolLicensesUrl(schoolId)}/assign?`)
      .replace(/&$/, '');
    return this.http.get<PagedResponse<LicenseAssignee[]>>(url)
      .pipe(
        map(res => new PagedResponse<LicenseAssignee[]>(true, res)),
      );
  }

  updateSchoolIds(educationalUnitId: string, schools: School[]): Observable<ApiResponse<School[]>> {
    const data = schools.map(s => ({ educationalUnitId: s.schoolId, abbreviatedSchoolId: s.abbreviatedSchoolId }));
    const url = `${this.getSchoolUrl(educationalUnitId)}/schoolid`;
    return this.http.patch<ApiResponse<School[]>>(url, data)
      .pipe(
        map(res => new ApiResponse<School[]>(true, res)),
        map((res) => {
          // Merge school info.
          schools.forEach((school) => {
            const index = res.response.findIndex(s => s.educationalUnitId === school.schoolId);
            if (index !== -1) {
              res.response[index] = _.merge(school, res.response[index]);
            }
          });
          return res;
        }),
      );
  }

  searchClassrooms(params: KeyValuePair[] = []): Observable<PagedResponse<Classroom[]>> {
    return this.http.get<PagedResponse<IClassroom[]>>(this.searchClassroomUrl(params))
      .pipe(
        shareReplay(),
        map(res => new PagedResponse<Classroom[]>(true, {
          ...res,
          response: copyArray(res.response, Classroom),
        })),
      );
  }

  revokeAllLicenses(schoolId: string): Observable<ApiResponse<boolean>> {
    const url = `${this.getSchoolLicensesUrl(schoolId)}/manage?educationalUnitType=school`;
    return this.http.request('delete', url)
      .pipe(
        map(() => new ApiResponse<boolean>(true, { response: true, messages: [] })),
      );
  }

  updateSchoolLicenseAssignments(schoolId: string, licenses: LicenseAssignee[], userId: string = null, classroomId: string = null, assignementType: LicenseAssignmentType = null): Observable<ApiResponse<SchoolLicense[]>> {
    const url = this.getSchoolLicensesUrl(schoolId, null, userId, classroomId, assignementType);
    return this.http.patch<ApiResponse<ILicense[]>>(url, licenses)
      .pipe(
        map(res => new ApiResponse<SchoolLicense[]>(true, {
          response: res.response.map(v => copyObject(v, SchoolLicense)),
          messages: res.messages,
        })),
      );
  }

  assignLicenses(skuId: string, schoolId: string, assignments: LicenseAssignee[], schoolYear: number, pageNumber: number = 1, pageSize: number = 25, licenseFilterType: LicenseFilterType = LicenseFilterType.ShowAll): Observable<PagedResponse<LicenseAssignee[]>> {
    const data = {
      skuId,
      licenseFilterType,
      pageNumber,
      pageSize,
      assignments,
      schoolYear,
    };
    const url = `${this.getSchoolLicensesUrl(schoolId)}/assign`;
    return this.http.patch<PagedResponse<LicenseAssignee[]>>(url, data)
      .pipe(
        map(res => new PagedResponse<LicenseAssignee[]>(true, res)),
      );
  }

  createClassroom(schoolId: string, data: IClassroomCreate): Observable<ApiResponse<Classroom>> {
    const url = `${this.getSchoolUrl(schoolId)}/classroom`;
    return this.http.post<ApiResponse<IClassroom>>(url, data)
      .pipe(
        map(res => new ApiResponse<Classroom>(true, {
          response: copyObject(res.response, Classroom),
          messages: res.messages,
        })),
      );
  }

  updateClassroom(schoolId: string, classroomId: string, data: IClassroomCreate): Observable<ApiResponse<Classroom>> {
    const url = `${this.getSchoolUrl(schoolId)}/classroom/${classroomId}`;
    return this.http.patch<ApiResponse<IClassroom>>(url, data)
      .pipe(
        map(res => new ApiResponse<Classroom>(true, {
          response: copyObject(res.response, Classroom),
          messages: res.messages,
        })),
      );
  }

  removeClassroomUser(schoolId: string, classroomId: string, userToRemove: IUser): Observable<ApiResponse<Classroom>> {
    const isTeacher = userToRemove.isViewingAsRole(RoleType.Teacher);
    const role = isTeacher
      ? 'teacher'
      : 'student';
    const url = `${this.getSchoolUrl(schoolId)}/classroom/${classroomId}/${role}`;
    const roleBody = isTeacher
      ? 'teacherUsersToAssignToClassroom'
      : 'studentUsersToAssignToClassroom';
    const data: UserPartial = {
      userId: userToRemove.userId,
      userName: userToRemove.username,
      firstName: userToRemove.firstName,
      lastName: userToRemove.lastName,
      willBeRemoved: true,
    };
    const body = { classroomId, [roleBody]: [data] };
    return this.http.patch<ApiResponse<IClassroom>>(url, body)
      .pipe(
        map(res => new ApiResponse<Classroom>(true, {
          response: copyObject(res.response, Classroom),
          messages: res.messages,
        })),
      );
  }

  updateClassroomStudents(schoolId: string, classroomId: string, usersToUpdate: UserPartial[]): Observable<ApiResponse<Classroom>> {
    const url = `${this.getSchoolUrl(schoolId)}/classroom/${classroomId}/student`;
    const data = usersToUpdate.map(user => ({
      userId: user.userId ?? null,
      userName: user.username ?? null,
      firstName: user.firstName ?? null,
      lastName: user.lastName ?? null,
      externalId: user.externalId ?? null,
      password: user.password ?? null,
      confirmPassword: user.confirmPassword ?? null,
      willBeRemoved: false,
    }));
    const body = { classroomId, studentUsersToAssignToClassroom: data };
    return this.http.patch<ApiResponse<IClassroom>>(url, body)
      .pipe(
        map(res => new ApiResponse<Classroom>(true, {
          response: copyObject(res.response, Classroom),
          messages: res.messages,
        })),
      );
  }

  updateClassroomTeachers(schoolId: string, classroomId: string, data: UserPartial[]): Observable<ApiResponse<Classroom>> {
    const url = `${this.getSchoolUrl(schoolId)}/classroom/${classroomId}/teacher`;
    const body = { classroomId, teacherUsersToAssignToClassroom: data };
    return this.http.patch<ApiResponse<IClassroom>>(url, body)
      .pipe(
        map(res => new ApiResponse<Classroom>(true, {
          response: copyObject(res.response, Classroom),
          messages: res.messages,
        })),
      );
  }

  deleteClassroom(classroomId: string): Observable<ApiResponse<boolean>> {
    return this.http.request('delete', this.getClassroomUrl(classroomId))
      .pipe(
        map(() => new ApiResponse<boolean>(true, { response: true, messages: [] })),
      );
  }

  createNewStudentPasswords(schoolId: string): Observable<ApiResponse<Blob>> {
    return this.http.patch(`${this.getSchoolUrl(schoolId)}/student-password`, {}, { responseType: 'blob' })
      .pipe(
        map(response => new ApiResponse<Blob>(true, { response, messages: [] })),
      );
  }

  uploadImportFile(zbNum: string, formData: FormData, classroomId: string = null): Observable<ApiResponse<boolean>> {
    const headers = new HttpHeaders({ 'Content-Type': 'multipart/form-data' });
    return this.http.post(this.getSchoolImportUrl(zbNum, classroomId), formData, { headers, responseType: 'json' })
      .pipe(
        map(res => new ApiResponse<boolean>(true, res ? { ...res } : { response: null, messages: [] })),
      );
  }

  getImportTemplate(filename): Observable<ApiResponse<Blob>> {
    return this.http.get(this.getImportTemplateUrl(filename), { responseType: 'blob' })
      .pipe(
        map(response => new ApiResponse<Blob>(true, { response, messages: [] })),
      );
  }

  exportRosterFile(zbNum: string, fileName: string): Observable<ApiResponse<Blob>> {
    return this.http.get(this.getSchoolExportUrl(zbNum, fileName), { responseType: 'blob' })
      .pipe(
        map(response => new ApiResponse<Blob>(true, { response, messages: [] })),
      );
  }

  getUserSettings(educationalUnitId: string): Observable<ApiResponse<ISchoolSettings>> {
    return this.http.get(this.getSchoolSettingsUrl(educationalUnitId))
      .pipe(
        map(res => new ApiResponse<ISchoolSettings>(true, res)),
      );
  }

  updateUserSettings(educationalUnitId: string, settings: IUserSettings): Observable<ApiResponse<ISchoolSettings>> {
    return this.http.patch(this.getSchoolSettingsUrl(educationalUnitId), settings)
      .pipe(
        map(res => new ApiResponse<ISchoolSettings>(true, res)),
      );
  }

  getClassroomProductUsageReport(classroomId: string, productType: ProductType, variant: VariantType): Observable<Blob> {
    return this.http.get(this.getProductUsageReportUrl(classroomId, productType, variant), { responseType: 'blob' });
  }

  private validateParams(params: KeyValuePair[]) {
    return params.filter(p => p.value != null);
  }
}
