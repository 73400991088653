
<section
  *ngIf="roleType && initialConfigurationComplete && !isLoading; else preloader"
  class="user-form-container d-flex flex-column"
  [class]="containerClass"
  [formGroup]="form"
  tabindex="0"
  [class.edit-form-style]="isInEditMode">

  <!-- Password Requirement Tooltip List -->
  <ng-template #passwordToolTipContent>
    <zbp-password-requirements-tooltip></zbp-password-requirements-tooltip>
  </ng-template>

  <zbp-alert-bar-container *ngIf="alertBarText" [zbpIconName]="zbpIconNames.exclamation_in_circle" class="managed-by-institution-banner">
    <p class="alert-bar-text">{{alertBarText}}</p>
  </zbp-alert-bar-container>
  <div *ngIf="newUsers?.controls" formArrayName="newUsers"id="createSchoolUsersRows" class="w-100" [class.edit-form]="isInEditMode">
    <div *ngFor="let uf of newUsers.controls; index as i" [formGroupName]="i" class="newUserRow">
      <div  class="form-fields-container">
        <div class="newUserRowFormFields">
          <h5 *ngIf="isInEditMode" class="form-section-title">User Information</h5>

          <!-- First Name -->
          <div class="form-group form-input"
            [class.parent-row-input]="isManagingParent">
            <label class="form-label" for="newUser{{i}}FirstName"
              [class.error]="formControlInvalidAndTouched(i, formFieldNames.firstName.name)">
              {{formFieldNames.firstName.label}}
              <span aria-hidden="true">*</span>
            </label>
            <input id="newUser{{i}}FirstName" type="text" class="form-control" required [formControlName]="formFieldNames.firstName.name" [class.is-invalid]="formControlInvalidAndTouched(i, formFieldNames.firstName.name)" [ngbTooltip]="(usesOneRoster && !this.userCanBeEditedIfOneRoster) ? oneRosterTooltipText : ''">
            <div
              class="errorWrapper">
              <span *ngIf="formControlInvalidAndTouched(i, formFieldNames.firstName.name)" class="errorText error">{{nameFieldErrorText(i, formFieldNames.firstName.name)}}</span>
            </div>
          </div>

          <!-- Last Name -->
          <div class="form-group form-input" [class.parent-row-input]="isManagingParent">
            <label class="form-label" for="newUser{{i}}LastName" [class.error]="formControlInvalidAndTouched(i, formFieldNames.lastName.name)">
              {{formFieldNames.lastName.label}}
              <span aria-hidden="true">*</span>
            </label>
            <input id="newUser{{i}}LastName" type="text" class="form-control" required [formControlName]="formFieldNames.lastName.name" [class.is-invalid]="formControlInvalidAndTouched(i, formFieldNames.lastName.name)" [ngbTooltip]="(usesOneRoster && !this.userCanBeEditedIfOneRoster) ? oneRosterTooltipText : ''">
            <div
              class="errorWrapper"
            >
              <span *ngIf="formControlInvalidAndTouched(i, formFieldNames.lastName.name)" class="errorText error">{{nameFieldErrorText(i, formFieldNames.lastName.name)}}</span>
            </div>
          </div>

          <!-- Non-student Username Fields -->
          <div *ngIf="!isManagingStudent" class="form-group form-input" [class.lastInputGroup]="!uf.get(formFieldNames.password.name)" [class.full-width]="isInEditMode && (isManagingSchoolAdmin || isManagingDistrictAdmin || isManagingTeacher || isManagingParent)"  [class.parent-row-input]="isManagingParent">
            <label class="form-label" for="newUser{{i}}UserName" [class.error]="formControlInvalidAndTouched(i, formFieldNames.username.name)">
              {{formFieldNames.username.label}}
              <span aria-hidden="true">*</span>
              <i class="fa-regular fa-question-circle" aria-hidden="true" [ngbTooltip]="'Provide the Email with which the user will log in.'" container="body"></i>
            </label>
            <input id="newUser{{i}}UserName" type="email" class="form-control" required [formControlName]="formFieldNames.username.name" [class.is-invalid]="formControlInvalidAndTouched(i, formFieldNames.username.name)" [ngbTooltip]="(usesOneRoster && !this.userCanBeEditedIfOneRoster) ? oneRosterTooltipText : ''">
            <div
              class="errorWrapper">
              <span *ngIf="formControlInvalidAndTouched(i, formFieldNames.username.name)" class="errorText error">{{nameFieldErrorText(i, formFieldNames.username.name)}}</span>
            </div>
          </div>

          <!-- External ID -->
          <div *ngIf="isManagingStudent" class="form-group form-input">
            <label class="form-label" for="newUser{{i}}ExternalId">
              External ID
              <button tabindex="-1"
                id="newUser{{i}}ExternalIdTooltipButton"
                type="button"
                class="btn question-button-icon"
                aria-label="Topic locking help"
                ngbPopover="Optionally provide the system identifier for this student from your Student Information System or Learning Management System."
                container="body"
                placement="top">
                <i class="fa-regular fa-question-circle" aria-hidden="true"></i>
              </button>
            </label>
            <input
              id="newUser{{i}}ExternalId"
              type="text"
              class="form-control no-error-placeholder"
              [ngbTooltip]="(usesOneRoster && !this.userCanBeEditedIfOneRoster) ? oneRosterTooltipText : ''"
              formControlName="externalId">
          </div>

          <!-- Student Search for Parents in Edit Mode -->
          <div *ngIf="isManagingParent && !isInEditMode" class="form-group form-input lastInputGroup parent">
            <label for="studentUsername{{ i }}" class="form-label" [class.error]="studentFormControlInvalid(getStudentFormControl(i))">
              Student
              <span aria-hidden="true">*</span>
            </label>
            <zbp-user-search-with-dropdown
              [i]="i"
              [school]="school"
              [role]="RoleType.Student"
              [control]="getStudentFormControl(i)"
              [isInvalid]="studentFormControlInvalid"
            >
            </zbp-user-search-with-dropdown>
            <div class="errorWrapper">
              <span *ngIf="studentFormControlInvalid(getStudentFormControl(i))" class="errorText error">{{nameFieldErrorText(i, formFieldNames.student.name)}}</span>
            </div>
          </div>

          <!-- Student Username Field -->
          <div *ngIf="isManagingStudent" class="form-group form-input">
            <label class="form-label" for="newUser{{i}}UserName" [class.error]="formControlInvalidAndTouched(i, formFieldNames.username.name)">
              {{formFieldNames.username.label}}
              <span aria-hidden="true">*</span>
              <button tabindex="-1"
                id="newUser{{i}}UserNameTooltipButton"
                type="button"
                class="btn question-button-icon"
                ngbPopover="Provide the user name with which the user will log in."
                container="body"
                placement="top-left">
                <i class="fa-regular fa-question-circle" aria-hidden="true"></i>
              </button>
            </label>
            <input id="newUser{{i}}UserName" type="text" autocomplete="username" class="form-control" [formControlName]="formFieldNames.username.name" [class.is-invalid]="formControlInvalidAndTouched(i, formFieldNames.username.name)" [ngbTooltip]="(usesOneRoster && !this.userCanBeEditedIfOneRoster) ? oneRosterTooltipText : ''">
            <div class="errorWrapper">
              <span *ngIf="formControlInvalidAndTouched(i, formFieldNames.username.name)" class="errorText error">{{nameFieldErrorText(i, formFieldNames.username.name)}}</span>
            </div>
          </div>

          <!-- School dropdown -->
          <ng-container *ngIf="showSchoolDropdown">
            <div class="form-select form-control user-form-dropdown">
              <label class="form-label" for="userFormSchoolDropdown">School</label>
              <select
                id="userFormSchoolDropdown"
                class="form-control no-error-placeholder school-dropdown"
                formControlName="schoolId"
                (ngModelChange)="onSchoolSelected($event, i)"
                [ngbTooltip]="!uf.get('schoolId')?.disabled ? 'Students can only be in one school at a time. Use this dropdown to transfer this student to another school within your district.' : oneRosterTooltipText"
                container="body">
                <option *ngFor="let school of schools" [ngValue]="school.schoolId">{{ school?.name }}</option>
              </select>
            </div>
          </ng-container>

          <!-- Password Fields -->
          <ng-container *ngIf="uf.get(formFieldNames.password.name)">
            <h5 *ngIf="isInEditMode" class="form-section-title">Change Password</h5>
            <div class="form-group form-input edit-password-fields" [class.half-width]="isManagingTeacher">
              <label class="form-label" for="newUser{{i}}Password" [class.error]="formControlInvalidAndTouched(i, formFieldNames.password.name)">{{formFieldNames.password.label}}</label>
              <input
                id="newUser{{i}}Password"
                type="password"
                class="form-control"
                autocomplete="new-password"
                [formControlName]="formFieldNames.password.name"
                [class.is-invalid]="formControlInvalidAndTouched(i, formFieldNames.password.name)"
                placement="bottom"
                tooltipClass="password-requirements-tooltip"
                #passwordTooltip="ngbTooltip"
                (input)="hideTooltip(passwordTooltip)"
                [ngbTooltip]="passwordToolTipContent">
                <div class="errorWrapper">
                  <span *ngIf="formControlInvalidAndTouched(i, formFieldNames.password.name)" class="errorText error">Password Does Not Meet the Requirements</span>
                </div>
            </div>
            <div class="form-group form-input edit-password-fields" [class.half-width]="isManagingTeacher">
              <label class="form-label form-label--confirmPassword" for="newUser{{i}}ConfirmPassword" [class.error]="confirmPasswordInvalid(i)">{{formFieldNames.confirmPassword.label}}</label>
              <input
                id="newUser{{i}}ConfirmPassword"
                type="password"
                class="form-control"
                autocomplete="new-password"
                [formControlName]="formFieldNames.confirmPassword.name"
                [class.is-invalid]="confirmPasswordInvalid(i)"
                placement="bottom"
                tooltipClass="password-requirements-tooltip"
                #confirmPasswordTooltip="ngbTooltip"
                (input)="hideTooltip(confirmPasswordTooltip)"
                [ngbTooltip]="passwordToolTipContent">
                <div class="errorWrapper">
                  <span *ngIf="confirmPasswordInvalid(i)" class="errorText error">{{confirmPasswordFieldErrorText(i)}}</span>
                </div>
            </div>
          </ng-container>

          <!-- Parent Fields for New Student parent fields -->
          <ng-container *ngIf="!isInEditMode && hasParentFields">
            <div class="form-group form-input">
              <label class="form-label" for="newUser{{i}}ParentFirstName" [class.error]="formControlInvalid(i, formFieldNames.parentFirstName.name)">Parent First Name</label>
              <input id="newUser{{i}}ParentFirstName" type="text" class="form-control no-error-placeholder" [formControlName]="formFieldNames.parentFirstName.name" [class.is-invalid]="formControlInvalid(i, formFieldNames.parentFirstName.name)">
              <div class="errorWrapper">
                <span *ngIf="formControlInvalid(i, formFieldNames.parentFirstName.name)" class="errorText error">{{nameFieldErrorText(i, formFieldNames.parentFirstName.name)}}</span>
              </div>
            </div>
            <div class="form-group form-input">
              <label class="form-label" for="newUser{{i}}ParentLastName" [class.error]="formControlInvalid(i, formFieldNames.parentLastName.name)">Parent Last Name</label>
              <input id="newUser{{i}}ParentLastName" type="text" class="form-control no-error-placeholder" [formControlName]="formFieldNames.parentLastName.name" [class.is-invalid]="formControlInvalid(i, formFieldNames.parentLastName.name)">
              <div class="errorWrapper">
                <span *ngIf="formControlInvalid(i, formFieldNames.parentLastName.name)" class="errorText error">{{nameFieldErrorText(i, formFieldNames.parentLastName.name)}}</span>
              </div>
            </div>
            <div class="form-group form-input lastInputGroup">
              <label class="form-label" for="newUser{{i}}ParentUserName" [class.error]="formControlInvalid(i, formFieldNames.parentUserName.name)">Parent Email</label>
              <input id="newUser{{i}}ParentUserName" type="email" class="form-control no-error-placeholder" [formControlName]="formFieldNames.parentUserName.name" [class.is-invalid]="formControlInvalid(i, formFieldNames.parentUserName.name)">
              <div class="errorWrapper">
                <span *ngIf="formControlInvalid(i, formFieldNames.parentUserName.name)" class="errorText error">{{nameFieldErrorText(i, formFieldNames.parentUserName.name)}}</span>
              </div>
            </div>
          </ng-container>

          <div class="form-row lists-section">
            <!-- List of Students for Parent in Edit Mode -->
            <div *ngIf="showStudentsList && isInEditMode" class="link-list-container" [class.half-width]="showClassroomList">
              <h5 class="text-uppercase form-section-title black-font-title">Students</h5>
              <ul class="links-list">
                <li *ngFor="let student of getStudentsArray(uf)?.controls; index as studentIndex" class="links-list-item">
                  <zbp-button-component
                    [theme]="'primaryTextLink'"
                    [label]="student.get('displayName')?.value"
                    class="classroom-link-button-container"
                    [customClass]="'classroom-link-button'"
                    (click)="openEditStudentModal($event, student.get('associatedUserId')?.value)">
                  </zbp-button-component>

                  <zbp-button-component
                    class="delete-student-from-parent"
                    [iconName]="'x_cross_circle'"
                    (clickHandler)="removeStudentFromParentClickHandler(i, studentIndex, student.get('displayName')?.value)"
                    [tooltip]="'Remove student from parent'"
                    [ariaLabel]="'Remove student from'"
                    theme="icon"
                    fontSizeUnit="rem"
                  ></zbp-button-component>
                </li>
              </ul>
            </div>

            <!-- Lists of Classroom links in Edit Mode -->
            <div *ngIf="showClassroomList && isInEditMode" class="link-list-container" [class.half-width]="showStudentsList">
              <h5 class="text-uppercase form-section-title black-font-title compact-section-title">Classrooms</h5>
              <zbp-button-component
                *ngFor="let c of user.classrooms"
                [theme]="'primaryTextLink'"
                [label]="c.name"
                class="classroom-link-button-container"
                [customClass]="'classroom-link-button'"
                (click)="goToClassroom($event, c)">
              </zbp-button-component>
            </div>
          </div>

        </div>

        <!-- Parent Lists for Students in Edit Mode -->
        <ng-container *ngIf="hasParentFields && isInEditMode">
            <ul *ngFor="let parent of getParentsArray(uf)?.controls; index as parentIndex" id="editParentFields{{parentIndex}}" [formArrayName]="'userAssociations'" class="form-row parent-fields-list">
              <li class="parent-list-item" [formGroupName]="parentIndex">
                <!-- Parent first name -->
                <div class="form-group form-input">
                  <label for="newUser{{i}}{{parentIndex}}ParentFirstName" class="form-label">
                    {{formFieldNames.parentFirstName.label}}
                    <span aria-hidden="true">*</span>
                  </label>
                  <input id="newUser{{i}}{{parentIndex}}ParentFirstName" type="text" class="form-control no-error-placeholder" [formControlName]="formFieldNames.parentFirstName.name" [class.is-invalid]="parentFormControlInvalidAndTouched(parentIndex, i, formFieldNames.parentFirstName.name)">
                  <div
                    class="errorWrapper">
                    <span *ngIf="parentFormControlInvalidAndTouched(parentIndex, i, formFieldNames.parentFirstName.name)" class="errorText error">{{parentNameFieldErrorText(parentIndex, i, formFieldNames.parentFirstName.name)}}</span>
                  </div>
                </div>
                <!-- Parent last name -->
                <div class="form-group form-input">
                  <label for="newUser{{i}}{{parentIndex}}ParentLastName" class="form-label">
                    {{formFieldNames.parentLastName.label}}
                    <span aria-hidden="true">*</span>
                  </label>
                  <input id="newUser{{i}}{{parentIndex}}ParentLastName" type="text" class="form-control no-error-placeholder" [formControlName]="formFieldNames.parentLastName.name" [class.is-invalid]="parentFormControlInvalidAndTouched(parentIndex, i, formFieldNames.parentLastName.name)">
                  <div
                    class="errorWrapper">
                    <span *ngIf="parentFormControlInvalidAndTouched(parentIndex, i, formFieldNames.parentLastName.name)" class="errorText error">{{parentNameFieldErrorText(parentIndex, i, formFieldNames.parentLastName.name)}}{{nameFieldErrorText(i, formFieldNames.parentLastName.name)}}</span>
                  </div>
                </div>
                <!-- Parent username -->
                <div class="form-group form-input">
                  <label for="newUser{{i}}{{parentIndex}}ParentUserName" class="form-label">
                    {{formFieldNames.parentUserName.label}}
                    <span aria-hidden="true">*</span>
                  </label>
                  <input id="newUser{{i}}{{parentIndex}}parentUserName" type="text" class="form-control no-error-placeholder" [formControlName]="formFieldNames.parentUserName.name" [class.is-invalid]="parentFormControlInvalidAndTouched(parentIndex, i, formFieldNames.parentUserName.name)">
                  <div
                    class="errorWrapper">
                    <span *ngIf="parentFormControlInvalidAndTouched(parentIndex, i, formFieldNames.parentUserName.name)" class="errorText error">{{parentNameFieldErrorText(parentIndex, i, formFieldNames.parentUserName.name)}}{{nameFieldErrorText(i, formFieldNames.parentUserName.name)}}</span>
                  </div>
                </div>
                <div class="parent-row-icons">
                  <div class="parent-login-indicator">
                    <i *ngIf="parent.get('isSetup')?.value" class="fa fa-check-circle green-icon parent-indicator" aria-label="Parent setup" [ngbTooltip]="parentSetupToolTip" container="body"></i>
                    <i *ngIf="!parent.get('isSetup')?.value" class="fa fa-circle red-icon parent-indicator" aria-label="Parent not setup" [ngbTooltip]="parentNotSetupToolTip" container="body"></i>
                </div>
                <ng-template #parentSetupToolTip>
                  <span>This parent has set up their ZB Portal account and is able to log in.</span>
                </ng-template>
                <ng-template #parentNotSetupToolTip>
                  <span>This parent has not set up their ZB Portal account yet.</span>
                </ng-template>
                <zbp-button-component
                  class="delete-parent-row"
                  [iconName]="'x_cross_circle'"
                  (clickHandler)="removeParentFromFormClickHandler(i, parentIndex)"
                  [tooltip]="'Remove parent from student'"
                  [ariaLabel]="'Remove parent from student'"
                  theme="icon"
                  iconSize="font-size"
                  fontSizeUnit="rem"
                ></zbp-button-component>
                </div>
              </li>
            </ul>
        </ng-container>

        <!-- Additional Student Controls -->
        <div *ngIf="isManagingStudent" class="additional-student-controls">
          <!-- Students can add multiple parents-->
          <zbp-button-component
            *ngIf="hasParentFields && isInEditMode"
            [iconName]="zbpIconNames.t_cross"
            iconSize="font-size"
            theme="primaryText"
            [label]="addParentButtonText"
            iconPosition="left"
            class="addAnotherButton"
            [disabled]="isAddAnotherParentDisabled(i)"
            [ngbTooltip]="isAddAnotherParentDisabled(i) ? 'Can only add 4 Parents to a Student.' : ''"
            (clickHandler)="addAnotherParentClickHandler(i)"
          >
          </zbp-button-component>

          <!-- Students can search parents -->
          <div *ngIf="isInEditMode" class="form-group form-input userSearch parent-search-box">
            <label class="orSeparator" id="parent-search-dropdown-label">OR</label>
            <zbp-user-search-with-dropdown
              id="parent-search-dropdown"
              aria-labelledby="parent-search-dropdown-label"
              class="addSearchBox"
              [i]="0"
              [school]="school"
              [role]="RoleType.Parent"
              [control]="searchBox"
              [itemSelectionFunction]="addParentRowFromUserSearchToStudent"
              [pageSize]="10"
              [activeOnly]="true"
              [placeholderText]="'Search Existing Parents'"
              [ngbTooltip]="isAddAnotherParentDisabled(i) ? 'Can only add 4 Parents to a Student.' : ''"
              container="body"
              [isDisabled]="isAddAnotherParentDisabled(i)"
            >
            </zbp-user-search-with-dropdown>
          </div>
        </div>
      </div>

      <button *ngIf="!hideFirstRowDeleteButton ? true : i !== 0" id="removeRow{{i}}" class="btn btn-sm removeButton text-end remove-button-container" type="button" [attr.aria-label]="'Remove User'" (click)="removeRow(i)">
        <i class="fa-regular fa-circle-xmark"></i>
      </button>
    </div>
    <div class="add-additional-user-controls">
      <!-- Add New Role Type being added -->
      <div *ngIf="canAddMultipleUsers" class="userSearch">
        <zbp-button-component
          *ngIf="canAddMultipleUsers"
          [iconName]="zbpIconNames.t_cross"
          iconSize="font-size"
          theme="primaryText"
          [label]="addAnotherUserLabel"
          iconPosition="left"
          class="addAnotherButton"
          (clickHandler)="addAnother()"
          [disabled]="newUsers?.length >= maxRequired"
        >
        </zbp-button-component>
      </div>

      <!-- Search for user for Role Type being added -->
      <div *ngIf="canUserSearch" class="userSearch">
        <div class="orSeparator">OR</div>
        <zbp-user-search-with-dropdown
          class="addSearchBox"
          [i]="0"
          [school]="school"
          [role]="roleType"
          [control]="searchBox"
          [itemSelectionFunction]="userSearchSelectionFunction"
          [pageSize]="10"
          [activeOnly]="true"
          [placeholderText]="'Search Existing'"
          [isDisabled]="newUsers?.length >= maxRequired"
        >
        </zbp-user-search-with-dropdown>
      </div>
    </div>
    <div *ngIf="!isInEditMode" class="text-uppercase requiredText">* Required</div>
  </div>
</section>

<ng-template #preloader>
  <div class="preloader spinner">
    <zbp-preloader></zbp-preloader>
  </div>
</ng-template>
