import { NavigationMenuItem } from '@models/navigation-menu-item';
import { RoleType } from '@shared/enums/role-type';
import { hlContactUsMenu, zbContactUsMenu } from './contact-us-menu';

export const TEACHERS_PRODUCTS_MENU_ID = 'products';
export const TEACHERS_CLASSES_MENU_ID = 'classes';

export const teacherHLMainMenus: NavigationMenuItem[] = [
  hlContactUsMenu,
  {
    id: TEACHERS_PRODUCTS_MENU_ID,
    label: 'My Products',
    isRouted: false,
    isExternal: false,
    weight: -10,
  },
];

/**
 * HL Portal currently does not support Classes.
 *
 * Logic currently static until Classes are supported for HLPortal.
 */
export const teacherZBMainMenus: NavigationMenuItem[] = [
  zbContactUsMenu,
  {
    id: TEACHERS_PRODUCTS_MENU_ID,
    label: 'My Products',
    isRouted: false,
    isExternal: false,
    weight: -10,
  },
  {
    id: TEACHERS_CLASSES_MENU_ID,
    label: 'Classes',
    isRouted: false,
    isExternal: false,
    weight: -9,
  },
];

/**
 * Primary Menu Items when Teacher Role.

 * Classes and Reports are dynamic based off of class licenses.
 *
 * The label should have the first letter of the first word capitalized.
 */
export function buildTeacherZBRedesignMenus(showReportsAndClasses: boolean): NavigationMenuItem[] {
  const  primaryMenus: NavigationMenuItem[] = [];

  if (showReportsAndClasses) {
    primaryMenus.push(
      {
        id: 'reports',
        label: 'Reports',
        route: '/learning/reports',
        isRouted: true,
        isExternal: false,
        weight: 2,
        roleType: RoleType.Teacher,
      }
    );
  }

  primaryMenus.push(
    ...teacherZBMainMenus
  );

  return primaryMenus;
}
