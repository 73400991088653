export const uploadIcon = `
<svg xmlns="http://www.w3.org/2000/svg" width="16" height="23" viewBox="0 0 16 23"
     fill="none">
  <path
    fill-rule="evenodd"
    clip-rule="evenodd"
    d="M2.86263 8.70269C2.53345 8.70269 2.33703 8.3359 2.51949 8.06191L7.08795 1.20175C7.25111 0.956749 7.61107 0.956749 7.77423 1.20175L12.3427 8.06191C12.5252 8.3359 12.3287 8.70268 11.9996 8.70268L8.76798 8.70269C8.54029 8.70269 8.35572 8.88726 8.35572 9.11495L8.35572 16.7189C8.35572 17.1616 7.94175 17.5205 7.43109 17.5205C6.92044 17.5205 6.50647 17.1616 6.50647 16.7189L6.50647 9.11495C6.50647 8.88726 6.32189 8.70269 6.09421 8.70269L2.86263 8.70269Z"
    fill="var(--zbp-svg-primary-color, black)"
  />
  <path
    d="M4.02007 11.5205H1.8826C1.3911 11.5205 0.993939 11.9214 0.99848 12.4128L1.07264 20.4383C1.07713 20.9234 1.47164 21.3142 1.95676 21.3142H13.6254C14.1137 21.3142 14.5095 20.9184 14.5095 20.4301V12.4047C14.5095 11.9164 14.1137 11.5205 13.6254 11.5205H11.2619"
    stroke="var(--zbp-svg-primary-color, black)"
    stroke-width="1.58217"
  />
</svg>
`;
