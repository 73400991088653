<form id="manageUsersLicensePopoverForm"
      [formGroup]="form"
      (ngSubmit)="updateLicenses()">
  <zbp-modal-template
    [modalHeader]="modalHeader"
    [bodyContent]="body"
    [footerContent]="footer"
    [showCloseX]="true"
    [isScrollable]="true">

    <ng-template #body>
      <zbp-add-licenses-form
        [school]="school"
        [assignmentType]="assignmentType"
        [assignedId]="assignedId"
        [showAlreadyAssigned]="true"
      ></zbp-add-licenses-form>
    </ng-template>

    <ng-template #footer>
      <zbp-button-component
        theme="ghost"
        (click)="dismiss()"
        label="Cancel"
        class="text-uppercase">
      </zbp-button-component>
      <zbp-button-component
        theme="primary"
        label="Save"
        type="submit"
        class="text-uppercase"
        [showSpinner]="loading">
      </zbp-button-component>
    </ng-template>

  </zbp-modal-template>
</form>
