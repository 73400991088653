import { Component, OnDestroy } from '@angular/core';
import { FormArray, FormGroup } from '@angular/forms';
import { AddClassLicenseAssignee, LicenseAssignee } from '@models/license/license-assignee';
import { School } from '@models/school';
import { NgbActiveModal, NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import {
  newLicensesFormArrayName,
  newLicensesFormGroupName
} from '@shared/add-licenses-form/helpers/form-field-setup-data';
import { LicenseAssignmentType, LicenseStatus } from '@shared/enums/license-status';
import { ZbpDataTable } from '@shared/zbp-data-table/zbp-data-table.model';
import _ from 'lodash';
import { finalize, Subscription, tap } from 'rxjs';
import {
  BrandedLicensesModalParams
} from 'src/app/schools/school/license-modal/branded-license-modal/branded-licenses-modal-params';
import { SchoolsService } from '../../../schools.service';

@Component({
  selector: 'zbp-branded-license-modal',
  templateUrl: './branded-license-modal.component.html',
})
export class BrandedLicenseModalComponent implements OnDestroy {

  protected readonly ZbpDataTable = ZbpDataTable;

  readonly form: FormGroup = new FormGroup({});

  school: School;
  assignedId: string;
  assignmentType: LicenseAssignmentType = LicenseAssignmentType.Teacher;
  loading = false;
  isAddLicense: boolean = false;
  licenses: AddClassLicenseAssignee[];

  private subscriptions: Subscription[] = [];

  constructor(
    private modal: NgbActiveModal,
    private schoolsService: SchoolsService
  ) {
  }

  static open(ngbModal: NgbModal, params: BrandedLicensesModalParams): NgbModalRef {
    const modalRef = ngbModal.open(
      BrandedLicenseModalComponent,
      {
        size: 'lg', // default
        ...params?.options, // override from params
      }
    );
    const component = modalRef.componentInstance;
    component.school = params.school;
    component.assignedId = params.assignedId;
    component.assignmentType = params.assignmentType;
    return modalRef;
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(subscription => subscription.unsubscribe());
  }

  get modalHeader(): string {
    if (this.assignmentType) {
      return `Edit ${this.assignmentType} Licenses`;
    }
    return 'Edit Licenses';
  }

  get licensesForm() {
    return this.form.get(newLicensesFormGroupName)
      ?.get(newLicensesFormArrayName) as FormArray;
  }

  updateLicenses(): void {
    const { licensesForm } = this;
    if (!this.isAddLicense) {
      const classroomId = this.assignmentType === LicenseAssignmentType.Classroom ? this.assignedId : null;
      const userId = !classroomId ? this.assignedId : null;
      const licenses: LicenseAssignee[] = licensesForm.value.map((values) => {
        const assignee = _.pick<LicenseAssignee>(values, ['skuId', 'isAssigned']);
        assignee.assignedId = this.assignedId;
        return assignee;
      });

      this.loading = true;

      this.subscriptions.push(
        this.schoolsService.updateSchoolLicenseAssignments(
          this.school.schoolId,
          licenses,
          userId,
          classroomId,
          this.assignmentType)
          .pipe(
            tap((res) => {
              if (res?.success) {
                const assignedCount = res.response
                  .reduce((count, value) => (value.status === LicenseStatus.AssignedToYou ? count + 1 : count), 0);
                this.modal.close(assignedCount);
              } else {
                this.modal.dismiss(res.messages[0]);
              }
            }),
            finalize(() => {
              this.loading = false;
            })
          ).subscribe(),
      );
    } else {
      this.licenses = licensesForm.value
        .map(values => _.pick<AddClassLicenseAssignee>(values, ['skuId', 'isAssigned', 'description', 'amount'])
        );
      this.modal.close(this.licenses);
    }
  }

  dismiss(): void {
    this.modal.dismiss(null);
  }

}
