export const backArrowIcon = `
<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"
     fill="inherit">
  <path fill-rule="evenodd"
        clip-rule="evenodd"
        d="M0.270363 12.0863C-0.090121 11.7258 -0.090121 11.1413 0.270363 10.7808L6.14479 4.90641C6.50527 4.54593 7.08973 4.54593 7.45022 4.90641C7.8107 5.26689 7.8107 5.85135 7.45022 6.21184L3.15158 10.5105H23.0769C23.5867 10.5105 24 10.9237 24 11.4336C24 11.9434 23.5867 12.3566 23.0769 12.3566H3.15158L7.45022 16.6553C7.8107 17.0157 7.8107 17.6002 7.45022 17.9607C7.08973 18.3212 6.50527 18.3212 6.14479 17.9607L0.270363 12.0863Z"
        fill="var(--zbp-svg-primary-color, black)"
  />
</svg>
`;
