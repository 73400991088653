import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AppRoutingService } from '@core/app-routing.service';
import { AppConfigService } from '@core/appconfig.service';

import { AuthenticationService } from '@core/authentication.service';
import { CacheService } from '@core/cache.service';
import { resetStore } from '@core/store';
import { CoreFacade } from '@core/store/facade';
import { UserService } from '@core/user.service';
import { from, Subscription } from 'rxjs';
import { mergeMap, skipWhile } from 'rxjs/operators';

@Component({
  selector: 'zbp-logout',
  templateUrl: './logout.component.html',
  styleUrls: ['./logout.component.scss'],
})
export class LogoutComponent implements OnDestroy, OnInit {
  private authSubscription: Subscription;

  constructor(
    private router: Router,
    private authService: AuthenticationService,
    private userService: UserService,
    private appConfig: AppConfigService,
    private coreFacade: CoreFacade,
    private cache: CacheService,
    private appRoutingService: AppRoutingService,
  ) { }

  ngOnInit(): void {
    this.authSubscription = this.authService.authStatus
      .pipe(
        skipWhile(status => status),
        mergeMap(() => {
          this.cache.clearAll();
          const route = this.appRoutingService.findLoginRoute();
          this.coreFacade.dispatch(resetStore());
          return from(this.router.navigateByUrl(route));
        })
      )
      .subscribe();

    this.authService.logout();
  }

  ngOnDestroy(): void {
    if (this.authSubscription) {
      this.authSubscription.unsubscribe();
    }
  }
}
