<nav *ngIf="(menuSubscription | async)?.length > 0" role="navigation" aria-label="subnavigation menu">
  <ul class="nav subnav-tabs">
    <li *ngFor="let menuItem of menuSubscription | async "  class="nav-item mb-0 nav-link subnav-link">
      <a
        [routerLink]="menuItem.route"
        routerLinkActive="active"
        [routerLinkActiveOptions]="{exact: false}"
        queryParamsHandling="merge">
        {{menuItem.label}}
      </a>
  </li>
  </ul>
</nav>