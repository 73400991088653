import { CommonModule, NgFor } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Params, Router, RouterModule } from '@angular/router';
import { AppConfigService } from '@core/appconfig.service';
import { AccessControlService } from '@core/services/access-control/access-control.service';
import { District } from '@models/district';
import { School } from '@models/school';
import { AlertBarContainerComponent } from '@shared/alert-bar-container/alert-bar-container.component';
import { AccessDeniedReason } from '@shared/enums/access-denied-reason';
import { supportLinks } from '@shared/enums/support-links';
import { PreloaderModule } from '@shared/preloader/preloader.module';
import { UIHelpers } from '@shared/uihelpers';
import { MomentInput } from 'moment';
import { map, skipWhile, Subscription, tap } from 'rxjs';
import { SchoolsFacade } from '../../schools/store/schools.facade';

@Component({
  standalone: true,
  selector: 'zbp-subscription-information',
  templateUrl: './subscription-information.component.html',
  styleUrls: ['./subscription-information.component.scss'],
  imports: [
    CommonModule,
    RouterModule,
    PreloaderModule,
    AlertBarContainerComponent,
    NgFor
  ]
})
export class SubscriptionInformationComponent implements OnInit  {
  isLoading: boolean = true;
  accessDeniedReason: AccessDeniedReason = null;
  district: District = null;
  school: School = null;
  schoolId: string = null;
  accessDeniedReasons = AccessDeniedReason;
  techSupportLink = supportLinks.zbportalTechSupportInquiryForm;
  futureDate: MomentInput = null;
  machineReadableFutureDate: MomentInput = null;
  isHighlightsPortal: boolean = false;
  brandPortalText: string = 'My ZB Portal';

  private subscriptions: Subscription[] = [];

  /**
   * Gets the School ID from the route and evaluates which reason should be shown using AccessControlService
   */
  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private schoolsFacade: SchoolsFacade,
    private accessControlService: AccessControlService,
    private appConfigService: AppConfigService,
  ) { }

  ngOnInit() {
    if (this.appConfigService.isHighlightsPortalUrl) {
      this.isHighlightsPortal = true;
      this.brandPortalText = 'My Highlights Portal';
    }

    this.subscriptions.push(
      this.route.params.pipe(
        skipWhile((params: Params) => !params?.schoolId),
        map((params: Params) => {
          this.schoolId = params.schoolId;
          this.setupSchoolRelatedData();
          return params;
        })).subscribe(),
    );
  }

  private setupSchoolRelatedData() {
    this.subscriptions.push(
      this.schoolsFacade.get(this.schoolId).pipe(
        tap((school: School) => {
          // Route back to landing page if there isn't a school for current user
          // e.g. Accessing a bookmarked link
          if (!school) {
            this.routeBackToLandingPage();
          }
        }),
        map((school: School) => {
          this.school  = school;
          this.evaluateSchool();

          // Route back to landing page if there is no access denied reason
          // e.g. License issue has been fixed by another admin & user refreshes
          if (!this.accessDeniedReason) {
            this.routeBackToLandingPage();
          }

          if (this.accessDeniedReason === AccessDeniedReason.OnlyFutureLicenses) {
            this.configureFutureDate();
          }

          this.isLoading = false;
        })
      ).subscribe()
    );
  }

  private routeBackToLandingPage() {
    this.router.navigate(['/'], { relativeTo: this.route });
  }

  private evaluateSchool() {
    this.accessDeniedReason = this.accessControlService.schoolLicenseAccessDeniedCheck(this.school);
  }

  private configureFutureDate(): void {
    this.futureDate = UIHelpers.getMMDDYYYYDateWithSlashes(this.school.schoolYearStartDate);
    this.machineReadableFutureDate = UIHelpers.getYYYYMMDDDateWithDashes(this.school.schoolYearStartDate);
  }
}
