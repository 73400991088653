<div id="informationPanelComponent">
  <figure class="text-center information-panel-figure-container">
    <img
      *ngIf="iconSrc"
      [src]="iconSrc"
      [alt]="iconAlt"
    />
    <zbp-icon
      *ngIf="zbpIconName"
      [iconName]="zbpIconName"
      hoveStyle="disabled"
    >
    </zbp-icon>
  </figure>
  <h1 class="information-panel-heading h3">{{ headingText }}</h1>
  <div *ngIf="bodyHTML" class="information-panel-body" [innerHTML]="bodyHTML"></div>
  <div class="row information-panel-button-container">


    <!-- Send again text & link button -->
    <p *ngIf="showSendAgainSection" class="send-again-container">
      <span>Didn't receive it?&nbsp;</span>
      <button
        type="button"
        class="btn btn-link send-again-button"
        (click)="handleSendAgain($event)">
        Send Again
      </button>
    </p>

    <!-- Back to login button -->
    <button
      *ngIf="showBackToLoginLinkButton"
      type="button"
      class="btn btn-link btn-sm mx-2 back-to-login"
      (click)="backToLogin()">
      <span class="back-arrow">&larr;</span> Back to Login
    </button>

    <!-- Styled Primary Button -->
    <zbp-button-component
      *ngIf="showPrimaryButton"
      iconPrimaryColor="white"
      [label]="primaryButtonLabel"
      [iconName]="primaryButtonIconName"
      [tooltip]="primaryButtonTooltip"
      [ariaLabel]="primaryButtonAriaLabel"
      (clickHandler)="handlePrimaryButtonClick($event)">
    </zbp-button-component>

  </div>
</div>
