import { FormArray, FormGroup, FormControl, AbstractControl } from '@angular/forms';
import { Classroom } from '@models/classroom';
import { FormFieldModel } from '@models/form-field';
import { IUser, IUserUpdate, UserPartial } from '@models/user';
import { NgbTypeaheadSelectItemEvent } from '@ng-bootstrap/ng-bootstrap';
import { RoleType } from '@shared/enums/role-type';
import {
  generalUserFields,
  studentUserFields
} from '@shared/manage-users/manage-users-form/constants/form-field-setup-data';
import { combineLatest, filter, tap } from 'rxjs';
import { getParentFieldsValidator } from './form-validators';
import { ParentRoleHelpers } from './parent-role-helpers';

/**
 * Helper Functions used when managing a Student user.
 */
export namespace StudentRoleHelpers {

  /** Used to provied realtime validation feedback on the form fields when adding a new parent to a Student. */
  export function subscribeToParentFieldValidation(formGroupWithRoleAndNewUsers: FormGroup, parentFirstNameControl: AbstractControl, parentLastNameControl: AbstractControl, parentUserNameControl: AbstractControl) {
    combineLatest([
      parentFirstNameControl?.valueChanges,
      parentLastNameControl?.valueChanges,
      parentUserNameControl?.valueChanges
    ]).pipe(
      filter(([parentFirstName, parentLastName, parentUsername]) => parentFirstName
        && parentLastName
        && parentUsername
      ),
      tap(() => {
        formGroupWithRoleAndNewUsers.updateValueAndValidity();
      })
    ).subscribe();
  }

  /**
   * Used in Edit Student Form
   * Adds parent row to student form. Can be used with or without a user.
   *
   * When using without a user, the form values will be initialized with falsey values.
   * When using with a user, the form values will be initialized with the user's values.
   *
   * When editing a student, multiple parents are added to the student.
   * The parents are connected to the student through the UserAssociations array on the existing student.
   *
   * If the parent is a new user, the user will also be added as a hidden user on the form.
   * The association with the student will be added in the UserAssociations array on the new parent user.
   *
   * @param {IUser | null} parentUser Parent that will be added to row, if defined.
   * @param indexOfStudentToAdd Index of user from array of new users. Will always be 0 when editing a single user.
   */
    export function addParentRowToEditStudentUserForm(
      parentUser: IUser,
      indexOfStudentToAdd: number,
      newUsersFormArray: FormArray,
      formFieldNames:{ [key: string]: FormFieldModel }
    ) {
      const userFormGroup = newUsersFormArray.at(indexOfStudentToAdd) as FormGroup;

      // Creates a parent and adds them as associations
      const isSetup = ParentRoleHelpers.parentIsSetup(parentUser);
      const parentFirstNameCtrl = new FormControl(parentUser?.firstName || null, []);
      const parentLastNameCtrl = new FormControl(parentUser?.lastName || null, []);
      const parentUserNameCtrl = new FormControl(parentUser?.username || null, []);

      const parentRow = new FormGroup({
        [formFieldNames.parentFirstName.name]: parentFirstNameCtrl,
        [formFieldNames.parentLastName.name]: parentLastNameCtrl,
        [formFieldNames.parentUserName.name]: parentUserNameCtrl,
        [formFieldNames.isSetup.name]: new FormControl(isSetup),
        [formFieldNames.parentUserId.name]: new FormControl(parentUser?.userId || null),
      });

      // Add validators for new parent group
      parentRow.addValidators(getParentFieldsValidator());

      // Whenever 1 of these fields is changed, all 3 need to run validation
      this.subscribeToParentFieldValidation(
        userFormGroup,
        parentFirstNameCtrl,
        parentLastNameCtrl,
        parentUserNameCtrl
      );

      if (parentUser?.userId) {
        parentRow.disable();
      }

      // Add new group to userAssociations in form for Student
      const associationsForUserFormArray = newUsersFormArray
        .at(indexOfStudentToAdd).get(formFieldNames.userAssociations.name) as FormArray;

      // Push form group into userAssociations FormArray
      associationsForUserFormArray.push(parentRow);
    }

  /**
   *
   * Adds fields and validators needed to manage a parent when adding a role that requires a parent
   *  * e.g. Students adding Parents
   */
  export function setupNewStudentWithParentFields(newUsersIndex: number, newUsersFormArray: FormArray,  formfieldNames:{ [key: string]: FormFieldModel }) {
    const userFormGroup = newUsersFormArray.at(newUsersIndex) as FormGroup;
    const parentFirstNameCtrl = new FormControl('', []);
    const parentLastNameCtrl = new FormControl('', []);
    const parentUserNameCtrl = new FormControl('', []);

    userFormGroup.addControl(formfieldNames.parentFirstName.name, parentFirstNameCtrl);
    userFormGroup.addControl(formfieldNames.parentLastName.name, parentLastNameCtrl);
    userFormGroup.addControl(formfieldNames.parentUserName.name,  parentUserNameCtrl);

    // Added at the form level for performance reasons
    userFormGroup.addValidators(getParentFieldsValidator());

    // Whenever 1 of these fields is changed, all 3 need to run validation
    this.subscribeToParentFieldValidation(
      userFormGroup,
      parentFirstNameCtrl,
      parentLastNameCtrl,
      parentUserNameCtrl
    );
  }

  /**
   * Removes a parent association from the student's UserAssociations list.
   *
   * Updates the userAssociationsToRemove list for the student.
   *
   * Form fields will be removed.
   *
   * @param userIndex Index of user from array of new users. Will always be 0 when editing a single user.
   * @param parentIndex Index of parent in the student's userAssociations list to remove.
   */
    export function removeParentAssociationsFromForm(
      userIndex: number,
      parentIndex: number,
      newUsersFormArray: FormArray,
      formFieldNames:{ [key: string]: FormFieldModel }
    ): void {
      const student = newUsersFormArray.at(userIndex) as FormGroup;
      const currentFormUserAssociations = student.get(formFieldNames.userAssociations.name) as FormArray;
      currentFormUserAssociations.removeAt(parentIndex);
    }


  export function addParentRowFromUserSearchToStudent(parentResultClickEvent, newUsersFormArray: FormArray, formFieldNames:{ [key: string]: FormFieldModel }) {
    const parentUser = parentResultClickEvent.item as IUser;
    const indexOfUser = parentResultClickEvent.indexOfUserInForm;

    this.addParentRowToEditStudentUserForm(
      parentUser,
      indexOfUser,
      newUsersFormArray,
      formFieldNames,
    );
  }

    /**
   * Fills in values to an existing student row.
   *
   * @param {number} index
   * @param {UserPartial} data
   */
    export function updateStudentRow(index: number, data: UserPartial, newUsersFormArray: FormArray): void {
      newUsersFormArray.at(index).patchValue({
        userId: data.userId,
        firstName: data.firstName,
        lastName: data.lastName,
        username: data.studentUserName,
        externalId: data.externalId || null,
        password: '',
        confirmPassword: '',
      });
    }

  /**
   * Add an existing student from typeahead selection.
   *
   * @param {NgbTypeaheadSelectItemEvent} event
   * Returns a warning message if the student already exists in the Classroom and does not add student
   */
  export function addExistingStudent(event: NgbTypeaheadSelectItemEvent, newUsersFormArray: FormArray, addAnotherUserFunction, formRowHasEmptyValuesFunc): string {
    const { item } = event;
    const notFound = newUsersFormArray.value.findIndex(u => u?.userId === item?.userId) === -1;
    let warningMessage: string = null;
    if (notFound) {
      const lastRowIndex = newUsersFormArray.length - 1;
      if (!(lastRowIndex < 0) && formRowHasEmptyValuesFunc(lastRowIndex, newUsersFormArray)) {
        // Updates existing empty row instead of adding a new one.
        this.updateStudentRow(lastRowIndex, {
          ...item,
          userName: item.username,
        } as UserPartial, newUsersFormArray);
      } else {
        addAnotherUserFunction();
        this.updateStudentRow(lastRowIndex + 1, {
          ...item,
          userName: item.username,
        } as UserPartial, newUsersFormArray);
      }
      newUsersFormArray
        .at(newUsersFormArray.length - 1)
        .get(generalUserFields.username.name)
        .markAsDirty();
      newUsersFormArray.updateValueAndValidity();
    } else {
      warningMessage = `${item?.username} is already a student in this classroom.`;
    }

    return warningMessage;
  }

  export function addParentAsUserAndToAssociations(parentIndex: number, studentFormGroup: FormGroup, schoolId: string, classrooms: Classroom[]): IUserUpdate {
    const currentAssociationsInForm = studentFormGroup.get(generalUserFields.userAssociations.name) as FormArray;
    const newParentFormGroup = currentAssociationsInForm.at(parentIndex) as FormGroup;
    const parentUser = ParentRoleHelpers.createHiddenParentUser(newParentFormGroup);

    // If an empty parent row is added, do not map that empty row
    if (parentUser.get(generalUserFields.firstName.name)?.value
      && parentUser.get(generalUserFields.lastName.name)?.value
      && parentUser.get(generalUserFields.username.name)?.value) {
      // make new parent for the association
      if (classrooms?.length > 0) {
        const roleForClassroomsToAdd = [];
        // make a parent role for each of the student's classrooms
        classrooms.forEach((classroom: Classroom) => {
          const parentRoleForClassroom =  new FormGroup({
            roleType: new FormControl(RoleType.Parent),
            classroomId: new FormControl(classroom.classroomId),
            educationalUnitId: new FormControl(schoolId),
          });

          roleForClassroomsToAdd.push(parentRoleForClassroom);
        });

        // Create a single parent, then add a role for each classroom
        parentUser.addControl(studentUserFields.rolesToAdd.name,  new FormArray(roleForClassroomsToAdd));

        // Make a user associationToAdd on parent
        const parentsAssociationToStudent = new FormGroup({
          associatedUserId: new FormControl(studentFormGroup.get(generalUserFields.userId.name)?.value),
        });

        const associationsFormArray =  new FormArray([parentsAssociationToStudent]);
        parentUser.addControl(generalUserFields.userAssociationsToAdd.name, associationsFormArray);
      } else {
        // Make a parent for a school with 0 classrooms
        const roleToAdd = new FormGroup({
          roleType: new FormControl(RoleType.Parent),
          classroomId: new FormControl(null),
          educationalUnitId: new FormControl(schoolId),
        });

        // Create a single parent, with a single role for 1 school with 0 classrooms
        parentUser.addControl(studentUserFields.rolesToAdd.name,  new FormArray([roleToAdd]));

        // Make an associationToAdd on parent
        const parentsAssociationToStudent = new FormGroup({
          associatedUserId: new FormControl(studentFormGroup.get(generalUserFields.userId.name)?.value),
        });
        parentUser
          .addControl(generalUserFields.userAssociationsToAdd.name, new FormArray([parentsAssociationToStudent]));
      }

      // Make a parent associationToAdd on student form
      const parentUserUpdate: IUserUpdate = {
        userId: newParentFormGroup.get(generalUserFields.parentUserId.name)?.value,
        firstName: newParentFormGroup.get(generalUserFields.parentFirstName.name)?.value,
        lastName: newParentFormGroup.get(generalUserFields.parentLastName.name)?.value,
        ...parentUser.value,
      };

      return parentUserUpdate;
    }

    return null;
  }

  /**
   * Removes a parent association from the student's UserAssociations list.
   *
   * Updates the userAssociationsToRemove list for the student.
   *
   * Form fields will be removed.
   *
   * @param userIndex Index of user from array of new users. Will always be 0 when editing a single user.
   * @param parentIndex Index of parent in the student's userAssociations list to remove.
   */
  export function removeParentAssociationFromStudent(originalUser: IUser, studentFormGroup: FormGroup, parentUserId: string): FormGroup {
    // Remove existing association from UserAssociations
    const existingParentAssociation = originalUser.userAssociations?.find(user => user.userId === parentUserId);
    const existingParentAssociationIndex =  originalUser
      .userAssociations?.findIndex(user => user.userId === parentUserId);
    originalUser.userAssociations?.splice(existingParentAssociationIndex, 1);

    // Add entry to UserAssociationsToRemove
    const newAssociationToRemove = new FormGroup({
      associatedUserId: new FormControl(existingParentAssociation.userId),
      associatedUserName: new FormControl(existingParentAssociation.username)
    });

    const associationsToRemoveFormArray = studentFormGroup
      .get(generalUserFields.userAssociationsToRemove.name) as FormArray;
    associationsToRemoveFormArray.push(newAssociationToRemove);

    return newAssociationToRemove;
  }
}
