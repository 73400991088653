import moment, { MomentInput } from 'moment';
import { BlockOptions } from './blocked.directive';
import { Helpers } from './helpers';

export namespace UIHelpers {

  // eslint-disable-next-line no-undef
  declare interface BlockableDocument extends JQueryStatic {
    blockUI(options?: BlockOptions): void;
    unblockUI(): void;
  }

  export function block(message: string = 'Loading...', css: string = null): void {
    ($ as BlockableDocument).blockUI({ message, css: Helpers.tryParseJSON(css) });
  }

  export function unblock(): void {
    ($ as BlockableDocument).unblockUI();
  }

  export function toggleSideMenu(sideMenuId: string): void {
    // Close any other drop downs, and open the current one.
    $('.sideMenu').each((i: number, el: HTMLElement) => {
      if (el.id === sideMenuId || el.classList.contains('sideMenuVisible')) {
        el.toggleAttribute('hidden');
        el.classList.toggle('sideMenuVisible');
      }
    });
  }

  export function closeSideMenu(event: any, followLink: boolean): boolean {
    $('.sideMenu').each((i: number, el: HTMLElement) => {
      el.toggleAttribute('hidden');
    });
    $('.sideMenuVisible').toggleClass('sideMenuVisible');

    return followLink;
  }

  export function handlePanelChanged(panelId, show, currentPaneId: string): string {
    if (show) {
      UIHelpers.toggleActive(currentPaneId, panelId);
      return panelId;
    }
    if (panelId === currentPaneId) {
      UIHelpers.toggleActive(currentPaneId);
      return '';
    }
    return null;
  }

  // Bootstrap deprecated active class on panel headers but we depend on this.
  export function toggleActive(prevId?: string, currentId?: string): void {
    if (prevId) {
      $(`#${prevId}-header`).toggleClass('active');
    }
    if (currentId) {
      $(`#${currentId}-header`).toggleClass('active');
    }
  }

  export function resetSideMenu(): void {
    $('header,main').trigger('click', () => {
      $('.primaryContainer').removeClass('showSideMenu');
    });
  }

  export function getSideMenuPrefix(panel: string = 'productsSideMenu'): string {
    let prefix = '';
    switch (panel) {
    case 'schoolsSideMenu':
      prefix = 'p_sch_';
      break;
    case 'districtsSideMenu':
      prefix = 'p_dis_';
      break;
    case 'childrenSideMenu':
      prefix = 'p_chi_';
      break;
    default:
      prefix = 'p_pl_';
    }
    return prefix;
  }

  export function isSideMenuClosed(id: string, activePaneId: string, panel: string = 'productsSideMenu'): boolean {
    const prefix = getSideMenuPrefix(panel);
    return activePaneId !== `${prefix}${id}`;
  }

  export function isSideMenuOpen(id: string | number, activePaneId: string, panel: string = 'productsSideMenu'): boolean {
    const prefix = getSideMenuPrefix(panel);
    return activePaneId === `${prefix}${id}`;
  }

  /** checks tinymce editor for the selection and toggles the style and returns the state for it. */
  export function toggleEditorSelection(styleName: string, editor: any, options?: any): boolean {
    // Only check for object properties that are in the allowed styles.
    const applied = editor.formatter.match(styleName, options);
    const selectionLength: number = editor.selection.getContent().length;

    if (applied) {
      editor.formatter.remove(styleName, { value: null }, null, true);
    } else {
      editor.formatter.apply(styleName, options);
    }

    // Returns whether a match was found.
    return selectionLength === 0;
  }

  export function getMMDDYYYYDateWithSlashes(date: MomentInput) : any {
    if (date) {
      return moment.utc(date).format('MM/DD/YYYY');
    }

    return null;
  }

  export function getYYYYMMDDDateWithDashes(date: MomentInput) : any {
    if (date) {
      return moment.utc(date).format('YYYY-MM-DD');
    }

    return null;
  }
}
