import { AnnouncementStatus } from '@shared/enums/announcement-status';
import { RoleType } from '@shared/enums/role-type';

import { IProductVariant } from './product-variant';

// Used for getting and saving one announcement because variants property is different than getting multiple
// announcements.
export interface IAnnouncementRequest {
  announcementId: string;
  productLineId: string;
  title: string;
  message: string;
  variants: IProductVariant[];
  userRoles: RoleType[];
  productLineIds: string[];
}

export interface IAnnouncement {
  announcementId: string;
  productLineId?: string;
  productLine?: string;
  productDescription?: string;
  title: string;
  message: string;
  variants?: IProductVariant[];
  userRoles?: RoleType[];
  productLineIds?: string[];
  status?: AnnouncementStatus;
}

export class Announcement implements IAnnouncement {
  announcementId: string = null;
  // productLineId holds value for announcement made with single product.
  // It will be null for multi product or global announcement.
  productLineId?: string = null;
  productLine?: string = null;
  productDescription?: string = null;
  title: string = null;
  message: string = null;
  // variants holds the grade selected for single product announcement.
  // For multi product or global announcement this will be empty meaning ALL grades
  variants?: IProductVariant[] = [];
  userRoles?: RoleType[] = [];
  // productLineIds holds list of products line Ids selected when an announcement made for multiple products.
  productLineIds?: string[] = [];
  status?: AnnouncementStatus = null;

  constructor(values: Partial<IAnnouncement>) {
    Object.keys(values).forEach((key) => {
      this[key] = values[key];
    });
  }
}
