<zbp-data-table
  [columns]="columns"
  [rows]="rows"
  [isLoading]="isLoading"
  [noResultsMessage]="noResultsMessage"
  [pageSize]="pageSize"
  [collectionSize]="collectionSize"
  [page]="page"
  (pageChange)="onPageChange($event)"
  [leftFooterContent]="leftFooterContent"
  [rightFooterContent]="rightFooterContent"
  (orderByParamChange)="orderByParamChange.emit($event)"
></zbp-data-table>
