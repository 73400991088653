export const checkedBoxIcon = `
<svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" viewBox="0 0 15 15"
     fill="none">
  <path
    d="M2.5 0.539062C1.397 0.539062 0.5 1.43606 0.5 2.53906V12.5391C0.5 13.6421 1.397 14.5391 2.5 14.5391H12.5C13.603 14.5391 14.5 13.6421 14.5 12.5391V2.53906C14.5 1.43606 13.603 0.539062 12.5 0.539062H2.5ZM2.5 12.5391V2.53906H12.5L12.502 12.5391H2.5Z"
    fill="var(--zbp-svg-primary-color, black)"
  />
  <path
    d="M6.4958 8.09513L5.1998 6.82413L3.7998 8.25413L6.5038 10.9011L11.2028 6.25013L9.7968 4.82812L6.4958 8.09513Z"
    fill="var(--zbp-svg-primary-color, black)"/>
</svg>
`;
