<div
  #tableScrollWrapper
  [class.table-scroll-wrapper]="isScrollable"
  [class.scroll-active]="scrollActive"
>
  <table
    [class]="'table styled-table ' + theme">
    <thead>
    <tr>
      <!-- loop over the columns to generate header -->
      <ng-container *ngFor="let column of columns">
        <th [attr.title]="column.tooltip"
            [attr.aria-label]="column.ariaLabel ?? column['header'] ?? column.key">

          <!-- Blank Column Header -->
          <ng-container *ngIf="column.type === ZbpDataTable.BLANK_COLUMN">
            <div class="hidden">
              <!-- put something in there for screen readers and hide using css -->
              {{ column.ariaLabel }}
            </div>
          </ng-container>

          <!-- Simple Column Header -->
          <ng-container *ngIf="column.type === ZbpDataTable.SIMPLE_COLUMN">
            {{ column.header }}
          </ng-container>

          <!-- Sortable Column Header -->
          <ng-container *ngIf="column.type === ZbpDataTable.SORTABLE_COLUMN">
            <button class="sortable-column-header"
                    (click)="onSortColumnClick(column.key)"
                    [attr.aria-label]="getSortAriaLabel(column)"
                    type="button">
              <span>{{ column.header }}</span>
              <i class="sort-icon"
                 [ngClass]="getSortClass(column.key)"
              ></i>
            </button>
          </ng-container>

          <ng-container *ngIf="column.type === ZbpDataTable.CHECKBOX_COLUMN">
            <input
              type="checkbox"
              (click)="toggleAllCheckboxes(column)"
              [formControl]="_checkAllStateForm"
              [attr.aria-labelledby]="column.ariaLabel ?? column.header"
            />
            {{ column.header ?? '' }}
          </ng-container>

        </th>
      </ng-container>

    </tr>
    </thead>
    <tbody>
    <!-- if loading, show spinner -->
    <ng-container *ngIf="!isLoading else spinnerTemplate">

      <!-- if results are missing, show no results message -->
      <ng-container *ngIf="rows.length > 0 else noResultsTemplate">

        <!-- loop over the data rows -->
        <tr *ngFor="let row of rows; index as i; trackBy: handleTracking"
            [formGroup]="formArray.at(i) ?? EMPTY_FORM_GROUP"
            [class.disabled]="row.isDisabled"
            [ngbTooltip]="row.tooltip"
            [container]="'body'">

          <!-- for each row loop over the columns -->
          <ng-container *ngFor="let column of columns">

            <!-- get cell from the row based on current column -->
            <ng-container *ngrxLet="getCell(row, column); let cell">

              <!-- Text Cell -->
              <ng-container *ngIf="cell.type === ZbpDataTable.TEXT_CELL">
                <td id="{{column.key}}_{{i}}"
                    [attr.title]="cell.tooltip"
                    [attr.aria-label]="cell.ariaLabel"
                    class="text-cell">
                  {{ cell.text }}
                </td>
              </ng-container>

              <!-- Html Cell -->
              <ng-container *ngIf="cell.type === ZbpDataTable.HTML_CELL">
                <td id="{{column.key}}_{{i}}"
                    [attr.aria-label]="cell.ariaLabel"
                    class="html-cell"
                    [innerHTML]="cell.html">
                </td>
              </ng-container>

              <!-- Text List Cell -->
              <ng-container *ngIf="cell.type === ZbpDataTable.TEXT_LIST_CELL">
                <td id="{{column.key}}_{{i}}"
                    [attr.title]="cell.tooltip"
                    [attr.aria-label]="cell.ariaLabel"
                    class="text-list-cell">
                  <ul class="text-list">
                    <!-- has to be on single line to avoid adding extra spaces -->
                    <li *ngFor="let value of cell.textList" class="text-wrap">{{ value }}</li>
                  </ul>
                </td>
              </ng-container>

              <!-- Link Cell -->
              <ng-container *ngIf="cell.type === ZbpDataTable.LINK_CELL">
                <td id="{{column.key}}_{{i}}"
                    class="link-cell">
                  <zbp-button-component
                    [label]="cell.text"
                    [tooltip]="cell.tooltip"
                    [ariaLabel]="cell.ariaLabel"
                    theme="primaryTextLink"
                    (clickHandler)="cell.onClick()"
                    fontSizeUnit="rem"
                  ></zbp-button-component>
                </td>
              </ng-container>

              <!-- Action Buttons Cell -->
              <ng-container *ngIf="cell.type === ZbpDataTable.ACTION_LIST_CELL">
                <td id="{{column.key}}_{{i}}"
                    class="actions-cell">
                  <div class="actions-container">
                    <zbp-button-component
                      *ngFor="let action of cell.actions"
                      [iconName]="action.iconName"
                      (clickHandler)="action.onClick()"
                      [tooltip]="action.tooltip"
                      [ariaLabel]="action.ariaLabel ?? action.tooltip"
                      [disabled]="action.disabled"
                      theme="icon"
                      iconSize="font-size"
                      fontSizeUnit="em"
                    ></zbp-button-component>
                  </div>
                </td>
              </ng-container>

              <!-- Checkbox Cell -->
              <ng-container *ngIf="cell.type === ZbpDataTable.CHECKBOX_CELL">
                <td id="{{column.key}}_{{i}}"
                    class="checkbox-cell">
                  <input
                    id="checkboxCell{{i}}"
                    type="checkbox"
                    (click)="checkboxCellClickHandler(cell)"
                    (change)="checkboxCellChangeHandler(column, cell)"
                    [formControlName]="cell.formControlName ?? column.key"
                    [attr.aria-label]="cell.ariaLabel ?? 'checkbox' + (i + 1)"
                  />
                </td>
              </ng-container>

              <!-- Radio Cell -->
              <ng-container *ngIf="cell.type === ZbpDataTable.RADIO_CELL">
                <td class="radio-cell">
                  <input
                    id="radioCell{{i}}"
                    class="radioButton"
                    type="radio"
                    [value]="cell.radioValue"
                    [formControl]="getRadioControl(column)"
                    [attr.aria-label]="cell.ariaLabel ?? 'radio' + (i + 1)"
                  >
                </td>
              </ng-container>

              <!-- BEHAVIOR_SUBJECT_CELL -->
              <ng-container *ngIf="cell.type === ZbpDataTable.BEHAVIOR_SUBJECT_CELL">
                <td id="{{column.key}}_{{i}}"
                    class="behavior-subject-cell"
                    [attr.title]="cell.tooltip"
                    [attr.aria-label]="cell.ariaLabel">
                  {{ cell.value?.value }}
                </td>
              </ng-container>

            </ng-container>
          </ng-container>
        </tr>
      </ng-container>
    </ng-container>
    </tbody>
  </table>

  <ng-template #spinnerTemplate>
    <tr>
      <td [colSpan]="columns.length">
        <zbp-preloader></zbp-preloader>
      </td>
    </tr>
  </ng-template>

  <ng-template #noResultsTemplate>
    <tr>
      <!-- single line to prevent extra whitespace characters in output -->
      <td id="noResultsMessage" [colSpan]="columns.length" class="table-body-message">{{ noResultsMessage }}</td>
    </tr>
  </ng-template>
</div>
<div *ngIf="leftFooterContent || rightFooterContent || collectionSize > pageSize"
     class="styled-table-footer-container">
  <div class="styled-table-footer-left">
    <ng-container *ngTemplateOutlet="leftFooterContent"></ng-container>
  </div>
  <div class="styled-table-footer-right">
    <ng-container *ngTemplateOutlet="rightFooterContent"></ng-container>
    <nav *ngIf="collectionSize > pageSize"
         class="styled-pagination">
      <ngb-pagination #pagination
                      [pageSize]="pageSize"
                      [maxSize]="maxPageCount"
                      [collectionSize]="collectionSize"
                      [page]="page"
                      (pageChange)="pageChangeWrapper($event)"
      ></ngb-pagination>
    </nav>
  </div>
</div>
