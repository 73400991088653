export const importFileIcon = `
<svg xmlns="http://www.w3.org/2000/svg"
     width="24" height="24" viewBox="0 0 24 24" fill="none">
  <path
    d="M14 2L19.7071 7.70711C19.8946 7.89464 20 8.149 20 8.41421V9M14 2H6C4.89543 2 4 2.89543 4 4V20C4 21.1046 4.89543 22 6 22H18C19.1046 22 20 21.1046 20 20V15.5V9M14 2V8C14 8.55228 14.4477 9 15 9H20"
    stroke="var(--zbp-svg-primary-color, black)"
    fill="transparent"
    stroke-linejoin="round"
  />
  <path fill-rule="evenodd"
        clip-rule="evenodd"
        d="M13.3333 11.7481C13.3333 11.4816 13.6241 11.317 13.8526 11.4541L19.51 14.8486C19.7319 14.9817 19.7319 15.3034 19.51 15.4366L13.8526 18.831C13.6241 18.9681 13.3333 18.8035 13.3333 18.537L13.3333 16.1783C13.3333 15.9889 13.1798 15.8354 12.9905 15.8354L6.66667 15.8354C6.29848 15.8354 6 15.5252 6 15.1426C6 14.7599 6.29848 14.4498 6.66667 14.4498L12.9905 14.4498C13.1798 14.4498 13.3333 14.2963 13.3333 14.1069V11.7481Z"
        fill="var(--zbp-svg-primary-color, black)"
   />
</svg>
`;
