<div [ngClass]="[
        'zbp-icon-component',
        iconSize,
        _iconName + '-icon',
     ]"
     [innerHTML]="svgIcon"
     style="{{primaryColor ? '--zbp-svg-primary-color: ' + primaryColor + ';': ''}}
            {{secondaryColor ? '--zbp-svg-secondary-color: ' + secondaryColor + ';' : ''}}"
></div>

