import { NgIf, NgTemplateOutlet } from '@angular/common';
import { Component, Input, TemplateRef } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ButtonComponent } from '@shared/button/button.component';
import { zbpIconNames } from '@shared/zbp-icon/zbp-icon-names';

@Component({
  selector: 'zbp-modal-template',
  standalone: true,
  imports: [
    NgIf,
    NgTemplateOutlet,
    ReactiveFormsModule,
    ButtonComponent
  ],
  templateUrl: './modal-template.component.html',
  styleUrl: './modal-template.component.scss'
})
export class ModalTemplateComponent {

  protected readonly zbpIconNames = zbpIconNames;

  @Input() modalHeader?: string;
  @Input() bodyContent?: TemplateRef<any>;
  @Input() footerContent?: TemplateRef<any>;
  @Input() showCloseX: boolean = false;
  @Input() isScrollable: boolean = true;

  constructor(private activeModal: NgbActiveModal) {
  }

  dismiss(): void {
    this.activeModal.dismiss();
  }

}
