import { CommonModule } from '@angular/common';
import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { ControlContainer, FormArray, FormControl, FormGroup, ReactiveFormsModule } from '@angular/forms';

import { SchoolLicense } from '@models/license/school-license';
import { School } from '@models/school';
import { LicenseAssignmentType, LicenseStatus } from '@shared/enums/license-status';
import { ZbpDataTableComponent } from '@shared/zbp-data-table/zbp-data-table.component';
import { ZbpDataTable } from '@shared/zbp-data-table/zbp-data-table.model';
import { ToastrService } from 'ngx-toastr';
import { BehaviorSubject, Subscription } from 'rxjs';
import { finalize } from 'rxjs/operators';
import { SchoolsService } from '../../schools/schools.service';
import { licenseFieldNames, newLicensesFormArrayName, newLicensesFormGroupName } from './helpers/form-field-setup-data';
import { AddLicensesFormModel } from './helpers/form-mappers';
import SIMPLE_COLUMN = ZbpDataTable.SIMPLE_COLUMN;

@Component({
  standalone: true,
  selector: 'zbp-add-licenses-form',
  templateUrl: './add-licenses-form.component.html',
  styleUrls: ['./add-licenses-form.component.scss'],
  imports: [
    CommonModule,
    ZbpDataTableComponent,
    ReactiveFormsModule,
  ]
})
export class AddLicensesFormComponent implements OnInit, OnDestroy {

  /** Name of the form group for licenses. How the group can be referenced by its containing form. */
  @Input() newLicensesFormGroupName = newLicensesFormGroupName;
  @Input() school: School;
  /** If true, it indicates that we should show licenses that are already assigned. (Useful for edit scenarios) */
  @Input() showAlreadyAssigned: boolean = false;
  /** Indicates whether we are working with Teacher licenses or Classroom licenses, default is Teacher. */
  @Input() assignmentType: LicenseAssignmentType = LicenseAssignmentType.Teacher;
  /** - If assignmentType is Teacher: this is the Teacher's User ID.
   *  - If assignmentType is Classroom: this is the Classroom's ID. */
  @Input() assignedId: string | null = null;

  /** FormGroup used within the form and added to the ControlContainer */
  form: FormGroup;
  rows: ZbpDataTable.Row[] = [];
  licensesLoaded: boolean = false;

  readonly tableTheme = ZbpDataTable.TableTheme.transparent;
  readonly columns: ZbpDataTable.Column[] = [
    { type: SIMPLE_COLUMN, key: 'assign', header: 'Assign' },
    { type: SIMPLE_COLUMN, key: 'license', header: 'License' },
    { type: SIMPLE_COLUMN, key: 'availability', header: 'Availability' },
  ];

  private subscriptions: Subscription[] = [];
  private countDisplayList: BehaviorSubject<string>[] = [];
  private formGroupExistsOnContainer: boolean = false;

  constructor(
    private toastr: ToastrService,
    private schoolsService: SchoolsService,
    private controlContainer: ControlContainer,
  ) {
  }

  ngOnInit() {
    this.setupFormGroup();

    if (!this.formGroupExistsOnContainer) {
      // If form group doesn't already exist on ControlContainer, create the form using the School
      this.loadLicensesFromSchool();
    } else {
      // Use existing form data to fill out the form
      this.loadLicensesFromForm();
    }
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(sub => sub.unsubscribe());
  }

  get licensesFormArray(): FormArray {
    return this.form.get(newLicensesFormArrayName) as FormArray;
  }

  private setupFormGroup(): void {
    const controlContainerAsFormGroup = this.controlContainer.control as FormGroup;
    const currentFormForThisComponent = controlContainerAsFormGroup?.get(this.newLicensesFormGroupName) as FormGroup;

    // If this form isn't already on the Control Container, instantiate new Licenses FormArray
    if (!currentFormForThisComponent) {
      this.formGroupExistsOnContainer = false;
      this.form = new FormGroup({
        [newLicensesFormArrayName]: new FormArray([]),
      });

      // Add control to ControlContainer, so it can be accessed by its containing form
      controlContainerAsFormGroup.addControl(this.newLicensesFormGroupName, this.form);
    } else {
      this.formGroupExistsOnContainer = true;
      // Use the existing form group data if it already exists.
      this.form = currentFormForThisComponent;
    }
  }

  /**
   * Creates a row of cells for a license to be displayed in a data table.
   *
   * @param {SchoolLicense} license - The license data to be displayed in the row.
   * @returns {ZbpDataTable.Row} The row object containing the cells for the license.
   */
  createLicenseRowForTable(license: SchoolLicense): ZbpDataTable.Row {
    // This value will update on the view when the user selects/unselects a checkbox
    const availabilityBehaviorSubject = new BehaviorSubject<string>(`${license.used}/${license.total}`);
    this.countDisplayList.push(availabilityBehaviorSubject);
    return {
      cells: {
        license: {
          type: ZbpDataTable.TEXT_CELL,
          text: license.description,
        },
        availability: {
          type: ZbpDataTable.BEHAVIOR_SUBJECT_CELL,
          value: availabilityBehaviorSubject,
        },
        assign: {
          type: ZbpDataTable.CHECKBOX_CELL,
          formControlName: 'isAssigned',
          ariaLabel: license.description,
        }
      }
    };
  }

  /**
   * Updates the count of the total licenses display to the user
   * This is only for displaying a count and does not handle any calculations needed by the API
   * @param licensesArrayIndex Index of row in the array of licenses
   * @param newValue the new value of the checkbox to use when adjusting the count
   */
  assignLicense(licensesArrayIndex: number, newValue: boolean): void {
    const licensedControlClicked = this.licensesFormArray?.controls[licensesArrayIndex] as FormControl;
    const licensesTotal = licensedControlClicked.get(licenseFieldNames.licensesTotal)?.value as number;
    const licensesUsed = licensedControlClicked.get(licenseFieldNames.licensesUsed)?.value as number;

    let newCount: number = 0;

    if (licensedControlClicked) {
      if (newValue) {
        newCount = licensesUsed + 1;
      } else {
        newCount = licensesUsed - 1;
      }

      // Update licenses used count - this is used only for UI purposes and not sent to API
      this.updateLicensesUsedControl(licensedControlClicked, newCount);
      // Update the display of used licenses vs total licenses
      this.countDisplayList[licensesArrayIndex]?.next(`${newCount}/${licensesTotal}`);
    }
  }

  private updateLicensesUsedControl(licenseControl: FormControl, newCount: number) {
    const licensesUsedFormControl = licenseControl.get(licenseFieldNames.licensesUsed);
    licensesUsedFormControl?.setValue(newCount);
  }

  /**
   * Use existing form data to create rows for the table.
   * For example, this occurs in the Add New Class page when going from step 3 back to step 2.
   */
  private loadLicensesFromForm() {
    const availableLicensesInForm = this.licensesFormArray as FormArray<AddLicensesFormModel>;

    const newRows = [];

    availableLicensesInForm?.controls.forEach((license, i) => {
      const isAssignedControl = license.get(licenseFieldNames.isAssigned);
      this.attachAssignLicenseTrigger(i, isAssignedControl as FormControl);
      const licenseData: SchoolLicense = {
        skuId: license.get(licenseFieldNames.skuId)?.value,
        used: license.get(licenseFieldNames.licensesUsed)?.value,
        total: license.get(licenseFieldNames.licensesTotal)?.value,
        // TODO amount is missing?
        description: license.get(licenseFieldNames.description)?.value,
        // TODO isAssigned is missing?
      } as SchoolLicense;
      newRows.push(this.createLicenseRowForTable(licenseData));
    });
    this.rows = newRows;
    this.licensesLoaded = true;
  }

  /**
   * Use the School to create form controls and add rows to the table
   */
  private loadLicensesFromSchool(): void {
    const userId: string = this.assignmentType === LicenseAssignmentType.Teacher
      ? this.assignedId
      : null;
    const classroomId: string = this.assignmentType === LicenseAssignmentType.Classroom
      ? this.assignedId
      : null;
    this.subscriptions.push(this.schoolsService
      .getSchoolLicenses(
        this.school.schoolId,
        this.school.schoolYear,
        null,
        userId,
        classroomId,
        this.assignmentType,
        false
      )
      .pipe(finalize(() => {
        this.licensesLoaded = true;
      }))
      .subscribe((res) => {
        if (res.success) {
          const newRows = [];
          const filteredLicenses = this.filterLicenses(res.response);
          filteredLicenses.forEach((license: SchoolLicense, i: number) => {
            const isAssignedControl = new FormControl(license.status === LicenseStatus.AssignedToYou);
            this.attachAssignLicenseTrigger(i, isAssignedControl);
            this.licensesFormArray.push(
              new FormGroup({
                skuId: new FormControl(license.skuId),
                licensesUsed: new FormControl(license.used),
                licensesTotal: new FormControl(license.total),
                amount: new FormControl(`${license.used}/${license.total}`),
                description: new FormControl(license.description),
                isAssigned: isAssignedControl,
              }));
            newRows.push(this.createLicenseRowForTable(license));
            this.licensesLoaded = true;
          });
          this.rows = newRows;
        } else {
          this.toastr.error(res.messages[0]);
        }
      }));
  }

  private filterLicenses(licenses: SchoolLicense[]): SchoolLicense[] {
    if (this.showAlreadyAssigned) {
      return licenses;
    }
    // only show available licenses
    return licenses.filter(
      license => license.status === LicenseStatus.Available
    );
  }

  private attachAssignLicenseTrigger(index: number, isAssignedControl: FormControl<boolean>): void {
    this.subscriptions.push(
      isAssignedControl.valueChanges.subscribe(
        newValue => this.assignLicense(index, newValue)
      )
    );
  }

  getNoResultsMessage(): string {
    const assignmentDisplay = this.assignmentType.toLowerCase();
    return this.showAlreadyAssigned
      ? `There are no licenses available or assigned to this ${assignmentDisplay}.`
      : `There are no licenses available to assign to this ${assignmentDisplay}.`;
  }

}
