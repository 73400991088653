export const parentRoleIcon = `
<svg xmlns="http://www.w3.org/2000/svg" width="100" height="96" viewBox="0 0 100 96"
     fill="none">
  <path
    d="M61.9555 22.3618C61.9555 32.414 53.8066 40.563 43.7543 40.563C33.7021 40.563 25.5531 32.414 25.5531 22.3618C25.5531 12.3095 33.7021 4.16056 43.7543 4.16056C53.8066 4.16056 61.9555 12.3095 61.9555 22.3618Z"
    stroke="var(--zbp-svg-primary-color, black)"
    stroke-width="6.84846" stroke-linejoin="round"
  />
  <path
    d="M46.8119 84.6569V84.4097C46.8119 79.7692 49.7729 75.6461 54.1702 74.1636L72.617 67.9444M46.8119 84.6569V91.6405H95.7576V84.1309C95.7576 79.627 92.9658 75.5948 88.75 74.0098L72.617 67.9444M46.8119 84.6569H4.05664V68.6378C4.05664 64.4918 6.42741 60.7108 10.1595 58.9048L13.7069 57.1882C27.7057 50.4141 41.2579 48.3264 55.9598 52.1865H56.8592M72.617 67.9444C81.3198 67.9444 88.3748 60.8893 88.3748 52.1865C88.3748 43.4837 81.3198 36.4287 72.617 36.4287C63.9142 36.4287 56.8592 43.4837 56.8592 52.1865M72.617 67.9444C63.9142 67.9444 56.8592 60.8893 56.8592 52.1865"
    stroke="var(--zbp-svg-primary-color, black)"
    stroke-width="6.84846" stroke-linejoin="round"
  />
</svg>
`;
