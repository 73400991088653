<div class="loginHeader fixed-top bg-primary">&nbsp;</div>
<div class="d-flex flex-wrap align-items-center justify-content-start loginContainer w-100" [ngClass]="{'loginContainerIE': browserService.isIE }">
  <div class="loginFormContainerWrapper text-center">
    <section class="loginFormContainer mx-auto">
      <img id="primaryLogo" src="assets/img/logo.svg" alt="My ZB Portal" class="mx-auto d-block loginImage">
      <h1 *ngIf="!inAccountSetup" id="loginResetTitle" class="subLogo mvBoli text-center">for teachers and school administrators</h1>
      <h1 *ngIf="inAccountSetup" id="pageTitle" class="formTitle text-center text-uppercase mt-5">Account Setup</h1>
      <aside id="errors" *ngIf="formErrors.length > 0" class="alert alert-danger my-3 mx-auto text-start" role="alert">
        <ng-container *ngFor="let formError of formErrors">
          <div>{{ formError }}</div>
        </ng-container>
      </aside>
      <form id="accountSetupForm" [formGroup]="form" class="mx-auto" (ngSubmit)="submitForm()">
        <div *ngIf="inAccountSetup && user" id="userInformation" class="my-3">
          <h2 class="h3 text-start">User Information</h2>
          <div class="text-end mb-3 d-flex align-items-baseline justify-content-end w-75">
            <label for="firstName" class="pe-3 w-50">First name</label>
            <input type="text" id="firstName" name="firstName" disabled [value]="user.firstName" class="form-control">
          </div>
          <div class="text-end mb-3 d-flex align-items-baseline justify-content-end w-75">
            <label for="lastName" class="pe-3 w-50">Last name</label>
            <input type="text" id="lastName" name="lastName" disabled [value]="user.lastName" class="form-control">
          </div>
          <div class="text-end mb-3 d-flex align-items-baseline justify-content-end w-75">
            <label for="email" class="pe-3 w-50">Email address</label>
            <input type="text" id="email" name="email" disabled [value]="user.username" class="form-control">
          </div>
        </div>
        <div class="my-3">
          <h2 class="h3 passwordTitle" [class.passwordTitleCentered]="!inAccountSetup">Set {{ inAccountSetup ? 'Password' : 'a new password'}}*</h2>
          <div *ngIf="form.controls.currentPassword" class="text-end mb-3 d-flex align-items-baseline justify-content-end w-75">
            <label for="currentPassword" class="pe-3 w-75">Current password</label>
            <input type="password" id="currentPassword" name="currentPassword" required class="form-control" autocomplete="off" formControlName="currentPassword" [class.is-invalid]="!isControlValid('currentPassword')" />
          </div>
          <div class="text-end mb-3 d-flex align-items-baseline justify-content-end w-75">
            <label for="password" class="pe-3 w-75">New password</label>
            <input type="password" id="password" name="password" required class="form-control" autocomplete="off" formControlName="password" [class.is-invalid]="!isControlValid('password')" />
          </div>
          <div class="text-end mb-3 d-flex align-items-baseline justify-content-end w-75">
            <label for="confirmPassword" class="pe-3 w-75">Retype new password</label>
            <input type="password" id="confirmPassword" name="confirmPassword" required class="form-control" autocomplete="off" formControlName="confirmPassword" [class.is-invalid]="!isControlValid('confirmPassword')" />
          </div>
        </div>
        <div class="mb-3 text-center">
          <button id="accountSetupSaveButton" type="submit" class="btn btn-outline-tertiary text-uppercase btn-sm">Save</button>
        </div>
      </form>
      <aside id="passwordInstructions" class="mx-auto text-start">
        <ul class="w-75 mx-auto ps-0">
          <li>Passwords must:<ul class="ps-5">
            <li>
              Be at least 8 characters in length
              <i class="fa" aria-hidden="true" [ngClass]="getPasswordRequirementClasses('passwordLength')"></i>
              <span class="sr-only">{{ passwordMeetsRequirement('passwordLength') ? 'valid' : 'invalid' }}</span>
            </li>
            <li>Be different from your last 5 passwords
              <ng-container *ngIf="form.controls.currentPassword">
                <i class="fa" aria-hidden="true" [ngClass]="getPasswordRequirementClasses('newPasswordNotDifferent')"></i>
                <span class="sr-only">{{ passwordMeetsRequirement('newPasswordNotDifferent') ? 'valid' : 'invalid' }}</span>
              </ng-container>
            </li>
          </ul></li>
        </ul>
      </aside>
    </section>
  </div>
  <div class="loginSwirlContainer mt-auto ms-auto align-self-end text-end">
    <img id="swirlImage" class="loginImage loginImageSwirl" src="assets/img/zb_greyswirl_right.png" role="presentation" alt="">
  </div>
</div>
<zbp-footer></zbp-footer>
