import { HttpErrorResponse } from '@angular/common/http';
import { ErrorHandler, Inject, Injectable, Injector } from '@angular/core';
import { AppConfigService } from '@core/appconfig.service';
import { UserService } from '@core/user.service';
import { ApplicationInsights } from '@microsoft/applicationinsights-web';
import { ErrorForDisplay } from '@models/error-handling/error-for-display.model';
import { ErrorForLogging } from '@models/error-handling/error-for-logging.model';
import { ExtendedHttpErrorResponse } from '@models/error-handling/extended-http-error-response.model';
import { UserError } from '@models/error-handling/user-error.model';
import { Helpers } from '@shared/helpers';
import { ToastrService } from 'ngx-toastr';
import { BehaviorSubject, Observable, asyncScheduler } from 'rxjs';
import { filter, map, tap, throttleTime } from 'rxjs/operators';
import { environment } from '../../../../environments/environment';


@Injectable()
export class ErrorHandlingService implements ErrorHandler {
  private appInsights: ApplicationInsights;
  private errorCache: { [key: string]: number } = {};
  reportingErrorDelay = 10000; // 10 seconds
  duplicateErrorDelay = 120000; // 2 minutes
  error$: BehaviorSubject<Error | UserError> = new BehaviorSubject<Error>(null);

  newErrorWithUUID$: Observable<{ originalError: Error }> = this.error$
    .pipe(
      throttleTime(this.reportingErrorDelay, asyncScheduler, { leading: true, trailing: false }),
      filter(error => !!error),
      map(error => ({ originalError: error }))
    );

  errorForLogging$: Observable<{ originalError: Error, errorForLogging: ErrorForLogging }> = this.newErrorWithUUID$
    .pipe(
      filter(error => !!error),
      map(({ originalError }) => {
        const currentTime = Date.now();
        const errorForLogging = new ErrorForLogging();
        const errorIdentifier = errorForLogging.referenceNumber;
        if (this.errorCache[errorIdentifier]) {
          const lastLoggedTime = this.errorCache[errorIdentifier];
          if ((currentTime - lastLoggedTime) < this.duplicateErrorDelay) {
            // If the error was logged in the past 2 minutes, don't log it again
            return null;
          }
        }
        this.errorCache[errorIdentifier] = currentTime;

        errorForLogging.stack = originalError?.stack || JSON.stringify(originalError);
        errorForLogging.userName = this.userService?.user?.userName || undefined;
        errorForLogging.originalErrorMessage = originalError?.message;

        if (originalError instanceof UserError) {
          errorForLogging.name = originalError.name;
        }

        if (originalError instanceof HttpErrorResponse) {
          errorForLogging.httpErrorCode = originalError.status;
        }

        errorForLogging.uri = window.location.href;

        // The custom properties will be ignored by AppInsights, so we need to put them into the message field
        // which will show up in the AppInsights query
        errorForLogging.message = JSON.stringify(errorForLogging);

        return { originalError, errorForLogging };
      }),
    );

  errorToShow$: Observable<ErrorForLogging> = this.errorForLogging$.pipe(
    filter(error => !!error),
    map(({ originalError, errorForLogging }) => {
      this.showError(originalError, errorForLogging);
      return errorForLogging;
    })
  );

  recordError$ = this.errorToShow$.pipe(
    filter(errorForLogging => !!errorForLogging),
    tap((errorForLogging) => {
      console.error(errorForLogging);
      if (environment.appInsights) {
        this.appInsights.trackException({ exception: errorForLogging });
      }
    }),
  );

  constructor(
    @Inject(Injector) private injector: Injector,
    private userService: UserService,
    private appConfigService: AppConfigService,
  ) {
    if (environment.appInsights) {
      this.appInsights = new ApplicationInsights({
        config: {
          connectionString: environment.appInsights,
        }
      });
      setInterval(() => {
        this.clearOldErrors();
      }, this.duplicateErrorDelay);

      this.appInsights.loadAppInsights();
    }
    this.recordError$.subscribe();
  }

  handleError(error: Error | UserError | ExtendedHttpErrorResponse): void {
    if (!this.appConfigService.isProduction) {
      console.error(error);
    }
    if (!(error instanceof HttpErrorResponse
      && (error.status === 401 || error.status === 403))) {
      this.error$.next(error);
    }
  }

  buildErrorList(errorMessages: string[]): string {
    if (errorMessages?.length === 1) {
      return errorMessages[0];
    }

    let errorList = '';
    errorMessages.forEach((message) => {
      errorList = errorList.concat(`  - ${message}<br>`);
    });
    return errorList;
  }

  private get toastr(): ToastrService {
    return this.injector.get(ToastrService);
  }

  private showError(originalError: Error, errorForLogging: ErrorForLogging) {
    const errorForDisplay = new ErrorForDisplay();
    errorForDisplay.userErrorId = errorForLogging.referenceNumber.slice(-12);
    if (originalError instanceof ExtendedHttpErrorResponse) {
      errorForDisplay.userMessages = originalError.messages;
    } else if (originalError instanceof HttpErrorResponse
      && Object.prototype.hasOwnProperty.call(originalError.error, 'messages')) {
      errorForDisplay.userMessages = Helpers.setMessages(originalError.error.messages);
    } else if (originalError instanceof UserError) {
      errorForDisplay.userMessages = originalError.userMessages;
    }

    if (errorForDisplay.userMessages?.length > 0) {
      const messageForUser = `${this.buildErrorList(errorForDisplay.userMessages)}`;
      this.toastr.error(messageForUser,
        '',
        {
          toastClass: 'ngx-toastr error-handling-service-toast',
          closeButton: true,
          timeOut: 10000,
          onActivateTick: true,
          tapToDismiss: true,
          extendedTimeOut: 0,
          enableHtml: true
        });
    }
  }

  private clearOldErrors(): void {
    const currentTime = Date.now();
    Object.keys(this.errorCache).forEach((errorIdentifier) => {
      if ((currentTime - this.errorCache[errorIdentifier]) > this.duplicateErrorDelay) {
        delete this.errorCache[errorIdentifier];
      }
    });
  }
}
