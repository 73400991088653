export const infoCircleIcon = `
<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"
     fill="none">
  <path
    fill-rule="evenodd"
    clip-rule="evenodd"
    d="m 23.99865,13.001275 c 0,6.07465 -4.9247,10.99935 -10.9993,10.99935 C 6.9247,24.000625 2,19.075925 2,13.001275 2,6.926675 6.9247,2.00195 12.99935,2.00195 c 6.0746,0 10.9993,4.924725 10.9993,10.999325 z m -9.9994,-1.4999 v 6.99955 H 11.9994 v -6.99955 z m -0.9999,-1.9999 c 0.3315,0 0.6494,-0.13165 0.8838,-0.3661 0.2344,-0.2344 0.3661,-0.5523 0.3661,-0.8838 0,-0.3315 -0.1317,-0.64945 -0.3661,-0.88385 -0.2344,-0.2344 -0.5523,-0.3661 -0.8838,-0.3661 -0.3315,0 -0.64945,0.1317 -0.88385,0.3661 -0.2344,0.2344 -0.3661,0.55235 -0.3661,0.88385 0,0.3315 0.1317,0.6494 0.3661,0.8838 0.2344,0.23445 0.55235,0.3661 0.88385,0.3661 z"
    fill="var(--zbp-svg-primary-color, black)"
    style="stroke-width:0.5"
  />
</svg>
`;
