export const tCrossIcon = `
<svg xmlns="http://www.w3.org/2000/svg" width="28" height="28" viewBox="0 0 28 28"
     fill="none">
  <path fill-rule="evenodd"
        clip-rule="evenodd"
        d="M13.8214 0.226074C12.8927 0.226074 12.1399 0.978927 12.1399 1.90762V11.9969H2.05057C1.12187 11.9969 0.369019 12.7498 0.369019 13.6785C0.369019 14.6071 1.12187 15.36 2.05056 15.36H12.1399V25.4493C12.1399 26.378 12.8927 27.1308 13.8214 27.1308C14.7501 27.1308 15.5029 26.378 15.5029 25.4493V15.36H25.5922C26.5209 15.36 27.2738 14.6071 27.2738 13.6785C27.2738 12.7498 26.5209 11.9969 25.5922 11.9969H15.5029V1.90762C15.5029 0.978929 14.7501 0.226074 13.8214 0.226074Z"
        fill="var(--zbp-svg-primary-color, black)"
  />
</svg>
`;
