export const neutralIcon = `
<svg xmlns="http://www.w3.org/2000/svg"
     width="41" height="41" viewBox="0 0 41 41"
     fill="none">
  <mask id="mask0_5765_50791"
        style="mask-type:alpha"
        maskUnits="userSpaceOnUse"
        x="0" y="0"
        width="40" height="41">
    <path
      d="M20 40.418C31.0457 40.418 40 31.4637 40 20.418C40 9.37227 31.0457 0.417969 20 0.417969C8.9543 0.417969 0 9.37227 0 20.418C0 31.4637 8.9543 40.418 20 40.418Z"
      fill="white"
    />
  </mask>
  <g mask="url(#mask0_5765_50791)">
    <path
      d="M20 40.418C31.0457 40.418 40 31.4637 40 20.418C40 9.37227 31.0457 0.417969 20 0.417969C8.9543 0.417969 0 9.37227 0 20.418C0 31.4637 8.9543 40.418 20 40.418Z"
      fill="var(--zbp-svg-secondary-color, white)"
    />
    <path d="M32.5 16.4379L20.0001 9.22168L7.50012 16.4379L20.0001 23.6541L32.5 16.4379Z"
          fill="var(--zbp-svg-primary-color, black)"
    />
    <path d="M11.4965 22.0902L20 26.9992L28.5034 22.0902L32.4999 24.3974L19.9999 31.6136L7.5 24.3974L11.4965 22.0902Z"
          fill="var(--zbp-svg-primary-color, black)"
    />
  </g>
</svg>
`;
