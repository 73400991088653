import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AppConfigService } from '@core/appconfig.service';
import { ZbPortalRedesignHelpers } from '@core/services/feature-flagged-features/zb-portal-redesign-helpers';
import { UserService } from '@core/user.service';
import { IAnnouncement } from '@models/announcement';
import { ApiResponse } from '@models/api-response';
import { NgbModal, NgbModalRef, NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { CompanyCode } from '@shared/enums/company-code';
import {
  UserAnnouncementModalComponent
} from '@shared/modals/user-announcement-modal/user-announcement-modal.component';
import { ZbpIconComponent } from '@shared/zbp-icon/zbp-icon';
import { zbpIconNames } from '@shared/zbp-icon/zbp-icon-names';
import { ToastrService } from 'ngx-toastr';
import { from, of } from 'rxjs';
import { catchError, mergeMap } from 'rxjs/operators';

import { ConfirmationModalComponent } from '../confirmation-modal/confirmation-modal.component';
import { MarkdownService } from '../markdown.service';

@Component({
  standalone: true,
  selector: 'zbp-user-announcements',
  templateUrl: './user-announcements.component.html',
  styleUrls: ['./user-announcements.component.scss'],
  imports: [CommonModule, NgbModule, ZbpIconComponent]
})
export class UserAnnouncementsComponent implements OnInit {
  @Input() announcements: IAnnouncement[] = [];
  @Input() displayTeaser: boolean = false;
  @Input() announcementIconClass: string = 'fa-exclamation-circle';
  @Input() arrowIconClass: string = 'fa-chevron-right';
  @Output() dismissed: EventEmitter<string> = new EventEmitter<string>();

  modalRef: NgbModalRef;
  useRedesignedVersion: boolean = false;
  useHighlightsPortalIcon: boolean = false;
  zbpIconNames = zbpIconNames;

  constructor(
    private modal: NgbModal,
    private toastr: ToastrService,
    private markdown: MarkdownService,
    private userService: UserService,
    private appConfig: AppConfigService,
    private zbPortalRedesignHelpers: ZbPortalRedesignHelpers,
  ) {
  }

  ngOnInit(): void {
    this.useHighlightsPortalIcon = this.appConfig.loginBrand === CompanyCode.HighlightsPortal;
    this.useRedesignedVersion = this.useHighlightsPortalIcon || this.zbPortalRedesignHelpers.isZbPortal2024Active();
  }

  open(announcement: IAnnouncement): void {
    if (this.useRedesignedVersion) {
      // New Branded Styles
      this.modalRef = UserAnnouncementModalComponent.open(this.modal, {
        title: announcement.title,
        body: this.markdown.render(announcement.message),
        options: { size: 'lg' },
      });
    } else {
      // Legacy ZB Styles
      this.modalRef = this.modal.open(ConfirmationModalComponent, { size: 'lg' });
      this.modalRef.componentInstance.displayCancelButton = false;
      this.modalRef.componentInstance.confirmButtonText = 'CONFIRM AND REMOVE';
      this.modalRef.componentInstance.message = this.markdown.render(announcement.message);
    }

    from(this.modalRef.result)
      .pipe(
        catchError(() => of(false)),
        mergeMap((shouldRemove: boolean) => {
          if (shouldRemove) {
            return this.userService.dismissAnnouncement(announcement.announcementId);
          }
          return of(null);
        })
      )
      .subscribe((res?: ApiResponse<IAnnouncement>) => {
        if (res) {
          if (res.success) {
            this.dismissed.emit(announcement.announcementId);
          } else {
            this.toastr.error(res.messages[0]);
          }
        }
      });
  }

  trackById(index: number, item: IAnnouncement): string {
    return item.announcementId;
  }

  /**
   * Renders a trimmed announcement message inline.
   *
   * @param {string} message the announcement message to trim and render inline.
   *
   * @returns {string}
   */
  renderTeaser(message: string): string {
    if (message) {
      if (!this.useRedesignedVersion) {
        const trimmedMessage = message.length > 60 ? `${message.substring(0, 60)}...` : message;
        return this.markdown.renderInline(trimmedMessage);
      }

      // Remove heading markdown for teaser so font size does not change.activePaneId
      // Allow other types of markdown such as italics, bold, underline etc. to render correctly.
      let sanitizedMessage = message.replace(/^#+\s*(.+)$/gm, '$1');

      // Remove the URL from any links so that it is not included in the character count.
      sanitizedMessage = sanitizedMessage.replace(/\[([^\]]+)\]\([^)]+\)/g, '$1');

      const trimmedMessage = sanitizedMessage?.length > 60 ? `${sanitizedMessage.substring(0, 60)}...` : sanitizedMessage;
      return this.markdown.renderInline(trimmedMessage);
    }

    return '';
  }
}
