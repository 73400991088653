export enum UserSortColumns {
  firstName = 'firstName',
  lastName = 'lastName',
  email = 'email',
  externalId = 'externalId',
  username = 'username',
  lastLogin = 'lastLogin',
  status = 'status',
  school = 'school',
}
