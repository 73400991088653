export const maskIcon = `
<svg xmlns="http://www.w3.org/2000/svg"
     width="24" height="15" viewBox="0 0 24 15"
     fill="none">
  <path id="Vector"
        d="M0 0.797444C2.99771 4.23072 5.02551 -1.44508 11.3777 4.21064C13.0379 5.07089 14.9175 1.52178 17.5399 1.56811C19.7021 1.21444 22.482 3.09556 24.0002 0.795898C23.6141 4.8732 23.9925 9.6316 19.4596 11.5343C17.197 12.4363 14.6904 12.4517 12.6796 13.9483C11.18 14.5753 10.1314 12.8394 8.7213 12.6602C0.622394 11.298 0.469502 8.0084 0 0.797444ZM3.30348 4.78052C3.15985 7.21455 5.16759 8.9505 7.51356 8.97366C7.84406 8.97366 9.21241 9.12811 9.24793 8.78525C8.73055 4.19674 6.56992 5.5651 3.30503 4.78052H3.30348ZM20.675 4.78052C17.3051 5.6207 15.2851 4.1396 14.7383 8.90726C17.4905 9.4787 20.9669 8.01612 20.675 4.77281V4.78052Z"
        fill="var(--zbp-svg-primary-color, black)"
  />
</svg>
`;
