import { AbstractControl, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { FormHelpers } from '@shared/form-helpers';
import { generalUserFields } from '@shared/manage-users/manage-users-form/constants/form-field-setup-data';

/**
 * Custom validator for parent fields when managing a Student.
 * @returns validation errors for control or null
 */
export function getParentFieldsValidator(): ValidatorFn | null {
  // Group of Controls
  return (control: AbstractControl): ValidationErrors | null => {
    const parentFirstNameField =  control.get(generalUserFields.parentFirstName.name);
    const parentLastNameField =  control.get(generalUserFields.parentLastName.name);
    const parentUsernameField =  control.get(generalUserFields.parentUserName.name);

    const maxLengthValidator = FormHelpers.getMaxLengthNameValidator();

    // Check for max length & if required based on other field values
    let parentFirstNameError = null;
    if (maxLengthValidator(parentFirstNameField)) {
      parentFirstNameError =  { maxlength: FormHelpers.NAME_MAX_LENGTH };
    } else if (!parentFirstNameField?.value) {
      parentFirstNameError = { required: true };
    }

    // Check for max length & if required based on other field values
    let parentLastNameError = null;
    if (maxLengthValidator(parentLastNameField)) {
      parentLastNameError =  { maxlength: FormHelpers.NAME_MAX_LENGTH };
    } else if (!parentLastNameField?.value) {
      parentLastNameError = { required: true };
    }

    // Check for email validity & if required based on other field values
    let parentUsernameError = null;
    if (!parentUsernameField?.value) {
      parentUsernameError = { required: true };
    } else if (Validators.email(parentUsernameField)) {
      parentUsernameError = { email: true };
    }

    if (parentFirstNameField?.value || parentLastNameField?.value || parentUsernameField?.value) {
      const errors = {
        parentFirstName: parentFirstNameError,
        parentLastName: parentLastNameError,
        parentUserName: parentUsernameError
      };

      parentFirstNameField?.setErrors(errors.parentFirstName);
      parentLastNameField?.setErrors(errors.parentLastName);
      parentUsernameField?.setErrors(errors.parentUserName);

      return errors.parentFirstName || errors.parentLastName || errors.parentUserName ? { parentFields: true } : null;
    }

    parentFirstNameField?.setErrors(null);
    parentLastNameField?.setErrors(null);
    parentUsernameField?.setErrors(null);

    return null;
  };
}
