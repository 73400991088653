import { CommonModule, NgClass, NgForOf, NgIf } from '@angular/common';
import {
  AfterViewChecked,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  TemplateRef,
  ViewChild
} from '@angular/core';
import { FormArray, FormControl, FormGroup, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgbModule, NgbPagination } from '@ng-bootstrap/ng-bootstrap';
import { LetDirective } from '@ngrx/component';
import { ButtonComponent } from '@shared/button/button.component';
import { PreloaderModule } from '@shared/preloader/preloader.module';
import { SortColumnsService, SortNgClassObject } from '@shared/sort-columns.service';
import { ZbpDataTable } from '@shared/zbp-data-table/zbp-data-table.model';
import { zbpIconNames } from '@shared/zbp-icon/zbp-icon-names';
import CHECKBOX_COLUMN = ZbpDataTable.CHECKBOX_COLUMN;
import RADIO_COLUMN = ZbpDataTable.RADIO_COLUMN;
import PageChangeEvent = ZbpDataTable.PageChangeEvent;

@Component({
  selector: 'zbp-data-table',
  standalone: true,
  imports: [
    NgForOf,
    PreloaderModule,
    NgIf,
    LetDirective,
    ButtonComponent,
    NgbPagination,
    NgClass,
    FormsModule,
    ReactiveFormsModule,
    CommonModule,
    NgbModule,
  ],
  templateUrl: './zbp-data-table.component.html',
})
export class ZbpDataTableComponent implements OnInit, OnChanges, AfterViewChecked {

  protected readonly EMPTY_FORM_GROUP = new FormGroup({});

  /// TABLE DATA CONTROLS

  /** Controls the column header data. */
  @Input({ required: true }) columns: ZbpDataTable.Column[] = [];

  /** Controls the row data. */
  @Input({ required: true }) rows: ZbpDataTable.Row[] = [];

  /** Used by cell types with Form Controls when table rows are part of a form. */
  @Input() formArray: FormArray<FormGroup<Record<string, FormControl>>> = new FormArray([]);

  /** If true, a spinner will be displayed instead of rendering the table rows */
  @Input() isLoading: boolean = false;

  /** Will be shown when `isLoading === false` and `rows.length === 0` */
  @Input() noResultsMessage: string = 'No results found';

  /** Emitted when the user clicks on a sortable column heading, changing the sort value */
  @Output() orderByParamChange = new EventEmitter<string>();

  /** Style theme applied to table */
  @Input() theme: ZbpDataTable.TableTheme = ZbpDataTable.TableTheme.blueAndWhite;

  /** Flag to allow the table to be scrollable */
  @Input() isScrollable: boolean = false;

  @Input() leftFooterContent: TemplateRef<any>;
  @Input() rightFooterContent: TemplateRef<any>;

  /// PAGINATION CONTROLS

  /** See {@link NgbPagination#pageSize}. */
  @Input() pageSize: number = 25;

  /** See {@link NgbPagination#maxSize}. */
  @Input() maxPageCount: number = 10;

  /** See {@link NgbPagination#collectionSize}. */
  @Input() collectionSize: number;

  /** See {@link NgbPagination#page}. */
  @Input() page: number;

  /** See {@link NgbPagination#pageChange}. */
  @Output() pageChange = new EventEmitter<PageChangeEvent>();

  /// PRIVATE FIELDS

  private _sortColumnKey: string | null = null;
  private _sortDirection: boolean | null = null;

  /// INTERNAL PUBLIC FIELDS

  @ViewChild('pagination') pagination: NgbPagination;
  @ViewChild('tableScrollWrapper') tableScrollWrapper: ElementRef;
  public _checkAllStateForm = new FormControl<boolean>(false);

  /// READONLY FIELDS

  protected readonly ZbpDataTable = ZbpDataTable;
  readonly zbpIconNames = zbpIconNames;

  scrollActive = false;

  constructor(
    private sortService: SortColumnsService,
    private cdr: ChangeDetectorRef,
  ) {
  }

  pageChangeWrapper(page: number) {
    this.pageChange.emit({ page, pagination: this.pagination });
  }

  ngAfterViewChecked(): void {
    this.isScrollActive();
  }

  ngOnInit() {
    this.calculateCheckAllState();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.isLoading && changes.isLoading.currentValue === false) {
      // no longer loading, calculate check all state
      this.calculateCheckAllState();
    }
  }

  getCheckboxColumn(): ZbpDataTable.Column {
    return this.columns.find(column => column.type === CHECKBOX_COLUMN);
  }

  isScrollActive() {
    if (!this.isScrollable || !this.tableScrollWrapper) {
      return;
    }

    const el = this.tableScrollWrapper.nativeElement;
    this.scrollActive = el.scrollHeight > el.clientHeight;
    this.cdr.detectChanges();
  }

  getRadioControl(column: ZbpDataTable.Column): FormControl {
    if (column.type !== RADIO_COLUMN) {
      return null; // only calculate state for radio column
    }
    return column.control;
  }

  checkboxCellClickHandler(checkboxCell: ZbpDataTable.CheckboxCell) {
    // Then call the additional click handler if provided
    if (checkboxCell.additionalClickHandler) {
      checkboxCell.additionalClickHandler();
    }
  }

  calculateCheckAllState(): void {
    const checkAllColumn = this.getCheckboxColumn();
    if (checkAllColumn) {
      this.calculateCheckAllStateForColumn(checkAllColumn);
    }
  }

  checkboxCellChangeHandler(column: ZbpDataTable.Column, checkboxCell: ZbpDataTable.CheckboxCell) {
    this.calculateCheckAllStateForColumn(column);

    // Then call the additional click handler if provided
    if (checkboxCell.additionalChangeHandler) {
      checkboxCell.additionalChangeHandler();
    }
  }

  calculateCheckAllStateForColumn(column: ZbpDataTable.Column) {
    if (column.type !== CHECKBOX_COLUMN) {
      return; // only calculate state for checkbox column
    }
    if (this.isLoading) {
      // assume nothing is checked when loading
      this._checkAllStateForm.setValue(false);
      return;
    }
    // we have a select all checkbox header, so we check to see if all the rows are selected already
    this._checkAllStateForm.setValue(column.formArray.controls
      .map(control => (control as FormGroup))
      .map(group => group?.get(column.key) as FormControl<boolean>)
      .every(control => control.value)
    );
  }

  toggleAllCheckboxes(column: ZbpDataTable.CheckboxColumn): void {
    this._checkAllStateForm.setValue(!this._checkAllStateForm.value);
    column.formArray.controls
      .map(control => (control as FormGroup))
      .map(group => group?.get(column.key) as FormControl<boolean>)
      .forEach(control => control?.setValue(this._checkAllStateForm.value));
  }

  /**
   * Uses the column's key to get a specific cell from the row.
   */
  getCell(row: ZbpDataTable.Row, column: ZbpDataTable.Column): ZbpDataTable.Cell {
    return row.cells[column.key];
  }

  /**
   * If any Row is provided with a value for trackById, then all of them will be required to.
   */
  handleTracking(index: number, item: ZbpDataTable.Row) {
    if (item.trackById) {
      return item.trackById;
    }
    return index;
  }

  /** The key for the column that is currently being sorted, or null if no column is selected. */
  get sortColumnKey(): string | null {
    return this._sortColumnKey;
  }

  /**
   * The sort direction for the selected column:
   * | Value | Direction    |
   * | ----- | ------------ |
   * | null  | no direction |
   * | false | descending   |
   * | true  | ascending    |
   */
  get sortDirection(): boolean | null {
    return this._sortDirection;
  }

  onSortColumnClick(columnKey: string): void {
    if (columnKey !== this._sortColumnKey) {
      // we clicked on a new column, switch to track the new column
      // and then reset the direction to null before running the rest of the logic
      this._sortColumnKey = columnKey;
      this._sortDirection = null;
    }
    // calculate the new sort direction based on the direction we have stored
    this._sortDirection = this.sortService.setSortState(this._sortDirection);
    // update the state of the service
    this.sortService.setSortParams(this._sortColumnKey, this._sortDirection);
    // notify subscribers
    this.orderByParamChange.emit(this.sortService.orderByParam);
  }

  getSortClass(column: string): SortNgClassObject {
    return this.sortService.setSortClass(
      column === this._sortColumnKey
        ? this._sortDirection
        : null
    );
  }

  getSortAriaLabel(column: ZbpDataTable.SortableColumn): string {
    const coreLabel = column.ariaLabel ?? column.header;
    const nextDirection: boolean | null = (column.key === this._sortColumnKey)
      ? this.sortService.setSortState(this._sortDirection)
      : true; // when the column is not selected, ascending is always the nex direction
    if (nextDirection === null) {
      return `remove ${coreLabel} sorting`;
    }
    return `sort ${coreLabel} ${nextDirection ? 'ascending' : 'descending'}`;
  }

  protected readonly Boolean = Boolean;
  protected readonly FormGroup = FormGroup;
}
