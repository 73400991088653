export const xCrossIcon = `
<svg xmlns="http://www.w3.org/2000/svg" width="12" height="13" viewBox="0 0 12 13"
     fill="none">
  <path
    fill-rule="evenodd"
    clip-rule="evenodd"
    d="M11.6864 1.25571C11.2958 0.865185 10.6627 0.865185 10.2722 1.25571L6.02958 5.49829L1.78672 1.25542C1.3962 0.864897 0.763032 0.864897 0.372508 1.25542C-0.0180165 1.64595 -0.0180169 2.27911 0.372507 2.66964L4.61537 6.9125L0.372665 11.1552C-0.0178593 11.5457 -0.017859 12.1789 0.372665 12.5694C0.76319 12.9599 1.39635 12.9599 1.78688 12.5694L6.02958 8.32671L10.272 12.5691C10.6625 12.9597 11.2957 12.9597 11.6862 12.5691C12.0767 12.1786 12.0767 11.5454 11.6862 11.1549L7.4438 6.9125L11.6864 2.66992C12.0769 2.2794 12.0769 1.64623 11.6864 1.25571Z"
    fill="var(--zbp-svg-primary-color, black)"
  />
</svg>
`;
