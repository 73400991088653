import { BehaviorSubject } from 'rxjs';

export function buildClassesSubnav(
  subNavMenuItems: BehaviorSubject<any>,
  classroomId: string
): void {
  const newMenus = [];
  newMenus.push(
    {
      id: 'class-subnav-manage-class-overview',
      weight: 0,
      route: `../teachers/classes/${classroomId}`,
      label: 'Overview'
    },
    {
      id: 'class-subnav-quests',
      weight: 1,
      route: `../learning/classes/${classroomId}/quests`, // TODO: Update route to new page with ZBP-13946
      label: 'Quests'
    },
    {
      id: 'class-subnav-manage-class-settings',
      weight: 2,
      route: `../learning/classes/${classroomId}/settings`, // TODO: Update route to new page with ZBP-14894
      label: 'Class Settings'
    },
    {
      id: 'class-subnav-manage-parent-portal',
      weight: 3,
      route: `../learning/classes/${classroomId}/parent-portal`, // TODO: Update route to new page with ZBP-14960
      label: 'Manage Parent Portal'
    },
  );
  subNavMenuItems.next(newMenus);
}

