import { Injectable, InjectionToken } from '@angular/core';
import { NavigationEnd, Router, RouterEvent } from '@angular/router';
import { AppRoutingService } from '@core/app-routing.service';
import { AppConfigService } from '@core/appconfig.service';
import { ProductService } from '@core/product.service';
import { ZbPortalRedesignHelpers } from '@core/services/feature-flagged-features/zb-portal-redesign-helpers';
import { UserService } from '@core/user.service';
import { EceProductService } from '@ece/api-services/ece-product.service';
import { EceRoutes } from '@ece/ece.routes';
import { PlannerTypesEnum } from '@ece-models/planner-types-enum';
import { ApiResponse } from '@models/api-response';
import { ClassesBySchool } from '@models/classes-by-school';
import { Classroom } from '@models/classroom';
import { NavigationMenuItem } from '@models/navigation-menu-item';
import { IProductLine } from '@models/product-line';
import { IProductLineVariant } from '@models/product-variant';
import { School } from '@models/school';
import { VariantType } from '@models/variant-type';
import { Capabilities } from '@shared/enums/capabilities';
import { CompanyCode } from '@shared/enums/company-code';
import { ModulePathName } from '@shared/enums/module-path-name';
import { ProductType } from '@shared/enums/product-type';
import { RoleType } from '@shared/enums/role-type';
import { Helpers } from '@shared/helpers';
import { BehaviorSubject, Observable, Subject, combineLatest, filter, map, mergeMap, shareReplay, tap } from 'rxjs';
import { TeacherService } from 'src/app/teachers/teachers-service';
import { setProductLines } from '../../classrooms/store/learning.actions';
import { LearningFacade } from '../../classrooms/store/learning.facade';
import {
  districtAdminManageDropdownMenus,
  districtAdminManageDropdownMenusHighlightsPortal,
  districtAdminZBRedesignManageDropdownMenus,
  districtHLAdminMainMenus,
  districtZBAdminMainMenus,
} from './branded-header/primary-menu-builders/district-admin-menu';
import {
  buildSchoolAdminHLRedesignMenus,
  buildSchoolAdminZBRedesignMenus,
  schoolAdminManageDropdownMenus,
  schoolHLAdminMainMenus,
  schoolZBAdminMainMenus,
} from './branded-header/primary-menu-builders/school-admin-menu';
import { buildTeacherZBRedesignMenus, teacherHLMainMenus } from './branded-header/primary-menu-builders/teacher-menu';
import {
  buildClassesSubnav
} from './branded-header/subnavigation/subnav-menu-builders/classes-subnavigation-menu-builder';
import {
  buildProductsSubnav
} from './branded-header/subnavigation/subnav-menu-builders/products-subnavigation-menu-builder';

/**
 * Used to build out the menus and dropdowns for the BrandedHeaderComponent used by HLPortal
 * Responsible for any API calls required to satiate menu items.
 * Can be used for the header changes needed for the future ZBPortal Redesign
 *
 * Menu building should be done here.
 *
 * ---
 * This should be as contextual as possible and have little reliance on other areas of the app driving it,
 * however there are times in the app when properties are set directly:

 * Flag to display more advanced menu options set by TeacherComponent & ProductComponent (both inside ProductModule).
 * When inside TeachersModule, classroom management pages use a resolver to update the currentClassroomId$.
 */
@Injectable({
  providedIn: 'root',
})
export class HeaderService {
  // Properties injected into the existing ZBPortal Header to prevent changing existing functionality
  zbPortalMenusToken = new InjectionToken<any>('menus');
  zbPortalAreaLabelToken = new InjectionToken<any>('areaLabel');
  /**
   * Current Products for when the Products dropdown primary menu item is displayed
   */
  currentMenuProducts: IProductLine[] = null;
  /**
   * Current menu items when the Manage dropdown primary menu item is displayed
   */
  adminManageDropdownMenuItems = [];
  /**
   * Current Primary Menu items for the navigation
   */
  currentMenus: NavigationMenuItem[] = [];
  /**
   * Assigned by modules directly when module adds header to the view
   */
  logoClickedFunction: Function = null;
  /**
   * Not all views have the Primary Menu Items shown (e.g. Choose Role page)
   */
  displayPrimaryHeaderMenus: boolean;
  brand: CompanyCode = null;
  isHighlightsPortalUser: boolean = false;

  /**
   * Current role that user is viewing the portal in.
   * Controls the navigation experience and will render different menus based on Role.
   */
  role: RoleType;

  districtId: string = null;
  schoolId: string = null;
  hasSK2017Licenses = false;

  /**
   * Will trigger a rebuild of all primary header menu items when a true value is provided.
   * Primary menu items are those on the left side of the red bar portion of the header (next to brand logo)
   */
  refreshHeader$: Subject<boolean> = new Subject<boolean>();

  /**
   * Schools used in the Manage menu dropdown menu (this is the secondary flyout window)
   */
  schools = [];

  /**
   * Submenu items for the items used in the Manage Menu dropdown items (this is the secondary flyout window)
   */
  adminSubMenus = {};

  /**
   * Updates when teachersService.currentSchoolId$ receives a new value.
   *
   * Will reoder the Classes dropdown for Teachers based off which School the Teacher is viewing.
   */
  currentSchoolId: string = null;

  /**
   * These reorder so that the last selected school is the school at the top of the list.
   */
  classesMenuItems$: BehaviorSubject<ClassesBySchool[]> = new BehaviorSubject([]);

  /**
   * Will show or remove the subnavigation bar.
   * This should be called last after the subnav is configured with menus.
   */
  showSubnavigation$: BehaviorSubject<boolean> = new BehaviorSubject(true);

  /**
   * Menu items that will be used in the subnavigation bar.
   */
  subNavMenuItems$: BehaviorSubject<NavigationMenuItem[]> = new BehaviorSubject([]);

  /**
   * Teacher Component is where Superkids and other Products that use a more complex navigation
   * (e.g. Handwriting 2025, Kickstart).
   */
  buildAdvancedSubnav$: BehaviorSubject<boolean> = new BehaviorSubject(false);

  /**
   * Current classroom that is being managed. Used to build classes subnavigation.
   *
   */
  currentClassroomId$: BehaviorSubject<string> = new BehaviorSubject(null);

  /**
   * Rebuilds the Classes subnav bar with current Classroom being managed.
   */
  buildClassesSubnavForNewClass$: Observable<void> = this.currentClassroomId$.pipe(
    filter(classroomId => !!classroomId),
    map((classroomId) => {
      buildClassesSubnav(this.subNavMenuItems$, classroomId);
    })
  );

  private eceProductUri = '/learning/products/ece/';

  private moduleRoutesByRoleType = {
    [RoleType.DistrictAdministrator]: `..${this.appRoutingService.findRouteToModule(ModulePathName.Districts)}`,
    [RoleType.SchoolAdministrator]: `..${this.appRoutingService.findRouteToModule(ModulePathName.Schools)}`,
  };

  private getRouteIdToUseByRoleType = {
    [RoleType.DistrictAdministrator]: () => this.districtId,
    [RoleType.SchoolAdministrator]: schoolId => schoolId || this.schoolId,
  };

  set headerProducts(val: IProductLine[]) {
    this.currentMenuProducts = val;
  }

  get headerProducts() {
    return this.currentMenuProducts;
  }

  constructor(
    private productService: ProductService,
    private appConfig: AppConfigService,
    private userService: UserService,
    private eceProductService: EceProductService,
    private router: Router,
    private learningFacade: LearningFacade,
    private zbPortalRedesignHelpers: ZbPortalRedesignHelpers,
    private appRoutingService: AppRoutingService,
    private teacherService: TeacherService,
  ) {
    this.isHighlightsPortalUser = this.appConfig.loginBrand === CompanyCode.HighlightsPortal;
    this.buildAdvancedSubnav$.subscribe();
    this.getClassrooms$.subscribe();

    router.events?.pipe(
      filter(event => event['routerEvent'] instanceof NavigationEnd),
      map(event => event['routerEvent'] as RouterEvent)
    ).subscribe((routerEvent: RouterEvent) => {
      const roleHasSubnav = this.userService.user.viewingAsRole === RoleType.Teacher;
      const showSubnav = this.isClassesRoute(routerEvent.url) || this.isProductsRoute(routerEvent.url);
      if (roleHasSubnav && showSubnav) {
        if (this.isClassesRoute(routerEvent.url)) {
          this.buildClassesSubnavForNewClass$.subscribe();
        } else if (this.isProductsRoute(routerEvent.url)) {
          let showFiveDayPlanner: boolean = false;
          let showLessonPage: boolean = false;

          // Sets up values to build out the menu used for the Teachers component (e.g. Superkids, Handwriting 2025)
          if (this.buildAdvancedSubnav$?.value) {
            const productLine = this.productService.getProductLineByKey(
              this.productService.currentProductLines$?.value,
              this.productService.currentProductLineKey$.value
            );
            const variant = this.productService.getProductVariantByVariantType(
              productLine,
              this.productService.currentVariantType$?.value
            );

            if (variant) {
              showFiveDayPlanner = Helpers.hasProductCapability(variant, Capabilities.FiveDayPlanner);
              showLessonPage = Helpers.hasProductCapability(variant, Capabilities.LessonPage);
            }
          }

          buildProductsSubnav(
            this.buildAdvancedSubnav$?.value,
            this.subNavMenuItems$,
            this.productService,
            this.isHighlightsPortalUser,
            showFiveDayPlanner,
            showLessonPage
          );
        }

        // This should be called last after all menu configurations are complete.
        this.showSubnavigation$.next(true);
      } else {
        this.showSubnavigation$.next(false);
      }
    });

    userService.user$.pipe(
      filter(user => !!user?.profileDetail)
    ).subscribe((user) => {
      this.role = user.viewingAsRole;

      if (!user.schools || user.schools?.length === 0) {
        this.districtId = user.districts[0]?.districtId;
      } else {
        this.districtId = user.schools[0]?.districtId;
        this.schoolId = user.schools[0]?.schoolId;
        this.schools = user.schools;
      }

      // eslint-disable-next-line max-len
      if (user.schools.some(school => school.productLines?.some(productLine => productLine.productLineKey === 'SK2017'))) {
        this.hasSK2017Licenses = true;
      }
    });

    // When user has not gone through Learning Module, products will not be loaded, so we need to load the products,
    // and set them back into state to limit API calls.
    if (this.needsProducts()) {
      this.getProducts$.subscribe((productLines) => {
        this.learningFacade.dispatch(setProductLines({ payload: productLines }));
        this.refreshHeader$.next(true);
      });
    }
  }

  /**
   * Navigates to item selected and closes the dropdown menu that was clicked in.
   *
   * @param event Event to cancel default
   * @param primaryMenu Menu item being selected
   */
  navigateToManageMenuDropdownItem(event: Event, primaryMenu: NavigationMenuItem) {
    event.preventDefault();
    const moduleRoute  = this.moduleRoutesByRoleType[primaryMenu.roleType];
    const routeId = this.getRouteIdToUseByRoleType[primaryMenu.roleType];

    if (moduleRoute && routeId) {
      this.router.navigate([moduleRoute, routeId(primaryMenu.schoolId), primaryMenu.route]);
    }
  }

  /**
   * Initiates router navigation for a given product line or variant.
   */
  navigateToProduct(product: IProductLine, variant: IProductLineVariant, isTeacher: boolean = true) {
    const hlProductLineKey =  product.productLineKey;
    const hlVariantType: VariantType = variant.variantType;
    const { themeId } = this.eceProductService;
    const hlProductLineKeyLower = product?.productLineKey?.toLowerCase();

    if (hlProductLineKeyLower && ProductType[hlProductLineKeyLower] === ProductType.ece2024) {
      this.eceNavigation(hlProductLineKey.toLocaleLowerCase(), hlVariantType, themeId);
    } else {
      // Use the standard way of routing, which does not need query parameters.
      this.router.navigate(Helpers.getProductVariantRoute(product, variant, isTeacher));
    }
  }

  private eceNavigation(productLineKey: string, variantType: VariantType, themeId: string) {
    this.router.navigate([`${this.eceProductUri}${EceRoutes[PlannerTypesEnum.WeeklyPlanner]}`], {
      queryParams: {
        productLineKey,
        variantType,
        plannerThemeId: themeId,
      },
      replaceUrl: true
    });
  }

  private needsProducts = () => this.role === RoleType.Teacher && this.currentMenuProducts === null;

  private isClassesRoute = (url: string) => url
    .startsWith(this.appRoutingService.findRouteToModule(ModulePathName.Classes));

  private isProductsRoute = (url: string) => url
    .startsWith(this.appRoutingService.findRouteToModule(ModulePathName.Products));

  /**
   * Loads product lines if not loaded yet.
   *
   * @todo this pattern can also be used for classrooms when setting up Classes header menu.
   */
  getProducts$: Observable<IProductLine[]> = this.learningFacade.canRefreshProductLines$.pipe(
    filter(canRefreshProducts => canRefreshProducts && this.needsProducts()),
    mergeMap(() => this.productService.getProducts()),
    map((res: ApiResponse<IProductLine[]>) => res.response),
    filter(response => !!response),
    map((productsLines: IProductLine[]) => {
      this.currentMenuProducts = productsLines;
      return productsLines;
    }),
    shareReplay()
  );

  getClassrooms$: Observable<[Classroom[], string]> = combineLatest([
    this.learningFacade.classrooms$,
    this.teacherService.currentSchoolId$
  ]).pipe(
    tap(([classrooms, currentSchoolId]) => {
      this.currentSchoolId = currentSchoolId;
      const allClassrooms  = classrooms ? [...classrooms.filter(c => c.roleType !== RoleType.Parent)] : [];
      this.groupClassroomsBySchool(allClassrooms);
    })
  );

  private groupClassroomsBySchool(classrooms: Classroom[]) {
    const grouped: any  = classrooms.reduce((acc, classroom: Classroom) => {
      const { educationalUnitId } = classroom;
      if (!acc[educationalUnitId]) {
        acc[educationalUnitId] = [];
      }
      const matchingSchool = this.userService.user.schools
        .find(school => school.educationalUnitId === classroom.educationalUnitId);

      const classToAdd = {
        ...classroom,
        schoolName: matchingSchool.name,
      };

      acc[educationalUnitId].push(classToAdd);
      return acc;
    }, {} as ClassesBySchool);

    // Get array of schoolNames and their corresponding educationalUnitIds.
    const schoolInfo = Object.keys(grouped).map(schoolId => ({
      educationalUnitId: schoolId,
      schoolName: grouped[schoolId][0]?.schoolName
    }));

    // Sort schoolInfo array alphabetically by school name.
    schoolInfo.sort((a, b) => a?.schoolName?.localeCompare(b.schoolName));

    // Add entries from original group into new group object with alphabetical keys.
    let result = {};
    schoolInfo.forEach((info) => {
      result[info.educationalUnitId] = grouped[info.educationalUnitId];
    });

    // If there's a previously selected school ID, make that school be the first if it's a valid school for user
    if (this.currentSchoolId) {
      const schoolIdIsValid = this.userService.user.schools?.length > 0
        && this.userService.user.schools?.some((school: School) => school.educationalUnitId === this.currentSchoolId);

      if (schoolIdIsValid) {
        const schoolClassGroup = result[this.currentSchoolId];
        delete result[this.currentSchoolId];
        result = {
          [this.currentSchoolId]: schoolClassGroup,
          ...result,
        };
      }
    }

    const sortedClassesAsArray = Object.entries(result as ClassesBySchool);
    const classesMenuOptions: ClassesBySchool[] = [];

    // Return array of ordered classroom groups for better handling on UI.
    sortedClassesAsArray.forEach((entry) => {
      const schoolId = entry[0];
      const newGroup: ClassesBySchool = {
        schoolId,
        schoolName: grouped[schoolId] ? grouped[schoolId][0]?.schoolName : null,
        classGroupBySchool: entry[1],
      };
      classesMenuOptions.push(newGroup);
    });

    this.classesMenuItems$.next(classesMenuOptions);
  }

  private domainMatchesBrowserHistory(): boolean {
    const currentDomain = window.location.hostname;
    const previousState = window.history.state;
    const previousDomain = previousState ? previousState.previousDomain : null;

    if (!previousDomain) {
      return false;
    }

    return currentDomain === previousDomain;
  }

  /**
   * Gets drop down menus for a specific role depending on portal.
   *
   * This could be the 'Manage' menu for external Admins or 'Products' for Teacher
   */
  getDropdownMenu(role: RoleType): NavigationMenuItem[] {
    if (this.isHighlightsPortalUser) {
      if (role === RoleType.DistrictAdministrator) {
        return [...districtAdminManageDropdownMenusHighlightsPortal];
      }
      if (role === RoleType.SchoolAdministrator) {
        let menus = [];
        this.schools.forEach(((school) => {
          const needsSchoolId = !school?.abbreviatedSchoolId;
          const schoolId = school.educationalUnitId;
          menus = buildSchoolAdminHLRedesignMenus(needsSchoolId, schoolId);
          menus.sort((x, y) => x.weight - y.weight);

          this.adminSubMenus[school.educationalUnitId] = menus;
        }));

        return menus;
      }
    } else if (this.zbPortalRedesignHelpers.isZbPortal2024Active()) {
      if (role === RoleType.DistrictAdministrator) {
        const menus = [...districtAdminZBRedesignManageDropdownMenus];
        // This needs to be pushed on here so that onClickFunction has access to router
        menus.push({
          id: 'score-online-activity',
          isRouted: false,
          isExternal: false,
          label: 'Scores and online activity',
          weight: 8,
          roleType: RoleType.DistrictAdministrator,
          onClickFunction: () => {
            this.router.navigate(['../../../../learning/reports'], {
              queryParamsHandling: 'merge',
              replaceUrl: true
            });
          },
        });

        return menus;
      }
      if (role === RoleType.SchoolAdministrator) {
        let menus = [];
        this.schools.forEach(((school) => {
          const needsSchoolId = !school?.abbreviatedSchoolId;
          const schoolId = school.educationalUnitId;
          menus = buildSchoolAdminZBRedesignMenus(needsSchoolId, schoolId);
          menus.push({
            id: 'score-online-activity',
            isRouted: false,
            isExternal: false,
            label: 'Scores and online activity',
            weight: 5,
            roleType: RoleType.SchoolAdministrator,
            onClickFunction: () => {
              this.router.navigate(['../../../../learning/reports'], {
                queryParamsHandling: 'merge',
                replaceUrl: true
              });
            },
            disabled: needsSchoolId,
            tooltip: needsSchoolId ? 'These links are disabled until a school ID has been set' : '',
            schoolId: school.educationalUnitId,
          });
          if (this.hasSK2017Licenses) {
            menus.push({
              id: 'benchmark-report',
              isRouted: false,
              isExternal: false,
              label: 'Benchmark report',
              weight: 6,
              roleType: RoleType.SchoolAdministrator,
              onClickFunction: () => {
                this.router.navigate(['schools', schoolId, 'admin-benchmark-report'], {
                  queryParams: {
                    schoolId,
                    classroomId: null
                  },
                  queryParamsHandling: 'merge',
                  replaceUrl: true
                });
              },
              disabled: needsSchoolId,
              tooltip: needsSchoolId ? 'These links are disabled until a school ID has been set' : '',
              schoolId: school.educationalUnitId,
            });
          }

          menus.sort((x, y) => x.weight - y.weight);

          this.adminSubMenus[school.educationalUnitId] = menus;
        }));

        return menus;
      }
    } else if (role === RoleType.DistrictAdministrator) {
      return [...districtAdminManageDropdownMenus];
    } else if (role === RoleType.SchoolAdministrator) {
      return [...schoolAdminManageDropdownMenus];
    }
    return [];
  }

  /**
   *  Builds the Products, Classes, and other Primary Menu items.
   *
   * Reports and Classes are dyanmic based on licenses.
   * @returns Current Primary Menu items for the primary navigation header
   */
  buildTeacherPrimaryMenus(): NavigationMenuItem[] {
    let menus: NavigationMenuItem[] = [];

    // ZBPortal will check for Class licenses, HLPortal currently does not support Classes
    if (this.isHighlightsPortalUser) {
      menus = teacherHLMainMenus;

    } else if (this.zbPortalRedesignHelpers.isZbPortal2024Active()) {
      const showReportsAndClasses = this.hasReportOrClassroomProducts(this.currentMenuProducts);
      menus = buildTeacherZBRedesignMenus(showReportsAndClasses);
    }

    menus = Helpers.sortByWeight(menus);

    return menus;
  }

  /**
   * Builds the Primary Menu items for the menu when viewing as a DistrictAdmin
   * @returns Current Primary Menu items for the primary navigation header
   */
  buildDistrictAdminMenus(): NavigationMenuItem[] {
    let menus: NavigationMenuItem[] = [];

    if (this.isHighlightsPortalUser) {
      menus = districtHLAdminMainMenus;
    } else {
      menus = districtZBAdminMainMenus;
    }

    menus = Helpers.sortByWeight(menus);

    this.currentMenus = menus;
    return menus;
  }

  /**
   * Builds the Primary Menu items for the menu when viewing as a SchoolAdmin
   * @returns Current Primary Menu items for the
   */
  buildSchoolAdminMenus(): NavigationMenuItem[] {
    let menus: NavigationMenuItem[] = [];
    if (this.isHighlightsPortalUser) {
      menus = schoolHLAdminMainMenus;
    } else {
      menus = schoolZBAdminMainMenus;
    }

    menus = Helpers.sortByWeight(menus);

    this.currentMenus = menus;
    return menus;
  }

  /**
   * Takes the user back to their last page.
   *
   * Will navigate to a referrer path when a referrer is provided.
   *
   * If the previous window history item is for the current domain, it will use that history item to go back to
   *
   * If none of the other optiosn are true, then the user is navigated to their root route (e.g. Admin Overview pages)
   */
  goBack(): void {
    const { referrer } = document;

    if (referrer && (referrer !== window.location.toString())) {
      // use referrer first
      const referrerPath = new URL(referrer).pathname;

      this.router.navigateByUrl(referrerPath);
    } else if (window.history.length > 1 && this.domainMatchesBrowserHistory()) {
      // use history if exists for current domain
      window.history.back();
    } else {
      // go back to module root
      this.router.navigateByUrl('/');
    }
  }

  /**
   * Clears out the current user's viewingAsRole then navigates to the root which directs user to the Choose Role page.
   */
  chooseRoleClick() {
    this.userService.user.viewingAsRole = null;
    this.router.navigate(['/']);
  }

  private hasReportOrClassroomProducts(productLines: IProductLine[]): boolean {
    if (this.currentMenuProducts) {
      return (productLines?.findIndex(pl => (
        pl.variants.findIndex(v => (
          v.skus.findIndex(s => (
            !Helpers.productTypesWithNoClassLicenses.includes(Helpers.getProductTypeFromProductLineId(s.name))
          )) !== -1
        )) !== -1
      )) !== -1);
    }

    return false;
  }
}
